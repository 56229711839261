import React, { useState } from "react";

export function Banner({className, title, message, handleFun, hasCloseBtn = false}) {

  const [closed, setClosed] = useState(false);
  const onClickClose = () => {
    setClosed(true);
  }

  if (closed) {
    return <></>;
  } else {
    return (
      <div className="sf-bg-cardselected rounded p-5">
        {title && title.length > 0 &&
          <h4 className="mb-2">
            {title}
          </h4>
        }
        <div className=" d-flex align-items-center justify-content-between rounded">

            <div className="h5-font-size">
              {message}
              {
                handleFun != null && handleFun()
              }
            </div>
            {hasCloseBtn &&
              <div>
                <i className='sf-icon-clear ml-3 cursor-pointer' onClick={onClickClose}/>
              </div>
            }
        </div>
      </div>
    );
  }

}
