import { superstructResolver } from '@hookform/resolvers/superstruct';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faWifi } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import React from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as s from 'superstruct';
import assignedMobileChild from "../../_images/assigned_mobile_child.png";
import assignedMobileParent from "../../_images/assigned_mobile_parent.png";
import { selectNetworkDevices } from "sf-ui-components";
import { selectDefaultSsid, updateWifiSetupStep, WIFI_SETUP_STEP } from "./_wifiSlice";

const ssidSchema = s.object({
  name: s.pattern(s.string(), /^[^!#;+\]\\/"\t][^+\]\\/"\t]{0,30}[^ !#;+\]\\/"\t]$|^[^ !#;+\]\\/"\t]$/),
  password: s.pattern(s.string(), /^[\u0020-\u007e\u00a0-\u00ff]{8,63}$/),
});

export function WifiSetupCompleted() {

  const dispatch = useDispatch();
  const devices = useSelector(selectNetworkDevices);
  const ssid = useSelector(selectDefaultSsid);
  const history = useHistory();

  const form = useForm({
    resolver: superstructResolver(ssidSchema),
    defaultValues: {
      name: _.get(ssid, 'name', ''),
      password: _.get(ssid, 'authPassword', ''),
    }
  });

  const { register } = form;

  const onClickEdit = () => {
    dispatch(updateWifiSetupStep(WIFI_SETUP_STEP.NAME_WIFI));
  };

  const onClick = () => {
    history.push('/wiring');
  };

  return (
    <>
      <section className="mb-6">
        <h2>
          You have {devices && devices.data && devices.data.length > 1 ? devices.data.length + " nodes " :
            devices && devices.data ? devices.data.length + " node " : " 0 nodes "} assigned to you
        </h2>
      </section>
      <section  className="mb-6">

        {
          devices.data.length > 0 &&
          devices.data.map((device, index) => {
            if (index === 0) {
              return (
                <div >
                  <img key={index} alt='' width='100%' src={assignedMobileParent}/>
                </div>
              );
            } else {
              return (
                <div className="mt-5">
                  <img key={index} alt='' width='100%' src={assignedMobileChild}/>
                </div>
              );

            }
          })
        }

      </section>
      <section>
        <Form >
          <Form.Group>
            <div className="d-flex justify-content-between align-items-center">
              <Form.Label className="mb-4">Home Network</Form.Label>
              <Button variant="link" className="p-0 sf-link-primary mb-4" onClick={onClickEdit} >
                Edit
              </Button>
            </div>
            <Form.Control name={"name"} type="text" {...register("name")} />
          </Form.Group>
          <Form.Group className="mb-6">
            <Form.Label className="mb-4">
              Password
            </Form.Label>
            <Form.Control name={"password"} type="password" {...register("password")} />
          </Form.Group>
          <div className="mb-6">
            You've already customized your settings. Are you ready to set up your nodes?
          </div>
          <div className="d-flex justify-content-center">
            <Button variant='primary' className='sf-btn-w-xlg' onClick={onClick}>
              Begin Setup
          </Button>
          </div>
        </Form>
      </section>

    </>
  );
}
