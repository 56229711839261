import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";

export const StepNodeAdded = () => {
  const history = useHistory();
  useEffect(
    () => {
      let timer = setTimeout(() => history.push('/network/ready'),
        5000);
      return () => {
        clearTimeout(timer);
      };
      // eslint-disable-next-line
    }, []
  );
  return (
    <>
      <section>
        <h2>
          You added the nodes!
        </h2>
      </section>
      <section className='mt-10'>
        <img alt='' width='100%' src='https://via.placeholder.com/400x256'/>
      </section>
    </>
  );
};
