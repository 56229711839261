import React, {useEffect, useMemo} from "react";
import {useDispatch} from "react-redux";
import {NODE_SETUP_STEP, updateNodeSetupStep} from "./_nodeSlice";
import {addToast, createLogger, useIotMessage} from "sf-ui-components";
import {IOT_STATUS_EVENTS} from "sf-ui-components";
import _ from "lodash";
import {useTopic} from "../../common/hooks";
import {useHistory} from "react-router-dom";

const logger = createLogger('sfe:adding:node');

export const StepAddingNodes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {topic} = useTopic();
  logger(`useIotMessage, topic: ${topic}`);
  const iotParam = useMemo(() => {
    return {
      topic: topic,
      filter: (message) => {
        const event = _.get(message, 'payload.event');
        return event === IOT_STATUS_EVENTS.DEVICE_ONLINE;
      }
    };
  }, [topic]);
  const {waitForPromise, removeMessagePromise} = useIotMessage(iotParam);

  useEffect(() => {
    waitForPromise(180000)
      .then(async messages => {
        logger('IoT message array: ', messages);
        await removeMessagePromise(messages);
        dispatch(updateNodeSetupStep(NODE_SETUP_STEP.NODE_ADDED));
      })
      .catch(it => {
        logger('waitForPromise Timeout', it);
        dispatch(updateNodeSetupStep(NODE_SETUP_STEP.GO_AHEAD));
        addToast('Node was not added within 60 seconds', 'info', true);
      }).finally(() => {
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <section>
        <h2>
          Adding your nodes...
        </h2>
      </section>
      <section className='mt-4'>
        <p className='sf-text-f-body2 sf-text-c-gray'>
          This can take up to a few minutes.
        </p>
      </section>
      <section className='mt-10'>
        <img alt='' width='100%' src='https://via.placeholder.com/400x256'/>
      </section>
      <div className='d-flex align-items-center justify-content-center mt-9'>
        <a href='#/' onClick={(e) => {
          e.preventDefault()
          history.push('/network/ready');
        }}>
          Skip for now
        </a>
      </div>
    </>
  );
};
