import React from 'react';

import menu from '../../_images/Menu.svg';

const MobileMenu = (props) => {
    const { showMobileMenu } = props;

    return (
        <div className='mobile-menu-container'>
            {!showMobileMenu && <img src={menu} alt='open menu' />}
        </div>
    )
}

export default MobileMenu;