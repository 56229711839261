import React from "react";
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux';
import {useCustomerConfig} from "sf-ui-components";
import {updateWifiSetupStep, WIFI_SETUP_STEP} from "./_wifiSlice";
import {selectNetworkDevices} from "sf-ui-components";
import comingSoon from "../../_images/assigned_mobile_start_coming_soon.png";


export function NodesComingSoon() {
  const devices = useSelector(selectNetworkDevices);
  const cc = useCustomerConfig();
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(updateWifiSetupStep(WIFI_SETUP_STEP.NAME_WIFI_INFO));
  }

  return (cc &&
    <>
      <section className="mb-8">
        <h2>
          Your {devices && devices.data && devices.data.length > 1 ? devices.data.length + " nodes " :
            devices && devices.data ? devices.data.length + " node " : " 0 nodes "} should be coming soon
        </h2>
      </section>
      <section className="mb-8">
        <img alt='' width='100%' src={comingSoon} />
      </section>
      <section>
        <div className="mb-6">
          In the meantime, let's customize some of your settings. It'll make setup a snap once
          your {devices && devices.data && devices.data.length > 1 ? devices.data.length + " nodes " :
            devices && devices.data ? devices.data.length + " node " : " 0 nodes "} arrive.
        </div>
        <div className="d-flex justify-content-center">
          <Button variant='primary' className='sf-btn-w-xlg' onClick={onClick}>
            Add My WiFi Settings
          </Button>
        </div>

      </section>

    </>
  );
}
