import React from "react";
import { useSelector } from "react-redux";
import { AppContainer } from "../../common/AppContainer";
import { NameWifiInfoPage } from "./NameWifiInfoPage";
import { NameWifiPage } from "./NameWifiPage";
import { NodesAssigned } from "./NodesAssigned";
import { NodesComingSoon } from "./NodesComingSoon";
import { NodesScanningPage } from "./NodesScanningPage";
import { ParentNodeSetup } from "./ParentNodeSetup";
import { StartSetupWiring } from "./StartSetupWiring";
import { WifiSetupCompleted } from "./WifiSetupCompleted";
import { selectWifiSetupStep, WIFI_SETUP_STEP } from "./_wifiSlice";

export const WifiSetupFlow = () => {
  return (
    <AppContainer>
      <RenderWifiSetupPage />
    </AppContainer>
  )
};


function RenderWifiSetupPage() {
  const step = useSelector(selectWifiSetupStep);
  switch (step) {
    case WIFI_SETUP_STEP.BEGIN_SCANNING:
      return <NodesScanningPage />;
    case WIFI_SETUP_STEP.NODES_ASSIGNED:
      return <NodesAssigned />;
    case WIFI_SETUP_STEP.NODES_COMMINGSOON:
      return <NodesComingSoon />;
    case WIFI_SETUP_STEP.NAME_WIFI_INFO:
        return <NameWifiInfoPage />;
    case WIFI_SETUP_STEP.NAME_WIFI:
      return <NameWifiPage />;
    case WIFI_SETUP_STEP.WIFI_SETUP_COMPLETED:
      return <WifiSetupCompleted />;
    case WIFI_SETUP_STEP.START_SETUP_WIRING:
      return <StartSetupWiring />;
    case WIFI_SETUP_STEP.PARENT_NODE_SETUP:
      return <ParentNodeSetup />
    default:
      return (
        <>
        </>
      );
  }
}
