import { superstructResolver } from "@hookform/resolvers/superstruct";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateWifiSetupStep, WIFI_SETUP_STEP } from "../wifi/_wifiSlice";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addToast,
  createLogger,
  hasError,
  isEmpty,
  selectCustomerId,
  selectVpnOptions,
  selectVpnSelfOption,
  SfPassword,
  startEditSelfVpnOptions,
  startFetchAllVpnOptions
} from "sf-ui-components";
import * as s from "superstruct";
import { string } from "superstruct";

const logger = createLogger('sfe:vpn:configuration:form');

export const VpnConfigurationForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const customerId = useSelector(selectCustomerId);
  const vpnOptions = useSelector(selectVpnOptions);
  const vpnSelfOption = useSelector(selectVpnSelfOption);
  const [vpnAuthMode, setVpnAuthMode] = useState();
  // const network = useSelector(selectNetwork);
  // const networkPreferences = useSelector(selectNetworkPreferences);

  const schema = s.object({
    preferredHostId: string(),
    username: s.optional(string()),
    password: s.optional(string()),
    groupId: s.optional(string()),
    clientCert: s.optional(string()),
    clientKey: s.optional(string()),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue
  } = useForm({
    resolver: superstructResolver(schema, {
      coerce: true
    }),
    defaultValues: {
      preferredHostId: vpnSelfOption?.preferredHostId,
      username: vpnSelfOption?.userName,
      password: vpnSelfOption?.password,
      groupId: vpnSelfOption?.groupId,
      clientCert: vpnSelfOption?.clientCert,
      clientKey: vpnSelfOption?.clientKey,
    }
  });

  // Fetch VPN options
  useEffect(() => {
    if (!isEmpty(customerId)) {
      dispatch(startFetchAllVpnOptions({orgId: customerId}))
        .then(unwrapResult)
        .then((options) => {
          // const [option] = options;
          // logger('Assign the first option as default option', option);
          // setValue('preferredHostId', option.hostId);

          let selectedVpn = null;
          if (vpnSelfOption && vpnSelfOption.preferredHostId != null) {
            selectedVpn = options.find(x => x.hostId === vpnSelfOption.preferredHostId);
          }

          if (selectedVpn == null) {
            // selectedVpn = option;
            setVpnAuthMode('None');
          } else {
            setValue('preferredHostId', selectedVpn.hostId);
            if (selectedVpn.authenMode === 'PASSWORD') {
              setVpnAuthMode('PASSWORD');
            } else {
              setVpnAuthMode('CERTIFICATE');
            }
          }
        })
        .catch((error) => {
          addToast(error.message, 'error');
        });
    }
  }, [customerId, dispatch, setValue, vpnSelfOption]);


  const onSubmit = (data) => {
    logger('VPN Form submitted:', data);
    dispatch(startEditSelfVpnOptions({
      orgId: customerId,
      preferredHostId: data.preferredHostId,
      userName: isEmpty(data.username) ? undefined : data.username,
      password: isEmpty(data.password) ? undefined : data.password,
      groupId: isEmpty(data.groupId) ? undefined : data.groupId,
      clientCert: isEmpty(data.clientCert) ? undefined : data.clientCert,
      clientKey: isEmpty(data.clientKey) ? undefined : data.clientKey,
    }))
      .then(unwrapResult)
      .then(() => {
        logger('Edit user VPN options successfully');
        dispatch(updateWifiSetupStep(WIFI_SETUP_STEP.NAME_WIFI_INFO));
        history.push('/wifi');
      })
      .catch((error) => {
        addToast(error.message, 'error');
      })
  };
  const onError = (error) => {
    logger('VPN Form error:', error);
  };

  function handleSelectChange(event) {
    logger('handle select change event:%o', event);

    setValue('preferredHostId', event.target.value);
    const selectedVpn = vpnOptions.find(x => x.hostId === event.target.value);
    if (selectedVpn == null) {
      setVpnAuthMode('None');
    } else if (selectedVpn.authenMode === 'PASSWORD') {
      setVpnAuthMode('PASSWORD');
    } else {
      setVpnAuthMode('CERTIFICATE');
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Form.Group controlId="vpnPreferredHostId" className='mt-5'>
        <Form.Label>
          VPN Server
        </Form.Label>
        <Form.Control as="select" onChange={handleSelectChange} defaultValue={vpnSelfOption?.preferredHostId != null ? vpnSelfOption.preferredHostId: 'None'}
            className={hasError(errors, `preferredHostId`) ? 'is-invalid shake' : ''}>
          <option disabled value='None'>Select a server</option>
          {
            vpnOptions.map(
              (s, i) => <option key={i} value={s.hostId}>{s.hostName}</option>)
          }
        </Form.Control>
      </Form.Group>
      {
        vpnAuthMode === 'PASSWORD' &&
        <>
          <Form.Group controlId='vpnUsername' className='mt-5'>
            <Form.Label>Username</Form.Label>
            <Form.Control
              type='text'
              name='username'
              {...register('username')}
              className={hasError(errors, `username`) ? 'is-invalid shake' : ''}
            />
          </Form.Group>
          <Form.Group controlId='vpnPassword' className='mt-5'>
            <Form.Label>Password</Form.Label>
            <SfPassword
              name='password'
              {...register('password')}
              className={'icon-muted ' + (hasError(errors, `password`) ? 'is-invalid shake' : '')}
            />
          </Form.Group>
          <Form.Group controlId='vpnGroupId' className='mt-5'>
            <Form.Label>Group Id</Form.Label>
            <Form.Control
              type='text'
              name='groupId'
              {...register('groupId')}
              className={hasError(errors, `groupId`) ? 'is-invalid shake' : ''}
            />
          </Form.Group>
        </>
      }
      {
        vpnAuthMode === 'CERTIFICATE' &&
        <>
          <Form.Group controlId="vpnClientCert" className='mt-5'>
            <Form.Label>Client Cert</Form.Label>
            <Form.Control as="textarea" rows={3}
              name="clientCert"
              {...register('clientCert')}
              className={hasError(errors, `clientCert`) ? 'is-invalid shake' : ''}
            />
          </Form.Group>
          <Form.Group controlId='vpnClientKey' className='mt-5'>
            <Form.Label>Client Key</Form.Label>
            <Form.Control  as="textarea" rows={3}
              name='clientKey'
              {...register('clientKey')}
              className={hasError(errors, `clientKey`) ? 'is-invalid shake' : ''}
            />
          </Form.Group>
          <Form.Group controlId='vpnGroupId' className='mt-5'>
            <Form.Label>Group Id</Form.Label>
            <Form.Control
              type='text'
              name='groupId'
              {...register('groupId')}
              className={hasError(errors, `groupId`) ? 'is-invalid shake' : ''}
            />
          </Form.Group>
        </>
      }
      <div className='mt-6'>
        <Button variant="primary" className='sf-btn-w-xlg' type="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};
