import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConfirmContainer } from '../features/confirm/_confirmContainer';
import { IspContainer } from '../features/isp/_ispContainer';
import { NetworkContainer } from '../features/network/_networkContainer';
import { NodeContainer } from '../features/node/_nodeContainer';
import { SetupContainer} from '../features/setup/_setupContainer'
import { TopologyContainer } from '../features/topology/_topologyContainer';
import { VpnContainer } from '../features/vpn/VpnContainer';
import { WifiContainer } from '../features/wifi/_wifiContainer';
import { WiringContainer } from '../features/wiring/_wiringContainer';
import { NetworkRoute } from './NetworkRoute';
import { ProfileContainer } from '../features/profile/_profileContainer';
import { DevicesContainer } from '../features/devices/_devicesContainer';
import { NodeDetailContainer } from "../features/network/_nodeDetailContainer";
import { WirelessContainer } from "../features/wireless/_wirelessContainer";
import { PriorityContainer } from "../features/priority/_priorityContainer";
import { SpeedsContainer } from "../features/speeds/_speedsContainer";
import { NetworkAdministrationContainer } from "../features/networkAdministration/_networkAdministrationContainer";
import { SystemSettingsContainer } from "../features/systemSettings/_systemSettingsContainer";

export function MainContainer() {
  return (
    <>
      <Switch>
        <Route path={"/isp"}>
          <NetworkRoute component={IspContainer} />
        </Route>
        <Route path={"/wifi"}>
          <NetworkRoute component={WifiContainer} />
        </Route>
        <Route path={"/node"}>
          <NetworkRoute component={NodeContainer} />
        </Route>
        <Route path={"/vpn"}>
          <NetworkRoute component={VpnContainer} />
        </Route>
        <Route path={"/wiring"}>
          <NetworkRoute component={WiringContainer} />
        </Route>
        <Route path={"/network"}>
          <NetworkContainer />
        </Route>
        <Route path={"/confirm"}>
          <ConfirmContainer />
        </Route>
        <Route path={"/topology"}>
          <TopologyContainer />
        </Route>
        <Route path={'/nodeDetails'}>
          <NodeDetailContainer />
        </Route>
        <Route path={"/setup"}>
          <SetupContainer />
        </Route>
        <Route path={"/profile"}>
          <ProfileContainer />
        </Route>
        <Route path={'/devices'}>
          <DevicesContainer />
        </Route>
        <Route path={"/wireless"}>
          <WirelessContainer />
        </Route>
        <Route path="/priority">
          <PriorityContainer />
        </Route>
        <Route path="/speeds">
          <SpeedsContainer />
        </Route>
        <Route path="/networkadministration">
          <NetworkAdministrationContainer />
        </Route>
        <Route exact path="/systemSettings">
          <SystemSettingsContainer />
        </Route>
        <Route path="*">
          <Redirect to="/setup" />
        </Route>
      </Switch>
    </>
  );
}
