import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createLogger, LoadingState, unwrapErrorResponse } from 'sf-ui-components';
import { envBaseApiUrl } from '../../app/env';
import axios from 'axios';

const logger = createLogger('sfe:profile:slice');

export const startFetchProfile = createAsyncThunk(
    'profile/FetchProfile',
    async () => {
        logger('[FetchProfile] begin');
        const url = `${envBaseApiUrl}/user-service/rest/accounts/self`;

        try {
            const response = await axios.get(url, { username: 'self' });
            if (response.status !== 200) {
                throw response.data;
            }
            return response.data;
        } catch (e) {
          const errorResp = unwrapErrorResponse(e);
          throw(errorResp);
        }
    }
);

export const startFetchPreferences = createAsyncThunk(
    'profile/FetchPreferences',
    async () => {
        logger('[FetchPreferences] begin');
        const url = `${envBaseApiUrl}/user-service/rest/accounts/self/preferences`;

        try {
            const response = await axios.get(url, { username: 'self' });
            if (response.status !== 200) {
                throw response.data;
            }
            return response.data;
        } catch (e) {
            const errorResp = unwrapErrorResponse(e);
            throw(errorResp);
        }
    }
);

export const startUpdateProfile = createAsyncThunk(
    'profile/UpdateProfile',
    async (input, { dispatch }) => {
        logger('[UpdateProfile] begin: %o', input);
        const url = `${envBaseApiUrl}/user-service/rest/accounts/self`;

        try {
            const response = await axios.put(url, input);
            if (response.status !== 204) {
                throw response.data;
            }
            dispatch(startFetchProfile());
            return response.data;
        } catch (e) {
          const errorResp = unwrapErrorResponse(e);
          throw(errorResp);
        }
    }
);

export const startUpdatePreferences = createAsyncThunk(
    'profile/UpdatePreferences',
    async (input, {rejectWithValue, getState, dispatch}) => {
        logger('[UpdatePreferences] begin: %o', input);
        const url = `${envBaseApiUrl}/user-service/rest/accounts/self/preferences`;

        try {
            const response = await axios.put(url, input);
            if (response.status !== 204) {
                throw response.data;
            }
            dispatch(startFetchPreferences());
            return response.data;
        } catch (e) {
          const errorResp = unwrapErrorResponse(e);
          throw(errorResp);
        }
    }
);

export const slice = createSlice({
    name: 'profile',
    initialState: {

    },
    reducers: {
        updateProfileEditingState: (state, action) => {
            state.profileEditActive = action.payload;
        }
    },
    extraReducers: {
        [startFetchProfile.pending]: (state, action) => {
            state.loadingState = LoadingState.LOADING;
            logger('startFetchProfile.pending: %o, %o', state, action);
        },
        [startFetchProfile.fulfilled]: (state, action) => {
            logger('startFetchProfile.fulfilled: %o, %o', state, action);
            state.profileData = action.payload;
            state.profileEditActive = false;
            state.loadingState = LoadingState.LOADED;
        },
        [startFetchProfile.rejected]: (state, action) => {
            logger('startFetchProfile.rejected: %o, %o', state, action);
            state.profileEditActive = false;
            state.loadingState = LoadingState.LOADED;
        },
        [startUpdateProfile.pending]: (state, action) => {
            state.loadingState = LoadingState.LOADING;
            logger('startUpdateProfile.pending: %o, %o', state, action);
        },
        [startUpdateProfile.fulfilled]: (state, action) => {
            logger('startUpdateProfile.fulfilled: %o, %o', state, action);
            state.loadingState = LoadingState.LOADED;
        },
        [startUpdateProfile.rejected]: (state, action) => {
            logger('startUpdateProfile.rejected: %o, %o', state, action);
            state.loadingState = LoadingState.LOADED;
        },
        [startFetchPreferences.pending]: (state, action) => {
            state.loadingState = LoadingState.LOADING;
            logger('startFetchPreferences.pending: %o, %o', state, action);
        },
        [startFetchPreferences.fulfilled]: (state, action) => {
            logger('startFetchPreferences.fulfilled: %o, %o', state, action);
            state.profilePreferences = action.payload;
            state.profileEditActive = false;
            state.loadingState = LoadingState.LOADED;
        },
        [startFetchPreferences.rejected]: (state, action) => {
            logger('startFetchPreferences.rejected: %o, %o', state, action);
            state.profileEditActive = false;
            state.loadingState = LoadingState.LOADED;
        },
        [startUpdatePreferences.pending]: (state, action) => {
            state.loadingState = LoadingState.LOADING;
            logger('startUpdatePreferences.pending: %o, %o', state, action);
        },
        [startUpdatePreferences.fulfilled]: (state, action) => {
            logger('startUpdatePreferences.fulfilled: %o, %o', state, action);
            state.loadingState = LoadingState.LOADED;
        },
        [startUpdatePreferences.rejected]: (state, action) => {
            logger('startUpdatePreferences.rejected: %o, %o', state, action);
            state.loadingState = LoadingState.LOADED;
        }
    }
});

export const { updateProfileEditingState } = slice.actions;

export const selectProfileEditActive = state => state?.profile?.profileEditActive;
export const selectProfileData = state => state?.profile?.profileData;
export const selectProfilePreferences = state => state?.profile?.profilePreferences;
export const selectLoadingState = state => state?.profile?.loadingState;

export default slice.reducer;