import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import { composeUrl, createLogger, LoadingState, unwrapErrorResponse } from "sf-ui-components";
import { envBaseApiUrl } from "../../app/env";

const logger = createLogger('sfe:confirm:slice');

// export const startCityStateLookup = createAsyncThunk(
//   'confirm/CityStateLookup',
//   async (input, thunkAPI) => {
//     logger('[CityStateLookup] begin: %o', input);
//     const {countrycode, zipcode} = input;
//     const url = composeUrl(`${envBaseApiUrl}/utility-service/rest/addresses/${countrycode}/${zipcode}`);

//     return axios.get(url)
//       .then((response) => {
//         return response.data;
//       })
//       .catch((error) => {
//         throw unwrapErrorResponse(error);
//       })
//   }
// );

export const startGetGeoLocationByZipcode = createAsyncThunk(
  'confirm/GetGeoLocationByZipcode',
  async (input, thunkAPI) => {
    logger('[GetGeoLocationByZipcode] begin: %o', input);
    const {zipcode} = input;
    const url = composeUrl(`${envBaseApiUrl}/utility-service/rest/addresses/zipcodes/${zipcode}/cities`);

    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startGetIsps = createAsyncThunk(
  'confirm/GetIsps',
  async (input, thunkAPI) => {
    logger('[GetIsps] begin: %o', input);
    const {limit} = input;
    const url = composeUrl(`${envBaseApiUrl}/utility-service/rest/isp`, limit);

    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startFetchNetworkPreferences = createAsyncThunk(
  'confirm/FetchNetworkPreferences',
  async (input, thunkAPI) => {
    logger('[FetchNetworkPreferences] begin: %o', input);
    const {networkId} = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/preferences`);

    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw unwrapErrorResponse(error);
      })
  }
);

export const startSetNetworkPreferences = createAsyncThunk(
  'confirm/SetNetworkPreferences',
  async (input, thunkAPI) => {
    logger('[SetNetworkPreferences] begin: %o', input);
    const { networkId, ...rest } = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/preferences`);

    return axios.put(url, rest)
      .then((response) => {
        logger('Network Preferences response: %o', response.data);
        return response.data;
      }).catch((error) => {
        logger('Network Preferencesfailed: %o', error);
        throw error;
      });
  }
);


export const slice = createSlice({
  name: 'confirm',
  initialState: {
    ispsLoadingState: LoadingState.NONE,
    preferencesLoadingState: LoadingState.NONE,
  },
  reducers: {

  },
  extraReducers: {
    // [startCityStateLookup.pending]: (state, action) => {
    //   logger("startCityStateLookup.pending: %o, %o", state, action);
    // },
    // [startCityStateLookup.fulfilled]: (state, action) => {
    //   logger("startCityStateLookup.fulfilled: %o, %o", state, action);
    //   state.lookupAddress = action.payload;
    // },
    // [startCityStateLookup.rejected]: (state, action) => {
    //   logger("startCityStateLookup.rejected: %o, %o", state, action);
    // },

    [startGetGeoLocationByZipcode.pending]: (state, action) => {
      logger("startGetGeoLocationByZipcode.pending: %o, %o", state, action);
    },
    [startGetGeoLocationByZipcode.fulfilled]: (state, action) => {
      logger("startGetGeoLocationByZipcode.fulfilled: %o, %o", state, action);
      state.locations = action.payload;
    },
    [startGetGeoLocationByZipcode.rejected]: (state, action) => {
      logger("startGetGeoLocationByZipcode.rejected: %o, %o", state, action);
    },
    [startGetIsps.pending]: (state, action) => {
      logger("startGetIsps.pending: %o, %o", state, action);
      state.ispsLoadingState = LoadingState.LOADING;
    },
    [startGetIsps.fulfilled]: (state, action) => {
      logger("startGetIsps.fulfilled: %o, %o", state, action);
      state.ispsLoadingState = LoadingState.LOADED;
      state.isps = action.payload;
    },
    [startGetIsps.rejected]: (state, action) => {
      state.ispsLoadingState = LoadingState.LOADED;
      logger("startGetIsps.rejected: %o, %o", state, action);
    },
    [startFetchNetworkPreferences.pending]: (state, action) => {
      logger("startFetchNetworkPreferences.pending: %o, %o", state, action);
      state.preferencesLoadingState = LoadingState.LOADING;
    },
    [startFetchNetworkPreferences.fulfilled]: (state, action) => {
      logger("startFetchNetworkPreferences.fulfilled: %o, %o", state, action);
      state.preferencesLoadingState = LoadingState.LOADED;
      state.networkPreferences = action.payload;
    },
    [startFetchNetworkPreferences.rejected]: (state, action) => {
      logger("startFetchNetworkPreferences.rejected: %o, %o", state, action);
      state.preferencesLoadingState = LoadingState.LOADED;
    },
    [startSetNetworkPreferences.pending]: (state, action) => {
      logger("startNetworkPreferences.pending: %o, %o", state, action);
      state.preferencesLoadingState = LoadingState.NONE;
    },
    [startSetNetworkPreferences.fulfilled]: (state, action) => {
      logger("startNetworkPreferences.fulfilled: %o, %o", state, action);
    },
    [startSetNetworkPreferences.rejected]: (state, action) => {
      logger("startNetworkPreferences.rejected: %o, %o", state, action);
    },

  }
})


export const selectIsps = state => state?.confirm?.isps;
export const selectLocations = state => state?.confirm?.locations;
export const selectNetworkPreferences = state => state?.confirm?.networkPreferences;
export const selectIspsLoadingState = state => _.get(state, 'confirm.ispsLoadingState', LoadingState.NONE);
export const selectPreferencesLoadingState = state => _.get(state, 'confirm.preferencesLoadingState', LoadingState.NONE);

export default slice.reducer;
