import React from "react";

export const VpnConnected = () => {
  return (
    <>
      <section>
        <h2>
          Your corporate network is set up!
        </h2>
      </section>
      <section className='mt-9'>
        <img alt='' width='100%' src='https://via.placeholder.com/400x256'/>
      </section>
    </>
  );
};
