import { superstructResolver } from '@hookform/resolvers/superstruct';
import { unwrapResult } from "@reduxjs/toolkit";
import debug from 'debug';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { hasError, selectNetwork } from 'sf-ui-components';
import * as s from 'superstruct';
import {
  selectDefaultSsid,
  selectSsids,
  startCreateNetworkSsid,
  startFetchSsid,
  startGetNetworkSsids,
  startUpdateSsid,
  updateWifiSetupStep,
  WIFI_SETUP_STEP
} from "./_wifiSlice";

const ssidSchema = s.object({
  // name: s.pattern(s.string(), /^[^!#;+\]\\/"\t][^+\]\\/"\t]{0,30}[^ !#;+\]\\/"\t]$|^[^ !#;+\]\\/"\t]$/),
  name: s.size(s.string(), 1, 32),
  password: s.pattern(s.string(), /^[\u0020-\u007e\u00a0-\u00ff]{8,62}$/),
});

const logger = debug('sf:emplooyee:nameWifiSsid');

export function NameWifiPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const network = useSelector(selectNetwork);
  const ssids = useSelector(selectSsids);
  const ssid = useSelector(selectDefaultSsid);
  const [savingError, setSavingError] = useState(false);

  const form = useForm({
    resolver: superstructResolver(ssidSchema),
    defaultValues: {
      name: _.get(ssid, 'name', ''),
      password: _.get(ssid, 'authPassword', ''),
    }
  });

  const { register, handleSubmit, setValue, trigger, watch, formState: { errors, dirtyFields, isValid } } = form;

  useEffect(() => {
    if (ssids != null  && ssids.length > 0 && ssid == null) {
      dispatch(startFetchSsid({
        networkId: network.id,
        ssidId: ssids[0].id,
      }))
        .then(unwrapResult)
        .then((resp) => {

          setValue('name', resp.name);
          setValue('password', resp.authPassword);
          trigger();

          logger("startFetchSsid resp =%o", resp);
        })
        .catch((error) => {
          logger("startFetchSsid error=%o", error.message);
        });
    }
  }, [dispatch, network.id, setValue, ssid, ssids, trigger])

  const dispatchPage = () => {

    const provisionStatus = _.get(network, 'provisionStatus');

    if (provisionStatus !== 'PROVISIONED') {
      history.push('/confirm');
    } else {
      dispatch(updateWifiSetupStep(WIFI_SETUP_STEP.PARENT_NODE_SETUP))
    }
  }

  const doOnSubmit = async data => {
    logger("submit: data =%o", data);

    if (ssids != null && ssids.length > 0) { //update ssid
      const input = {
        name: data.name,
        hidden: false,
        hasAuth: true,
        authMethod: "WPA2",
        authPassword: data.password,
        status: true,
        ssidId: ssids[0].id,
        networkId: network.id,
      };
      dispatch(startUpdateSsid(input))
        .then(unwrapResult)
        .then(resp => {
          logger("startUpdatSsid resp =%o", resp);

          dispatch(startFetchSsid({
            networkId: network.id,
            ssidId: ssids[0].id,
          })); //update ssid name and password

          dispatchPage();
        })
        .catch(error => {
          setSavingError(true);
        });
    } else { // create ssid
      const input = {
        name: data.name,
        hasAuth: true,
        authMethod: "WPA2",
        authPassword: data.password,
        networkId: network.id,
      }
      dispatch(startCreateNetworkSsid(input))
        .then(unwrapResult)
        .then(resp => {
          logger("startCreateNetworkSsid resp =%o", resp);
          dispatch(startGetNetworkSsids({
            networkId: network.id
          })).then(resp => {
            dispatchPage();
          });
        })
        .catch(error => {
          setSavingError(true);
        });
    }
  }

  const onClickDoThisLater = () => {
    dispatchPage();
  }

  const passwordValue = watch('password', '');

  return (
    <>
      <section className="mb-6">
        <h2 className="mb-4">
          Name your personal WiFi
        </h2>
        <div className="sf-text-f-body2 sf-text-c-secondary">
          This is what you're going to use to connect your non-work devices to your WiFi.
        </div>
      </section>

      <section className="mb-6">
        <Form onSubmit={handleSubmit(doOnSubmit)}>
          <Form.Group className="mb-5">
            <Form.Label className={hasError(errors, `name`) ? "sf-text-c-red" : ""}>WiFi Name</Form.Label>
            <Form.Control name={"name"} type="text" className={hasError(errors, `name`) ? "is-invalid" : ""}
              {...register("name")} />
            {
              hasError(errors, `name`) &&
              <div className="row mt-1">
                <div className="col-12 sf-text-f-body2 sf-text-c-red">
                  Your wifi name is invaild
                </div>
              </div>
            }
          </Form.Group>
          <div className="mb-6">
            <Form.Label className={hasError(errors, `password`) ? "sf-text-c-red" : ""}>
              WiFi Password
            </Form.Label>
            <Form.Control name={"password"} type="text" className={hasError(errors, `password`) ? "is-invalid" : ""}
              {...register("password")} style={{backgroundImage: 'none', paddingRight: 12}}/>
            {
              hasError(errors, `password`) &&
              <div className="row mt-1">
                <div className="col-12 sf-text-f-body2 sf-text-c-red">
                  {
                    passwordValue.length < 8 ? 'Your password must have at leaset 8 characters' :
                    passwordValue.length > 62 ? 'Your password must have at most 62 characters' :
                    'invalid password'
                  }
                </div>
              </div>
            }
            {
              savingError &&
              <div className="row mt-1">
                <div className="col-12 sf-text-f-body2 sf-text-c-red">
                  We have a little trouble saving these settings.
                  <p>
                    Please try again.
                  </p>
                </div>
              </div>
            }
          </div>
          <div>
            {
              ((dirtyFields && dirtyFields.password && dirtyFields.password === true) || isValid)?
                <Button variant='primary' className='sf-btn-w-xlg' type='submit'>
                  Save
                </Button>
                :
                <Button variant="primary-reversed" className='sf-btn-w-xlg' type='submit'>
                  Save
                </Button>
            }
          </div>
        </Form>
      </section>
      <section>
        <div className="d-flex justify-content-center align-items-center">
          <Button variant="link" className="p-0 sf-link-primary mb-4" onClick={() => onClickDoThisLater()} >
            I'll do this later
          </Button>
        </div>
      </section>


    </>);
}
