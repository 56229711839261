import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import SetupPage from "./SetupPage"

export function SetupContainer() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <SetupPage/>
      </Route>
    </Switch>
  );

}
