import React, {useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faTimes} from '@fortawesome/free-solid-svg-icons';
import {Redirect, useHistory} from 'react-router-dom';
import {updateShowMobileMenu} from './_layout/_layoutSlice';
import {useDispatch} from 'react-redux';

import { ReactComponent as RefreshIcon } from '../_images/refreshIcon.svg';

const Page = (props) => {
  let {showBackButton, showClose, title, children, column, redirect, className, showBorder, refreshAction} = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const goBack = () => {
    history.goBack();
  };

    useEffect(() => {
        dispatch(updateShowMobileMenu(false));
    }, [dispatch]);

    return (
        <div className={`page-container ${showBorder ? 'show-border' : ''}`}>
          {redirect &&
          <Redirect to={redirect}/>
          }
          {showBackButton &&
          <div className="back-button" onClick={goBack}>
            <FontAwesomeIcon icon={faChevronLeft} className='back-button-icon'/>
          </div>
          }
          {showClose &&
          <div className="close-button" onClick={goBack}>
            <FontAwesomeIcon icon={faTimes} className='back-button-icon'/>
          </div>
          }
          <header className="d-flex justify-content-between align-items-center">
            {title && <h1 className="page-container-title">{title}</h1>}
            <div className="toolbox d-flex align-items-center">
              {refreshAction && <RefreshIcon className="toolIcon" alt="Refresh icon" onClick={refreshAction} />}
            </div>
          </header>
          <div className={`sub-page-container ${column ? 'column' : ''} ${className || ''}`}>
            {children}
          </div>
        </div>
    )
}

export default Page;
