import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {VpnSetupPage} from "./VpnSetupPage";

export const VpnContainer = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/setup`}>
        <VpnSetupPage/>
      </Route>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.url}/setup`}/>
      </Route>
      <Route path={`${match.path}`}>
        <Redirect to={`${match.url}/setup`}/>
      </Route>
    </Switch>
  );
};
