import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllSet } from "./AllSet";
import { HomeWrkSystem } from "./HomeWrkSystem";
import { SetupDetected } from "./SetupDetected";
import { StartCustomizationFlow } from "./StartCustomizationFlow";
import { StartWiringFlow } from "./StartWiringFlow";
import { selectSetupStep, SETUP_STEP, updateSetupStep } from "./_setupSlice";


export const SetupPage = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(updateSetupStep(SETUP_STEP.DETECTED));
    }
  }, [dispatch]);

  return (
    <div className="page-container">
      <RenderSetupFlow/>
    </div>
  );
};

const RenderSetupFlow = () => {
  const step = useSelector(selectSetupStep);

  switch (step) {
    case SETUP_STEP.DETECTED:
      return (
        <SetupDetected/>
      );
    case SETUP_STEP.HAVE_HOME_WRKSYSTEM:
      return (
        <HomeWrkSystem/>
      );
    case SETUP_STEP.START_CUSTOMIZATION_FLOW:
      return (
        <StartCustomizationFlow/>
      );
    case SETUP_STEP.START_WIRING_FLOW:
      return (
        <StartWiringFlow/>
      );
    case SETUP_STEP.ALL_SET:
      return (
        <AllSet/>
      );
    default:
      return (
        <>
          <SetupDetected/>
        </>
      );
  }
}

export default SetupPage;
