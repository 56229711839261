import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppContainer } from "../../common/AppContainer";
import { CheckingForInternet } from "./CheckingForInternet";
import { CheckWiring } from "./CheckWiring";
import { Connected } from "./Connected";
import { IdentityingModem } from "./IdentityingModem";
import { LightsStillOn } from "./LightsStillOn";
import { QuickStart } from "./QuickStart";
import { TroubleCommunicating } from "./TroubleCommunicating";
import { UnableConnect } from "./UnableConnect";
import { ISP_STEP, resetState, selectIspStep } from "./_ispSlice";

export const IspDetectionPage = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    }
  }, [dispatch]);

  return (
    <RenderIspPage/>
  )
};

function RenderIspPage() {
  const step = useSelector(selectIspStep);
  switch (step) {
    case ISP_STEP.QUICK_START:
      return (
        <AppContainer>
          <QuickStart/>
        </AppContainer>
      );
    case ISP_STEP.LIGHT_STILL_ON:
      return (
        <LightsStillOn />
      );
    case ISP_STEP.IDENTITYING_MODEM:
      return (
        <IdentityingModem/>
      );
    case ISP_STEP.CHECKING_INTERNET:
      return (
        <AppContainer>
          <CheckingForInternet />
        </AppContainer>
      );
    case ISP_STEP.CONNECTED:
      return (
        <AppContainer>
          <Connected />
        </AppContainer>
      );
    case ISP_STEP.UNABLE_CONNECT:
      return (
        <AppContainer>
          <UnableConnect />
        </AppContainer>
      );
    case ISP_STEP.CHECK_WIRING:
      return (
        <AppContainer>
          <CheckWiring />
        </AppContainer>
      );
    case ISP_STEP.TROUBLE_COMMUNICATING:
      return (
        <AppContainer>
          <TroubleCommunicating />
        </AppContainer>
      );
    default:
      return (<></>);
  }
}
