import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectNetworkUnProvisionedCounts } from "sf-ui-components";
import { useHistory } from "react-router-dom";

export const MoreNodeDone = () => {
  const unProvisionedCounts = useSelector(selectNetworkUnProvisionedCounts);
  // TODO
  // reload devices
  const history = useHistory();
  useEffect(
    () => {
      let timer = setTimeout(() => history.push('/confirm'),
        3000);
      return () => {
        clearTimeout(timer);
      };
      // eslint-disable-next-line
    }, []
  );
  return (
    <>
      <section>
        <h2 className='d-flex align-items-center justify-content-center'>
          You added the {unProvisionedCounts > 1 ? 'nodes' : 'node'}!
        </h2>
      </section>
      <section className='mt-9'>
        <img alt='' width='100%' src='https://via.placeholder.com/400x256'/>
      </section>
    </>
  );
}
