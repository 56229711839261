import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import IspConnected from "../../_images/ISP_Connected.png";
import { updateWiringStep, WIRING_STEP } from "../wiring/_wiringSlice";

export const Connected = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(
    () => {
      let timer = setTimeout(() => {
        history.push('/wiring');
      }, 3000);
      return () => {
        dispatch(updateWiringStep(WIRING_STEP.DONE));
        clearTimeout(timer);
      };
      // eslint-disable-next-line
    }, []
  );


  return (
    <section>
      <div className="d-flex justify-content-center mb-11">
        <div className="text-center">
          <h3>
            Connected!
          </h3>
        </div>
      </div>
      <div className="d-flex justify-content-center pt-2">
        <img alt='' width='100%' src={IspConnected}/>
      </div>
    </section>
  );
}
