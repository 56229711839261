import { superstructResolver } from "@hookform/resolvers/superstruct";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createLogger } from "sf-ui-components";
import * as s from "superstruct";
import IspCheckingWiring from "../../_images/ISP_checkingWiring.png";
import { ISP_STEP, updateIspStep } from "./_ispSlice";

const logger = createLogger('sfe:isp:CheckWiring');

export const CheckCard = ({title, message, className, register, name}) => {

  return (
    <div className={`rounded p-5 clickable sf-bg-gray-2 mx-0 ${className}`}>
      <div>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <h4>{title}</h4>
          <Form.Check type="checkbox" value={true} {...register(name)}/>
        </div>
        <div className="sf-text-f-body1 sf-text-c-secondary">
          {message}
        </div>
      </div>
    </div>
  );
}

const schema = s.object({
  checkedPower: s.optional(s.boolean()),
  checkedEthernet: s.optional(s.boolean()),
  checkedWires: s.optional(s.boolean()),
});


export const CheckWiring = () => {

  const dispatch = useDispatch();
  const [allChecked, setAllChecked] = useState(false);

  const form = useForm({
    mode: "onSubmit",
    resolver: superstructResolver(schema, {
      coerce: true
    }),
  });

  const {register, watch} = form;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      logger('value=%o, name=%o, type=%o', value, name, type);
      const checkPower = _.get(value, 'checkedPower', false);
      const checkedEthernet = _.get(value, 'checkedEthernet', false);
      const checkedWires = _.get(value, 'checkedWires', false);

      if (checkPower.toString().toLowerCase() === 'true' &&
          checkedEthernet.toString().toLowerCase() === 'true' &&
          checkedWires.toString().toLowerCase() === 'true'
      ) {
        setAllChecked(true);
      } else {
        setAllChecked(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <section className="mb-6">
        <h3 className="mb-4">
          Check your wiring
        </h3>
        <div className='sf-text-f-body1 sf-text-c-gray'>
          Check the boxes as you complete these steps
        </div>
      </section>
      <section className='mb-6'>
        <img alt='' width='100%' src={IspCheckingWiring}/>
      </section>

      <section className="mb-7">

        <CheckCard title="Power Card" className="mb-6"
          message="Is the internet cord from your wall connected to your modem?"
          register={register} name="checkedPower"
        />

        <CheckCard title="Ethernet Cable" className="mb-6"
          message="Is the ethernet cable between the port labeled Internet on your parent node and modem connected?
          Any open port on the modem should work."
          register={register} name="checkedEthernet"
        />

        <CheckCard title="Wires" className="mb-6"
          message="Are all of your wires snapped into place?"
          register={register} name="checkedWires"
        />
      </section>
      <section>
        {
          allChecked === true ?
          <Button variant='primary' className='sf-btn-w-xlg' onClick={() => dispatch(updateIspStep(ISP_STEP.CHECKING_INTERNET))}>
            Try Again
          </Button>
          :
          <Button variant='primary-reversed' className='sf-btn-w-xlg' disabled >
            Try Again
          </Button>
        }
      </section>
    </>
  )
};
