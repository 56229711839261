export const envBaseApiUrl = process.env.REACT_APP_SF_API_URL;

export const envClientTypeId = process.env.REACT_APP_SF_CLIENT_TYPE_ID;
export const envClientTypeSecret = process.env.REACT_APP_SF_CLIENT_TYPE_SECRET;

export const envDomainOverride = process.env.REACT_APP_SF_DOMAIN_OVERRIDE;

export function domainOrOverride(input) {
  if (envDomainOverride != null) {
    return envDomainOverride;
  }
  return input;
}

export const envPublic = process.env.PUBLIC_URL;

export const envCmvUiUrl = process.env.REACT_APP_SF_CMV_UI_URL;

export const envCmvApiUrl = process.env.REACT_APP_SF_CMV_API_URL;
