import React from "react";
import { useDispatch, useSelector } from "react-redux";
import IspIdentifying from "../../_images/ISP_identifying.png";
import { selectPreviousStep, updateIspStep } from "./_ispSlice";

export const IdentityingModem = () => {

  const dispatch = useDispatch();
  const previousStep = useSelector(selectPreviousStep);

  return (
    <div className="app-container">
      <div className="app-container-top"/>
      <div className="app-container-trunk px-2">
        <div className="d-flex align-items-center mb-5 pt-5">
          <i className='sf-icon-chevron-left cursor-pointer' onClick={() => dispatch(updateIspStep(previousStep))}/>
        </div>

        <section className="mb-6">
          <h3>
            Identifying your modem
          </h3>
        </section>
        <div className="mb-6">
          Modems are usually connected to internet via a coaxial cable or phone cable coming to your home.
        </div>
        <div className="mb-9">
          Note: Modems can be horizontal or vertical depending on the brand.
        </div>
        <section>
          <img alt='' width='100%' src={IspIdentifying} />
        </section>
      </div>
      <div className="app-container-bottom"/>
    </div>
  );

}
