import React from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useProfile, authSlice } from 'sf-ui-components';
import UserIcon from '../UserIcon';

/**
 * See @param dropdownList for example.
 *
 * @param menuConfig:

 defaultMenuConfig = {
  profilePath: '/profile',
  showProfile: true,
  showLogout: true,
  dropdownList: [
    {
      title: 'Profile',
      handleClick: ({ history }) => {
        logger('Profile is clicked');
        history.push('/profile');
      }
    },
    {
      title: 'Settings',
      handleClick: ({ history }) => {
        logger('Setting is clicked');
        history.push('/settings');
      }
    },
    {
      title: 'Logout',
      handleClick: ({ history }) => {
        logger('Logout is clicked');
      }
    }
  ]
}

 * @returns {JSX.Element}
 * @constructor
 */
export const MobileUserMenu = ({ menuConfig = defaultMenuConfig }) => {
  return <UserNavDropDown menuConfig={menuConfig} />;
};

export const defaultMenuConfig = {
  dropdownList: [
    {
        title: 'Profile',
        location: '/profile',
        className: 'mobile-user-menu-item'
    },
    {
        title: 'About',
        location: '/about',
        className: 'mobile-user-menu-item'
    },
    {
        title: 'Help',
        location: '/help',
        className: 'mobile-user-menu-item'
    },
    {
        title: 'Logout',
    }
  ]
};

const UserNavDropDown = ({ menuConfig }) => {
  const profile = useProfile();
  const { username = '', firstname = '', lastname = '' } = profile;

  return (
    <Nav className='mobile-user-menu-container'>
        <div className='mobile-user-menu-left'>
            <UserIcon profile={profile} />
        </div>
        <div className='mobile-user-menu-right'>
            <div className={`dropdown-item`} role='button'>
                {firstname} {lastname}
                <div id='auth-nav-email'>{username}</div>
            </div>
            {renderItems({ profile, menuConfig })}
        </div>
    </Nav>
  );
};

const renderItems = ({ profile, menuConfig: { dropdownList } }) => {
    return dropdownList.map((item, index) => {
        return <NavItem key={index} profile={profile} item={item} isLastItem={index === dropdownList.length - 1} />;
    });
};

const NavItem = ({ item, isLastItem }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  switch (item.title) {
    case 'Logout':
      return (
        <>
          {isLastItem && <NavDropdown.Divider />}
          <NavDropdown.Item
            key={item}
            onClick={() => {
              if (typeof item.handleClick === 'function') {
                item.handleClick({ history });
              }
              authSlice.startLogout({ dispatch, history }).then(() => {});
            }}
            className={item.className}
          >
            Sign out
          </NavDropdown.Item>
        </>
      );
    default:
      return (
        <Link key={item} to={item.location} className={`dropdown-item ${item.className}`} role='button'>
          {item.title}
        </Link>
      );
  }
};
