import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addToast,
  createLogger,
  isEmpty,
  LoadingOverlay,
  LoadingState,
  selectCustomerId,
  selectNetwork,
  selectNetworkDevicesLoadingState,
  selectNetworkLoadingState,
  startFetchFirstAccountNetwork,
  startFetchNetworkDevices,
  selectVpnSelfOptionLoadingState,
  startFetchAccountVpnOption,
  useProfile
} from "sf-ui-components";
import { selectPreferencesLoadingState, startFetchNetworkPreferences } from '../confirm/_confirmSlice';
import { selectNetworkSsidsLoadingState, startGetNetworkSsids } from "../wifi/_wifiSlice";
import { SETUP_STEP, updateSetupStep } from "./_setupSlice";

const logger = createLogger('sfe:topology:banner');


export function SetupDetected() {

  const dispatch = useDispatch();
  const history = useHistory();
  const network = useSelector(selectNetwork);
  const customerId = useSelector(selectCustomerId);
  const profile = useProfile();
  const accountId = _.get(profile, 'accountId', '');
  const networkLoadingState = useSelector(selectNetworkLoadingState);
  const preferencesLoadingState = useSelector(selectPreferencesLoadingState);
  const ssidsLoadingState = useSelector(selectNetworkSsidsLoadingState);
  const devicesLoadingState = useSelector(selectNetworkDevicesLoadingState);
  const vpnSelfOptionLoadingState = useSelector(selectVpnSelfOptionLoadingState);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (network == null && !isEmpty(accountId) && !isEmpty(customerId) && networkLoadingState === LoadingState.NONE) {
      dispatch(startFetchFirstAccountNetwork({
        customerId: customerId,
        accountId: accountId
      }))
        .then(unwrapResult)
        .then((resp) => {
          logger('Fetch account network successfully', resp);
        })
        .catch((error) => {
          logger('Fetch network failed:', error);
          addToast(`Fetch network failed: ${error.message}`, 'error');
        });
    }
  }, [accountId, customerId, dispatch, network, networkLoadingState]);


  useEffect(() => {
    if ( vpnSelfOptionLoadingState === LoadingState.NONE) {
      dispatch(startFetchAccountVpnOption({orgId: customerId, accountId: 'self'}))
        .then(unwrapResult)
        .then(resp => {
          logger('startFetchAccountVpnOption resp=%o', resp);
        })
        .catch((error) => {
          addToast(`Fetch vpn self option failed: ${error.message}`, 'error');
        });
      ;
    }
  }, [customerId, dispatch, vpnSelfOptionLoadingState]);

  useEffect(() => {
    if (network && preferencesLoadingState === LoadingState.NONE) {
      dispatch(startFetchNetworkPreferences({networkId: network.id}))
      .then(unwrapResult)
      .then((resp) => {
        logger('startFetchNetworkPreferences resp: %o', resp);
      })
      .catch((error) => {
        logger('Fetch Network Preferences failed:', error?.message);
      });

    }
  }, [dispatch, network, preferencesLoadingState]);

  useEffect(() => {
    if (network && ssidsLoadingState === LoadingState.NONE) {
      dispatch(startGetNetworkSsids({
        networkId: network.id
      }))
        .then(unwrapResult)
        .then(resp => {
          logger('startGetNetworkSsids resp=%o', resp);
        })
        .catch((error) => {
          addToast(`startGetNetworkSsids failed: ${error.message}`, 'error');
        });
    }
  }, [dispatch, network, ssidsLoadingState]);

  useEffect(() => {
    if (network != null && devicesLoadingState === LoadingState.NONE) {
      dispatch(startFetchNetworkDevices({
        networkId: network.id
      }))
        .then(unwrapResult)
        .then(resp => {
          logger('startFetchNetworkDevices resp=%o', resp);
        })
        .catch((error) => {
          addToast(`Fetch devices failed: ${error.message}`, 'error');
        });
    }
  }, [dispatch, network, devicesLoadingState]);


  useEffect(() => {
    if ( networkLoadingState === LoadingState.LOADED &&
         vpnSelfOptionLoadingState === LoadingState.LOADED &&
         ssidsLoadingState === LoadingState.LOADED) {
      setInitialized(true);
    }
  }, [networkLoadingState, ssidsLoadingState, vpnSelfOptionLoadingState]);


  if (initialized) {
    if (network != null && network.provisionStatus === "PROVISIONED") {
      history.push('/topology');
    } else {
      dispatch(updateSetupStep(SETUP_STEP.HAVE_HOME_WRKSYSTEM));
    }
  }

  return (
  <>
    <div className="h-100">
      <LoadingOverlay
        className="d-flex align-items-center justify-content-center display-1 h-75"
        initialized={initialized}>
        <div></div>
      </LoadingOverlay>
    </div>
  </>);

}
