import React, {useEffect, useState} from "react";
import {ProgressBar} from "react-bootstrap";

export const EmpProgressBar = ({start = 0, step = 1}) => {
  const [progress, setProgress] = useState(start);
  const [loop, setLoop] = useState();
  useEffect(() => {
    setLoop(
      setInterval(() => {
        setProgress((p) => {
          return p >= 95 ? p : p + step;
        });
      }, 1000)
    );
    return () => {
      clearInterval(loop);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ProgressBar now={progress}/>
  )
}
