import React, {useEffect} from "react";
import {EmpProgressBar} from "../../components/EmpProgressBar";
import {useDispatch, useSelector} from "react-redux";
import {selectNetwork, selectNetworkUnProvisionedCounts} from "sf-ui-components";
import {startWSetup, MORE_NODE_SETUP_STEP, updateMoreNodeSetupStep} from "./_nodeSlice";
import searchingNode from "../../_images/searching_node.png";

export const MoreNodeSearchingNode = () => {
  const unProvisionedCounts = useSelector(selectNetworkUnProvisionedCounts);
  const network = useSelector(selectNetwork);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startWSetup({
      networkId: network.id,
    }))
    .then(resp => {
      dispatch(updateMoreNodeSetupStep(MORE_NODE_SETUP_STEP.ADDING_NODES));
    });
  }, [dispatch, network.id]);

  return (
    <>
      <section>
        <h2>
          Searching for child {unProvisionedCounts > 1 ? 'nodes' : 'node'}...
        </h2>
      </section>
      <section className='mt-9'>
        <img alt='' src={searchingNode}/>
      </section>
      <section className='mt-9'>
        <EmpProgressBar start={0} step={25}/>
      </section>
    </>
  )
}
