import React from "react";
import {LoadingOverlay} from "sf-ui-components";

export const LoadingNetworkPage = () => {
  return (
    <>
      <h2>
        Just a moment while we get things ready...
      </h2>
      <LoadingOverlay
        initialized={false}
        className='d-flex align-items-center justify-content-center display-1 h-75'>
      </LoadingOverlay>
    </>
  );
}
