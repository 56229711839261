import { createSlice } from '@reduxjs/toolkit';
// import {createLogger} from "sf-ui-components";

// const logger = createLogger("sfe:isp:slice");

export const ISP_STEP = Object.freeze({
  QUICK_START: 0,
  LIGHT_STILL_ON: 1,
  IDENTITYING_MODEM: 2,
  CHECKING_INTERNET: 3,
  UNABLE_CONNECT: 4,
  CHECK_WIRING: 5,
  TROUBLE_COMMUNICATING: 6,
  CONNECTED: 7,
});

export const slice = createSlice({
  name: 'isp',
  initialState: {
    step: ISP_STEP.QUICK_START,
    detectionCount: 0,
  },
  reducers: {
    updateIspStep: (state, action) => {
      state.previousStep = state.step;
      state.step = action.payload;
    },
    increaseDetectionCount: (state, action) => {
      state.detectionCount = state.detectionCount + 1;
    },
    resetState: (state, action) => {
      state.previousStep = ISP_STEP.QUICK_START;
      state.step =ISP_STEP.QUICK_START;
      state.detectionCount = 0;
    }
  },
  extraReducers: {}
})

export const { updateIspStep, increaseDetectionCount, resetState } = slice.actions;
export const selectIspStep = state => state?.isp?.step;
export const selectPreviousStep = state => state?.isp?.previousStep;
export const selectDetectionCount = state => state?.isp?.detectionCount != null ? state?.isp?.detectionCount : 0;

export default slice.reducer;
