import { superstructResolver } from '@hookform/resolvers/superstruct';
import { unwrapResult } from "@reduxjs/toolkit";
import debug from 'debug';
import isEmail from 'is-email';
import React, { useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToast,
  hasError,
  isEmpty,
  LoadingState,
  selectNetwork,
  selectNetworkLoadingState,
  startFetchAccountNetwork,
  selectCustomerId
} from 'sf-ui-components';
import * as s from 'superstruct';
import { selectProfileData, startFetchProfile, startUpdateProfile } from '../profile/_profileSlice';
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { SETUP_STEP, updateSetupStep } from "../setup/_setupSlice";

const Email = s.define('Email', isEmail);
const logger = debug('sf:confirm:backupEamil');

export const BackupEmail = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const customerId = useSelector(selectCustomerId);
  const profile = useSelector(selectProfileData);
  const network = useSelector(selectNetwork);
  const accountId = _.get(profile, 'accountId', '');

  const networkLoadingState = useSelector(selectNetworkLoadingState);
  useEffect(() => {
    if (!isEmpty(accountId) && !isEmpty(customerId) && !network && networkLoadingState === LoadingState.NONE) {
      dispatch(startFetchAccountNetwork({
        customerId: customerId,
        accountId: accountId
      }))
        .then(unwrapResult)
        .then((resp) => {
          logger('Fetch account network successfully', resp);
        })
        .catch((error) => {
          logger('Fetch network failed:', error);
          addToast(`Fetch network failed: ${error.message}`, 'error');
        });
    }
  }, [dispatch, accountId, customerId, networkLoadingState, network]);

  const schema = s.object({
    backupEmail: Email,
  });

  const form = useForm({
    resolver: superstructResolver(schema, {
      coerce: true
    }),
  });

  const { handleSubmit, register, setValue, formState: { errors } } = form;

  useEffect(() => {
    dispatch(startFetchProfile());
  }, [dispatch]);

  useEffect(() => {
    if (profile != null) {
      setValue('backupEmail', profile.backupEmail);
    }
  }, [profile, setValue]);

  const doOnSubmit = async data => {
    logger("submit: data =%o", data);

    dispatch(startUpdateProfile(data))
      .then(unwrapResult)
      .then((resp) => {
        logger('save backup email successfully');
        const provisionStatus = _.get(network, 'provisionStatus');

        if (provisionStatus !== 'PROVISIONED') {
          dispatch(updateSetupStep(SETUP_STEP.ALL_SET));
          history.push('/setup');
        } else {
          history.push('/network/ready');
        }
      })
      .catch((error) => {
        addToast(`save backup email failed: ${error.message}`, 'error');
      });
  }


  const onClickSkip = () => {
    const provisionStatus = _.get(network, 'provisionStatus');

    if (provisionStatus !== 'PROVISIONED') {
      dispatch(updateSetupStep(SETUP_STEP.ALL_SET));
      history.push('/setup');
    } else {
      history.push('/network/ready');
    }
  }

  return (
    <>
      <section className="mb-6">
        <h3 className="mb-4">Add a backup email address</h3>
        <div>
          You can use a personal email address as an alternative way to log in to your Linksys HomeWRK settings.
          You'll log in with the same password that you use with your work email.
        </div>
      </section>
      <Form onSubmit={handleSubmit(doOnSubmit)}>
        <section className="mb-6">

            <Form.Label className={hasError(errors, `backupEmail`) ? "sf-text-c-red sf-font-body3" : "sf-text-secondary sf-font-f-body3"}>
              Email Address (Optional)
            </Form.Label>
            <Form.Control type="text" name={"backupEmail"} id={"backupEmail"} {...register("backupEmail")}
              className={hasError(errors, `backupEmail`) ? "is-invalid" : ""} style={{backgroundImage: 'none', paddingRight: 12}}
            />
            {
              hasError(errors, `backupEmail`) &&
              <div className="d-flex mt-2 sf-text-f-body2 sf-text-c-red">
                Please enter a valid email address.
              </div>
            }

        </section>
        <section className="mb-6">
          <Button variant='primary' className='sf-btn-w-xlg' type="submit">
            Next
          </Button>
        </section>
        <section>
          <div className='d-flex align-items-center justify-content-center'>
            <Button variant="link" className="p-0 sf-link-primary" onClick={onClickSkip}>
              Skip this step
            </Button>
          </div>
        </section>
      </Form>

    </>
  );

}


