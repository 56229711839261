import _ from "lodash";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, selectVpnSelfOption, useProfile } from "sf-ui-components";
import setupPackage from "../../_images/setup_package.png";
import { selectNetworkPreferences } from '../confirm/_confirmSlice';
import { selectSsids } from "../wifi/_wifiSlice";
import { SETUP_STEP, updateSetupStep } from "./_setupSlice";

export function HomeWrkSystem() {

  const profile = useProfile();
  const userName = _.get(profile, 'firstname', '');
  const dispatch = useDispatch();

  const networkPreferences = useSelector(selectNetworkPreferences);
  const ssids = useSelector(selectSsids);
  const vpnSelfOption = useSelector(selectVpnSelfOption);

  const onClickYes = () => {
    dispatch(updateSetupStep(SETUP_STEP.START_WIRING_FLOW));
  }

  const onClickNo = () => {

    if(isEmpty(networkPreferences) || isEmpty(ssids) || isEmpty(vpnSelfOption)) {
      dispatch(updateSetupStep(SETUP_STEP.START_CUSTOMIZATION_FLOW));
    } else {
      dispatch(updateSetupStep(SETUP_STEP.ALL_SET));
    }
  }

  return (
    <>
      <div className="text-center mt-9 mb-7">
        <h4>Welcome, {userName}</h4>
      </div>

      <div className="d-flex justify-content-center mt-7 mb-7">
        <img alt='' src={setupPackage}/>
      </div>

      <div className="text-center mt-7 mb-9">
        <h3>Have you received your Linksys HomeWRK system?</h3>
      </div>

      <div className="d-flex justify-content-center align-items-center mb-6">
        <Button variant="primary" className="mr-2 sf-btn-w-lg sf-btn-h-default" onClick={onClickYes}>Yes</Button>
        <Button variant="secondary" className="sf-btn-w-lg sf-btn-h-default" onClick={onClickNo}>No</Button>
      </div>

      <div className="text-center">
        View tracking information
      </div>
    </>


  );
}
