import React from "react";
import img1 from "../../_images/priority.png";

export function PriorityPage() {
  return (
    <>
      <img alt='Priority' src={img1} />
    </>
  );
}
