import React, {useEffect} from "react";
import {Button, Form} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  LoadingState,
  selectCustomerId,
  selectNetwork
} from "sf-ui-components";
import { AppContainer } from "../../common/AppContainer";
import {
  selectCorpSsid,
  selectDefaultSsid,
  selectNetworkSsidsLoadingState,
  selectSsids,
  startFetchCorpSsid,
  startFetchSsid,
  startGetNetworkSsids
} from "../wifi/_wifiSlice";

export const NetworkReadyPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const ssids = useSelector(selectSsids);
  const network = useSelector(selectNetwork);
  const personalSsid = useSelector(selectDefaultSsid);
  const corpSsid = useSelector(selectCorpSsid);
  const customerId = useSelector(selectCustomerId);
  const ssidsLoadingState = useSelector(selectNetworkSsidsLoadingState);

  useEffect(() => {
    if (network && ssidsLoadingState === LoadingState.NONE) {
      dispatch(startGetNetworkSsids({
        networkId: network.id
      }))
    }
  }, [dispatch, network, ssidsLoadingState]);

  useEffect(() => {
    if (ssids && ssids.length > 0 && personalSsid == null) {
      dispatch(startFetchSsid({
        networkId: network.id,
        ssidId: ssids[0].id,
      }));
    }
  }, [dispatch, ssids, personalSsid, network?.id]);

  useEffect(() => {
    if (corpSsid == null && customerId != null && network?.id) {
      dispatch(startFetchCorpSsid({
        orgId: customerId,
        networkId: network.id,
      }));
    }
  }, [dispatch, corpSsid, customerId, network?.id]);
  return (
    <AppContainer>
      <section>
        <h3>
          Your WiFi is ready! Use your networks based on what you're doing
        </h3>
      </section>
      <section className='mt-6'>
        <div className='mb-4'>
          <WorkWifi ssid={corpSsid}/>
        </div>
        <div className='mb-6'>
          <p className='sf-text-f-body2 sf-text-c-gray'>
            Connect authorized devices only. When prompted, log in with your Linksys HomeWRK email and password.
          </p>
        </div>
        <div className='mb-4'>
          <PersonalWifi ssid={personalSsid}/>
        </div>
        <div className='mb-6'>
          <p className='sf-text-f-body2 sf-text-c-gray'>
            Connect personal devices for all non-work activity.
          </p>
        </div>
      </section>
      <section className='mt-6'>
        <div>
          <Button variant='primary' className='sf-btn-w-xlg' type='submit'
                  onClick={() => {
                    history.push('/topology');
                  }}>
            Go to Dashboard
          </Button>
        </div>
      </section>
    </AppContainer>
  );
}

const PersonalWifi = ({ssid}) => {
  return (
    <div>
      <Form.Group>
        <div className='mb-2'>
          <h5>
            Personal WiFi
          </h5>
        </div>
        <Form.Control type="text" defaultValue={ssid?.name} readOnly/>
      </Form.Group>
      <Form.Group className="mb-6">
        <div className='mb-2'>
          <h5>
            Password
          </h5>
        </div>
        <Form.Control type="text" defaultValue={ssid?.authPassword} readOnly/>
      </Form.Group>
    </div>
  );
}

const WorkWifi = ({ssid}) => {
  return (
    <div>
      <Form.Group>
        <div className='mb-2'>
          <h5>
            Work WiFi
          </h5>
        </div>
        <Form.Control type="text" defaultValue={ssid?.name} readOnly/>
      </Form.Group>
    </div>
  );
}

