import React, {useEffect} from "react";
import {Button, Form} from "react-bootstrap";
import {MORE_NODE_SETUP_STEP, startWSetup, updateMoreNodeSetupStep} from "./_nodeSlice";
import {selectDefaultSsid, selectSsids, startFetchSsid} from "../wifi/_wifiSlice"
import {useDispatch, useSelector} from "react-redux";
import {selectNetwork} from "sf-ui-components";
import {useHistory} from "react-router-dom";
import nodePlacementOverall from "../../_images/node_placement_overall.png";


export const MoreNodePlaceChildNode = () => {
  const dispatch = useDispatch();
  const network = useSelector(selectNetwork);
  const ssids = useSelector(selectSsids);
  const personalSsid = useSelector(selectDefaultSsid);
  const history = useHistory();

  useEffect(() => {
    if (ssids && ssids.length > 0 && personalSsid == null) {
      dispatch(startFetchSsid({
        networkId: network.id,
        ssidId: ssids[0].id,
      }));
    }
  }, [dispatch, ssids, personalSsid, network?.id]);

  const handleAddNode = () => {

    dispatch(startWSetup({
      networkId: network.id,
    }))
    .then(resp => {
      dispatch(updateMoreNodeSetupStep(MORE_NODE_SETUP_STEP.ADDING_NODES));
    });
  }

  return (
    <>
      <section className='mb-6'>
        <h2>
          Place your child nodes where you need a WiFi boost
        </h2>
      </section>
      <section className='mb-6'>
        <img alt='' width='100%' src={nodePlacementOverall}/>
      </section>
      <section className='mb-6'>
        <div className='mb-6'>
          <h4 className='mb-4'>
            Place your nodes within range of your new network
          </h4>
          <p className='sf-text-f-body2 sf-text-c-gray'>
            Connect any wireless device to your new home network and check to see if the signal strength is at least 50% where you place
            each child node. They should be 1 to 2 rooms apart, with no more than 1 floor between them.
          </p>
        </div>
        <PersonalWifi ssid={personalSsid}/>
        <div className='mb-6'>
          <h4 className='mb-4'>
            Wait for the light to turn solid purple
          </h4>
          <p className='sf-text-f-body2 sf-text-c-gray'>
            When you plug in a node, the light will blink purple. It will become solid purple when it's ready for setup.
          </p>
        </div>
      </section>
      <section className='mb-6'>
        <div>
          <Button variant='primary' className='sf-btn-w-xlg' type='submit'
                  onClick={() => handleAddNode()}>
            I've Done This
          </Button>
        </div>
      </section>
      <section className='mb-6'>
        <div className='d-flex align-items-center justify-content-center mt-2'>
          <a href='#/' onClick={(e) => {
            e.preventDefault()
            history.push('/confirm');
          }}>
            Skip adding child nodes
          </a>
        </div>
      </section>
      <section>
        <div className='d-flex align-items-center justify-content-center'>
          <span className='sf-text-f-body2 sf-text-c-gray'>
            We're always happy to help!
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-center mt-2'>
          <a href='https://www.linksys.com/homewrk/help' target="_blank" rel="noreferrer noopener">
            Visit our support site
          </a>
        </div>
      </section>
    </>
  );
}

const PersonalWifi = ({ssid}) => {
  return (
    <div className="mb-6">
      <Form >
        <Form.Group>
            <Form.Label className="mb-4">Home Network</Form.Label>
          <Form.Control type="text" defaultValue={ssid?.name} readOnly/>
        </Form.Group>
        <Form.Group>
          <Form.Label className="mb-4">Password</Form.Label>
          <Form.Control type="text" defaultValue={ssid?.authPassword} readOnly/>
        </Form.Group>
      </Form>
    </div>
  );
}
