import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import assignedMobileChild from "../../_images/assigned_mobile_child.png";
import assignedMobileParent from "../../_images/assigned_mobile_parent.png";
import { selectNetworkDevices } from "sf-ui-components";
import { updateWifiSetupStep, WIFI_SETUP_STEP } from "./_wifiSlice";

export function NodesAssigned() {
  const devices = useSelector(selectNetworkDevices);
  const dispatch = useDispatch();

  const onClickYes = () => {
    dispatch(updateWifiSetupStep(WIFI_SETUP_STEP.START_SETUP_WIRING));
  }

  const onClickNo = () => {
    dispatch(updateWifiSetupStep(WIFI_SETUP_STEP.NODES_COMMINGSOON));
  }

  return (
    <>
      <section className="mb-8">
        <h2>
          You have {devices && devices.data && devices.data.length > 1 ? devices.data.length + " nodes " :
            devices && devices.data ? devices.data.length + " node " : " 0 nodes "} assigned to you.
            Do you have them yet?
        </h2>
      </section>
      <section className="mb-11">
        {
          devices.data.length > 0 &&
          devices.data.map((device, index) => {
            if (index === 0) {
              return ( <img key={index} alt='' width='100%' src={assignedMobileParent}/>);
            } else {
              return ( <img key={index} className="mt-5" alt='' width='100%' src={assignedMobileChild}/>);
            }
          })
        }
      </section>
      <section>
        <div className="d-flex justify-content-center">
          <Button variant='primary' className='sf-btn-w-lg mr-2' onClick={onClickYes}>
            Yes
          </Button>
          <Button variant='primary' className='sf-btn-w-lg' onClick={onClickNo}>
            No
          </Button>

        </div>

      </section>

    </>
  );
}
