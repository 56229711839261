import { React, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStorageValue, setStorageValue } from '../../common/utilities';
import { Col, Container, Nav, Row, Tab, Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
  restartWiFiSignal,
  rebootNode,
  editNodeName,
  fetchNodeDetails,
  LoadingOverlay,
  nodeDetailsIsOnline,
  nodeDetailsIsMaster,
  nodeDetailsLanAddress,
  nodeDetailsName,
  nodeDetailsSerialNumber,
  nodeDetailsSku,
  nodeDetailsStatus,
  nodeDetailsWanAddress,
  nodeDetailsFirmwareVersion,
  nodeDetailsFirmwareUpdateAvailable,
  selectCustomerId,
  selectNetworkId,
  selectViewTopology,
  startFetchAccountNetwork,
  startFetchTopology,
  useConfirmDialog,
  useProfile
} from 'sf-ui-components';
import { useWindowSize } from '../../common/hooks';
import { faCheck, faChevronLeft, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Page from '../../common/Page';
import KebabMenu from '../../common/_layout/KebabMenu';
import nodeImage from '../../_images/routerIcons/mx53.svg';
import refreshIcon from '../../_images/refreshIcon.svg';
import './NodeDetails.scss';

const SM_BREAK_POINT = 576;
const RowItem = ({ className, mobileClassName, label, value }) => {
  const windowSize = useWindowSize();
  return (
      <>
        {windowSize.width < SM_BREAK_POINT ? (
            <div className={mobileClassName}>
              <label>{label}</label>
              <h5 className="value mt-1">{value}</h5>
            </div>
        ) : (
            <Row className={className}>
              <Col as="label" sm="4" md="3" lg="3" xl="2">
                {label}
              </Col>
              <Col as="h5" className="value">
                {value}
              </Col>
            </Row>
        )}
      </>
  );
};

const FirmwareStatus = (status) => {
  return (
      <>
        {status.fwUpdateAvailable ? (
            <></>
        ) : (
            <div className="fwStatus">
              <FontAwesomeIcon className="ml-0 ml-md-4" icon={faCheck} color="#3bb287" />
              <span>Up to date</span>
            </div>
        )}
      </>
  );
};

function NodeDetailsPage() {
  const topology = useSelector(selectViewTopology);
  const networkId = useSelector(selectNetworkId);
  const customerId = useSelector(selectCustomerId);
  const profile = useProfile();
  const accountId = profile?.accountId ?? '';
  const { deviceId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isNodeDetailsEmpty, rebooting, loading } = useSelector(nodeDetailsStatus);
  const isMaster = useSelector(nodeDetailsIsMaster);
  const sku = useSelector(nodeDetailsSku);
  const serialNumber = useSelector(nodeDetailsSerialNumber);
  const firmwareVersion = useSelector(nodeDetailsFirmwareVersion);
  const fwUpdateAvailable = useSelector(nodeDetailsFirmwareUpdateAvailable);
  const isOnline = useSelector(nodeDetailsIsOnline); // TODO: use IoT service (DEVICE_ONLINE)
  const name = useSelector(nodeDetailsName);
  const wanAddress = useSelector(nodeDetailsWanAddress);
  const lanAddress = useSelector(nodeDetailsLanAddress);
  const activeTab = getStorageValue('activeTab', 'devices');
  const displaySpinner = isNodeDetailsEmpty || loading;
  const { dialog: restartNodeDialog, showDialog: showRestartNodeDialog } = useConfirmDialog({
    variant: {
      buttons: [
        {
          variant: 'secondary',
          text: 'Cancel',
          result: false
        },
        {
          variant: 'primary',
          text: 'Restart',
          result: true
        }
      ]
    },
    backdropClassName: 'sf-employee',
    dialogClassName: 'sf-employee sf-bg-white',
    dialogBodyClassName: 'pb-6',
    buttonClassName: 'sf-btn-w-default mr-4'
  });
  const { dialog: editNodeNameDialog, showDialog: showEditNodeNameDialog } = useConfirmDialog({
    variant: {
      buttons: []
    },
    backdropClassName: 'sf-employee',
    dialogClassName: 'sf-employee sf-bg-white',
    dialogBodyClassName: 'pb-0',
    buttonClassName: 'sf-btn-w-default mr-4'
  });
  const fetchData = () => {
    if (networkId && topology && !rebooting) {
      dispatch(
          fetchNodeDetails({
            networkId,
            orgId: customerId,
            deviceId,
            masterMacAddress: topology?.root?.macAddress
          })
      );
    }
  };
  const NodeNameForm = ({ save, closeDialog }) => {
    const {
      register,
      handleSubmit,
      formState: { errors }
    } = useForm();
    return (
        <Form onSubmit={handleSubmit(save)}>
          <Form.Label className="sf-text-secondary">Name</Form.Label>
          <Form.Control
              name="name"
              type="text"
              defaultValue={name}
              {...register('name', { required: true })}
              className={errors.name ? 'is-invalid shake' : ''}
          />
          {errors.name && <div className="invalid-feedback d-block">Please provide a valid name.</div>}

          <div class="justify-content-start modal-footer p-0 mt-6">
            <Button variant="secondary" className="sf-btn-w-default mr-4" onClick={closeDialog}>
              Cancel
            </Button>
            <Button variant="primary" className="sf-btn-w-default" type="submit">
              Save
            </Button>
          </div>
        </Form>
    );
  };
  const editName = () => {
    const closeDialog = () => {
      editNodeNameDialog.props.setShow(false);
    };
    const save = (data) => {
      dispatch(
          editNodeName({
            name: data.name,
            deviceId,
            networkId,
            orgId: customerId
          })
      );
      closeDialog();
    };

    showEditNodeNameDialog(
        <>
          <h3 className="mb-4">Edit Name</h3>
          <NodeNameForm save={save} closeDialog={closeDialog} />
        </>
    );
  };
  const restart = () => {
    showRestartNodeDialog(() => (
        <>
          <h3 className="mb-4">Restart node</h3>
          <h5 className="sf-text-secondary">What will happen</h5>
          <p className="sf-text-secondary">This node will turn completely off, then on again.</p>

          <h5 className="sf-text-secondary">When to use</h5>
          <p className="sf-text-secondary">Troubleshooting connectivity issues.</p>

          <h5 className="sf-text-secondary">Estimated time</h5>
          <p className="sf-text-secondary">3-5 minutes.</p>
        </>
    )).then((res) => {
      if (res) {
        dispatch(
            rebootNode({
              networkId,
              deviceId
            })
        );
      }
    });
  };
  const restartWiFi = () => {
    dispatch(
        restartWiFiSignal({
          networkId,
          deviceId
        })
    );
  };
  const kebabMenuConfig = {
    dropdownList: [
      {
        title: 'Edit name',
        onClick: editName
      },
      {
        title: 'Restart WiFi signal',
        onClick: restartWiFi
      },
      {
        title: 'Restart node',
        onClick: restart
      }
    ]
  };

  useEffect(() => {
    if (networkId && !topology) {
      dispatch(
          startFetchTopology({
            networkId
          })
      );
    }
  }, [dispatch, networkId, topology]);

  useEffect(() => {
    if (!networkId) {
      dispatch(
          startFetchAccountNetwork({
            customerId,
            accountId
          })
      );
    }
  }, [dispatch, networkId, customerId, accountId]);

  useEffect(fetchData, [dispatch, networkId, topology, customerId, deviceId, rebooting]);

  return (
      <Page className="nodeDetails" column>
        <LoadingOverlay className="d-flex align-items-center justify-content-center display-4 mb-4" initialized={!displaySpinner}>
          <div className="text-center"></div>
        </LoadingOverlay>
        {!displaySpinner && (
            <>
              <Tab.Container
                  defaultActiveKey={activeTab}
                  onSelect={(activeTab) => {
                    setStorageValue('activeTab', activeTab);
                  }}
              >
                <div className="mobilePanel">
                  <div className="pageTools d-flex justify-content-between align-items-center">
                    <FontAwesomeIcon className="back-button back-button-icon" icon={faChevronLeft} onClick={history.goBack} />
                    <div className="right d-flex align-items-center">
                      <img src={refreshIcon} className="toolIcon mr-3" alt="Refresh icon" />
                      <KebabMenu kebabMenuConfig={kebabMenuConfig} />
                    </div>
                  </div>

                  <header className="d-flex mt-5 mb-6 align-items-center">
                    <img className="" src={nodeImage} alt={`Icon for ${sku}`} />
                    <div className="ml-6">
                      <h1>{name}</h1>
                      <div className="d-flex align-items-center mt-3 mb-3">
                        <FontAwesomeIcon
                            icon={faCircle}
                            color={isOnline ? '#13c59d' : '#ff465d'}
                            style={{ width: '0.625rem' }}
                            className="ml-1 mr-2"
                        />
                        <span style={{ fontSize: '0.9375rem' }}>{isOnline ? 'Online' : 'Offline'}</span>
                      </div>
                    </div>
                  </header>
                  <Nav variant="pills">
                    <Nav.Item className="mr-0">
                      <Nav.Link eventKey="devices">Devices (11)</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="details">Details</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

                <Tab.Content className="mobilePanel flex-fill pt-8 pb-8">
                  <Tab.Pane eventKey="devices">Device table</Tab.Pane>
                  <Tab.Pane eventKey="details">
                    <h3 className="mb-5">{isMaster ? 'Parent node' : 'Child node'}</h3>
                    <Container className="nodeSettings" fluid>
                      <RowItem label="Serial Number" value={serialNumber} />
                      <RowItem label="Model Number" value={sku} />
                      <RowItem
                          label="Firmware Version"
                          mobileClassName="mt-5"
                          value={
                            <>
                              {firmwareVersion} <FirmwareStatus fwUpdateAvailable={fwUpdateAvailable} />
                            </>
                          }
                      />

                      {isMaster && <RowItem className="mt-8" mobileClassName="mt-5" label="WAN IP Address" value={wanAddress} />}
                      <RowItem className="mt-8" mobileClassName="mt-5" label="LAN IP Address" value={lanAddress} />
                    </Container>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
              {restartNodeDialog}
              {editNodeNameDialog}
            </>
        )}
      </Page>
  );
}

export default NodeDetailsPage;
