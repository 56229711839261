import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomerConfig, updateVpnSetupStep, VPN_SETUP_STEP } from "sf-ui-components";
import _ from "lodash";
import { Button } from "react-bootstrap";
import vpnInfo from "../../_images/vpn_info.png";

export const VpnIntroduction = () => {
  const customerConfig = useSelector(selectCustomerConfig);
  const customerName = _.get(customerConfig, 'customerName');
  const dispatch = useDispatch();
  const [showVpnDesc, setShowVpnDesc] = useState(false);
  return (
    <>
      <section>
        <h2>
          Next, we'll ask for your {customerName} VPN details
        </h2>
      </section>
      <section className='mt-5'>
        <img alt='' width='100%' src={vpnInfo}/>
      </section>
      <section className='mt-5 sf-text-f-body1 sf-text-c-secondary'>
        <div>
          Linksys HomeWRK securely stores your {customerName} VPN username and password
          so that authorized devices on the corporate network automatically connect to VPN.
        </div>
        <div className='mt-5'>
          This only saves your information. It doesn't connect any devices right now.
          Once your system is set up, any device joining the corporate network will connect to VPN automatically.
        </div>
      </section>
      <section className='mt-5'>
        <a href='#/' onClick={(e) => {
          e.preventDefault();
          setShowVpnDesc(!showVpnDesc);
        }}>
          <h3>What's a VPN?</h3>
        </a>
        {
          showVpnDesc &&
          <div className='mt-5 sf-text-f-body1 sf-text-c-secondary'>
            A virtual private network allows you to connect remotely to the {customerName} network as if you were directly connected in the
            office. This allows access to files and web pages only available through the company network.
          </div>
        }
      </section>
      <section className='mt-5'>
        <div>
          <Button variant='primary' className='sf-btn-w-xlg' type='submit'
                  onClick={() => dispatch(updateVpnSetupStep(VPN_SETUP_STEP.CONFIGURE))}>
            Next
          </Button>
        </div>
      </section>
    </>
  );
}
