import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: 'layout',
  initialState: {
    showMobileMenu: false
  },
  reducers: {
    updateShowMobileMenu: (state, action) => {
        state.showMobileMenu = action.payload;
    },
  },
  extraReducers: {
  }
})

export const { updateShowMobileMenu } = slice.actions;

export const selectShowMobileMenu = state => state?.layout?.showMobileMenu;

export default slice.reducer;
