import React, { useEffect } from 'react';
import Page from '../../common/Page';
import { DevicesTabs, selectCustomerId, selectNetworkId, startFetchFirstAccountNetwork, useProfile } from 'sf-ui-components';
import { useDispatch, useSelector } from "react-redux";
import { useConnectedClients } from "sf-ui-components";

export function DevicesPage() {
  const dispatch = useDispatch();
  const { accountId } = useProfile();
  const customerId = useSelector(selectCustomerId);
  const networkId = useSelector(selectNetworkId);

  const clientsData = useConnectedClients({
    networkId, portal: 'employee', forceReload: true
  });

  useEffect(() => {
    if (!networkId) {
      dispatch(startFetchFirstAccountNetwork({
        customerId, accountId,
      }));
    }
  }, [dispatch, customerId, accountId, networkId]);

  return (
    <Page title="Devices" refreshAction={clientsData.reload} column>
      <DevicesTabs {...clientsData} />
    </Page>
  );
}
