import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { DevicesPage } from './DevicesPage';

export function DevicesContainer() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path}>
        <DevicesPage />
      </Route>
    </Switch>
  );
}
