import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import IspErrorConnection from "../../_images/ISP_errorConnection.png";
import { ISP_STEP, updateIspStep } from "./_ispSlice";

export const TroubleCommunicating = () => {

  const dispatch = useDispatch();

  return (
    <>
      <section className="mb-6">
        <div className="mb-4">
          <h3>We're having trouble communicating with your modem </h3>
        </div>
        <div className="sf-text-f-body1 sf-text-c-gray">
          But don't worry, you're a call away from expert help.
        </div>
      </section>
      <section className='mb-6'>
        <img alt='' width='100%' src={IspErrorConnection}/>
      </section>
      <section className="mb-6">
        <div>
          <Button variant='primary' className='sf-btn-w-xlg' onClick={() => dispatch(updateIspStep(ISP_STEP.CHECKING_INTERNET))}>
            I've Done This
          </Button>
        </div>
      </section>
      <section>
        <div className="text-center mb-2">
          <div className="sf-text-f-body1 sf-text-c-gray">We're always happy to help!</div>
        </div>
        <div className="text-center">
          <a href='https://www.linksys.com/homewrk/help' target="_blank" rel="noreferrer noopener">
            Visit our support site
          </a>
        </div>
      </section>
    </>
  );

}
