import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { WifiSetupFlow } from "./WifiSetupFlow"

export const WifiContainer = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/setup`}>
        <WifiSetupFlow />
      </Route>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.url}/setup`} />
      </Route>
      <Route path={`${match.path}`}>
        <Redirect to={`${match.url}/setup`} />
      </Route>
    </Switch>
  );
}
