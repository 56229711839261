import { unwrapResult } from "@reduxjs/toolkit";
import debug from 'debug';
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { LoadingOverlay, LoadingState, selectNetwork, selectVpnSelfOption } from "sf-ui-components";
import { selectNetworkPreferences, selectPreferencesLoadingState, startFetchNetworkPreferences } from '../confirm/_confirmSlice';
import { MORE_NODE_SETUP_STEP, updateMoreNodeSetupStep } from "../node/_nodeSlice";
import { selectSsids, startFetchSsid, updateWifiSetupStep, WIFI_SETUP_STEP } from "./_wifiSlice";

const logger = debug('sf:employee:NodesScanningPage');

export function NodesScanningPage() {
  const [processing, setProcessing] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const network = useSelector(selectNetwork);
  const ssids = useSelector(selectSsids);
  const vpnSelfOption = useSelector(selectVpnSelfOption);
  const networkPreferences = useSelector(selectNetworkPreferences);
  const preferencesLoadingState = useSelector(selectPreferencesLoadingState);

  useEffect(() => {
    if (preferencesLoadingState === LoadingState.NONE && network) {
      dispatch(startFetchNetworkPreferences({networkId: network.id}))
      .then(unwrapResult)
      .then((resp) => {
        logger('startFetchNetworkPreferences resp: %o', resp);
      })
      .catch((error) => {
        logger('Fetch Network Preferences failed:', error?.message);
      });

    }
  }, [dispatch, network, preferencesLoadingState]);

  useEffect(() => {

    if (network) {

      const unProvisionedCounts = _.get(network, 'stat.unProvisionedCounts', 0);
      const deviceCounts = _.get(network, 'stat.deviceCounts', 0);

      if (deviceCounts === 0 || deviceCounts === unProvisionedCounts) { // unprovisioned
        dispatch(updateWifiSetupStep(WIFI_SETUP_STEP.NODES_ASSIGNED));
      } else { // associated
        if (ssids != null && ssids.length > 0) { // had personal wifi
          setProcessing(true);
          dispatch(startFetchSsid({
            networkId: network.id,
            ssidId: ssids[0].id,
          })).then(() => {
            if (_.isEmpty(vpnSelfOption)) {
              history.push('/vpn');
            } else {
              if (unProvisionedCounts > 0) {
                dispatch(updateMoreNodeSetupStep(MORE_NODE_SETUP_STEP.NODE_DETECTED));
                history.push('/node/setup/more');
              } else {
                if (networkPreferences != null && networkPreferences.city != null && networkPreferences.city.cityId != null) {
                  history.push('/network/ready');
                } else {
                  history.push('/confirm');
                }
              }
            }
          })
        } else { // no personal wifi
          dispatch(updateWifiSetupStep(WIFI_SETUP_STEP.NAME_WIFI_INFO));
        }

      }
    }
  }, [dispatch, history, network, networkPreferences, ssids, vpnSelfOption]);

  return (
    <>
      <section>
        <h2>
          Just a moment while we get things ready...
        </h2>
      </section>
      <div className="h-100">
        <LoadingOverlay
          className="d-flex align-items-center justify-content-center display-1 h-75"
          initialized={!processing}>
          <div></div>
        </LoadingOverlay>

      </div>
    </>);
}
