import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ProfilePage from './ProfilePage';

export function ProfileContainer() {
  const match = useRouteMatch();
  
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <ProfilePage/>
      </Route>
      <Route exact path={`${match.path}/settings`}>
          <div>Settings</div>
      </Route>
    </Switch>
  );

}