import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import debug from 'debug';
import _ from "lodash";
import { composeUrl, LoadingState, unwrapErrorResponse } from 'sf-ui-components';
import { envBaseApiUrl } from "../../app/env";

export const WIFI_SETUP_STEP = {
  // BEGIN_SCANNING: 0,
  // NODES_ASSIGNED: 2,
  // NODES_COMMINGSOON: 3,
  NAME_WIFI_INFO: 0,
  NAME_WIFI: 1,
  WIFI_SETUP_COMPLETED: 2,
  START_SETUP_WIRING: 3,
  PARENT_NODE_SETUP: 4,
};

const logger = debug('sf:wifi:slice');

export const startGetNetworkSsids = createAsyncThunk(
  'wifi/getNetworkWifiSsids',
  async (input, thunkAPI) => {
    logger('[getNetworkWifi] begin: %o', input);
    const { networkId } = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/ssids`);

    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      })
  }
);

export const startCreateNetworkSsid = createAsyncThunk(
  'wifi/createNetworkSsid',
  async (input, thunkAPI) => {
    logger('[createNetworkSsid] begin: %o', input);
    const { networkId, ...rest } = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/ssids`);

    return axios.post(url, rest)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      })
  }
);

export const startUpdateSsid = createAsyncThunk(
  'wifi/updateSsid',
  async (input, thunkAPI) => {
    logger('[updateSsid] begin: %o', input);
    const {networkId, ssidId, ...rest} = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/ssids/${ssidId}`);

    return axios.put(url, rest)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      })
  }
);

export const startFetchSsid = createAsyncThunk(
  'wifi/fetchSsid',
  async (input, thunkAPI) => {
    logger('[fetchSsid] begin: %o', input);
    const {networkId, ssidId} = input;
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/ssids/${ssidId}`);

    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      })
  }
);

export const startFetchCorpSsid = createAsyncThunk(
  'wifi/fetchCorpSsid',
  async (input, thunkAPI) => {
    logger('[fetchCorpSsid] begin: %o', input);
    const {orgId, networkId} = input;
    const url = composeUrl(`${envBaseApiUrl}/organization-service/rest/organizations/${orgId}/corpnet`, {
      networkId: networkId
    });

    return axios.get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      })
  }
);

export const slice = createSlice({
  name: 'wifi',
  initialState: {
    ssidsLoadingState: LoadingState.NONE,
    step: WIFI_SETUP_STEP.NAME_WIFI_INFO,
  },
  reducers: {
    updateWifiSetupStep: (state, action) => {
      state.step = action.payload;
    },
  },
  extraReducers: {
    [startGetNetworkSsids.pending]: (state, action) => {
      logger("startGetNetworkSsids.pending: %o, %o", state, action);
      state.ssidsLoadingState = LoadingState.LOADING;
    },
    [startGetNetworkSsids.fulfilled]: (state, action) => {
      logger("startGetNetworkSsids.fulfilled: %o, %o", state, action);
      state.ssids = action?.payload?.data;
      state.ssidsLoadingState = LoadingState.LOADED;
    },
    [startGetNetworkSsids.rejected]: (state, action) => {
      logger("startFetchCustomerDevices.rejected: %o, %o", state, action);
      state.ssidsLoadingState = LoadingState.LOADED;
    },
    [startFetchSsid.pending]: (state, action) => {
      logger("startFetchkSsid.pending: %o, %o", state, action);
    },
    [startFetchSsid.fulfilled]: (state, action) => {
      logger("startFetchkSsid.fulfilled: %o, %o", state, action);
      state.defaultSSid = action?.payload;
    },
    [startFetchSsid.rejected]: (state, action) => {
      logger("startFetchkSsid.rejected: %o, %o", state, action);
    },
    [startFetchCorpSsid]: (state, action) => {
      logger("startFetchCorpSsid.pending: %o, %o", state, action);
    },
    [startFetchCorpSsid.fulfilled]: (state, action) => {
      logger("startFetchCorpSsid.fulfilled: %o, %o", state, action);
      state.corpSsid = action?.payload;
    },
    [startFetchCorpSsid.rejected]: (state, action) => {
      logger("startFetchCorpSsid.rejected: %o, %o", state, action);
    },

  }
})


export const { updateWifiSetupStep } = slice.actions;

export const selectNetworkSsidsLoadingState = state => _.get(state, 'wifi.ssidsLoadingState', LoadingState.NONE);
export const selectWifiSetupStep = state => state?.wifi?.step;
export const selectSsids = state => _.get(state, 'wifi.ssids', undefined);
export const selectDefaultSsid = state => state?.wifi?.defaultSSid;
export const selectCorpSsid = state => state?.wifi?.corpSsid;

export default slice.reducer;
