import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppContainer} from "../../common/AppContainer";
import {NODE_SETUP_STEP, selectNodeSetupStep, updateNodeSetupStep} from "./_nodeSlice";
import {StepNodeNotFound} from "./StepNodeNotFound";
import {StepGoAhead} from "./StepGoAhead";
import {StepAddingNodes} from "./StepAddingNodes";
import {StepNodeAdded} from "./StepNodeAdded";
import {selectNetwork, selectNetworkLoadingState} from "sf-ui-components";
import {createLogger, LoadingState} from "sf-ui-components";

const logger = createLogger('sfe:node:setup:page');

export const NodeSetupPage = () => {
  const networkLoadingState = useSelector(selectNetworkLoadingState);
  const dispatch = useDispatch();
  const network = useSelector(selectNetwork);

  useEffect(() => {
    if (networkLoadingState === LoadingState.LOADED) {
      if (network == null) {
        logger('Network not found');
        dispatch(updateNodeSetupStep(NODE_SETUP_STEP.NODE_NOT_FOUND));
      }
    }
  }, [network, dispatch, networkLoadingState]);

  return (
    <AppContainer>
      <RenderNodeSetupFlow/>
    </AppContainer>
  )
};

function RenderNodeSetupFlow() {
  const step = useSelector(selectNodeSetupStep);
  switch (step) {
    case NODE_SETUP_STEP.NODE_NOT_FOUND:
      return (
        <>
          <StepNodeNotFound/>
        </>
      );
    case NODE_SETUP_STEP.GO_AHEAD:
      return (
        <>
          <StepGoAhead/>
        </>
      );
    case NODE_SETUP_STEP.ADDING_NODE:
      return (
        <>
          <StepAddingNodes/>
        </>
      );
    case NODE_SETUP_STEP.NODE_ADDED:
      return (
        <>
          <StepNodeAdded/>
        </>
      );
    default:
      return (
        <>
          <StepNodeNotFound/>
        </>
      );
  }
}
