import React from "react";
import {Button} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import assignedMobileStartSetup from "../../_images/assigned_mobile_start_setup.png";

export function StartSetupWiring() {
  const history = useHistory();
  const onClick = () => {
    history.push('/wiring');
  }

  return (
    <>
      <section className="mb-8">
        <h2>
          Get ready for a whole home coverage!
        </h2>
      </section>
      <section className="mb-8">
        <img alt='' width='100%' src={assignedMobileStartSetup} />
      </section>
      <section className="mb-8">
        Let's get you started
      </section>

      <section className="pt-2">
        <div className="d-flex justify-content-center">
          <Button variant='primary' className='sf-btn-w-xlg' onClick={onClick}>
            Start Setup
          </Button>
        </div>
      </section>
    </>
  );
}
