import React, { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  isEmpty,
  LoadingState,
  selectCustomerId,
  selectVpnSelfOption,
  selectVpnSelfOptionLoadingState,
  selectNetwork,
  selectNetworkUnProvisionedCounts,
  startFetchAccountVpnOption
} from "sf-ui-components";
import { MORE_NODE_SETUP_STEP, updateMoreNodeSetupStep } from "../node/_nodeSlice";
import { SETUP_STEP, updateSetupStep } from "../setup/_setupSlice";
import { selectNetworkSsidsLoadingState, startGetNetworkSsids } from "../wifi/_wifiSlice";
import { Banner } from './Banner';

const PromptVpnSetting = () => {

  const history = useHistory();

  const letsVpnSetting = () => {
    return (
      <Button variant="link" className="p-0 sf-link-primary" onClick={() => history.push('/vpn')}>
        Let's do it.
      </Button>
    )
  }

  return (
    <Col xs={12} className="mb-5">
      <Banner
        title="Let's finish setup"
        message="Enter your company's VPN details to connect to your secure corporate network. "
        handleFun={letsVpnSetting}/>
    </Col>
  );
}

const PromptAddNode = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSetUpMyNode = () => {
    dispatch(updateMoreNodeSetupStep(MORE_NODE_SETUP_STEP.PLACE_CHILD_NODES));
    history.push('/node/setup/more');
  }

  const SetUpMyNode = () => {
    return (
      <Button variant="link" className="p-0 sf-link-primary" onClick={() => handleSetUpMyNode()}>
        Set up my node
      </Button>
    )
  }

  return (
    <Col xs={12} className="mb-5">
      <Banner
        title="A node is ready for setup"
        message="Let's get it up and running to expand your Wifi coverage. "
        handleFun={SetUpMyNode}/>
    </Col>
  );
};

export function SetupPrompt() {

  const dispatch = useDispatch();
  const history = useHistory();
  const customerId = useSelector(selectCustomerId);
  const network = useSelector(selectNetwork);
  const unProvisionedCounts = useSelector(selectNetworkUnProvisionedCounts);
  const vpnSelfOption = useSelector(selectVpnSelfOption);
  const vpnSelfOptionLoadingState = useSelector(selectVpnSelfOptionLoadingState);
  const [initialized, setInitialized] = useState(false);
  const ssidsLoadingState = useSelector(selectNetworkSsidsLoadingState);

  useEffect(() => {
    if (network != null && ssidsLoadingState === LoadingState.NONE) {
      dispatch(startGetNetworkSsids({
        networkId: network.id
      }))
    }
  }, [dispatch, network, ssidsLoadingState]);

  useEffect(() => {
    if (network != null && network.provisionStatus === "NO") {
      dispatch(updateSetupStep(SETUP_STEP.DETECTED));
      history.push('/setup');
    }
  }, [dispatch, history, network]);

  useEffect(() => {
    if ( vpnSelfOptionLoadingState === LoadingState.NONE) {
      dispatch(startFetchAccountVpnOption({orgId: customerId, accountId: 'self'}));
    }
  }, [customerId, dispatch, vpnSelfOptionLoadingState]);

  useEffect(() => {
    if (vpnSelfOptionLoadingState === LoadingState.LOADED &&
      ssidsLoadingState === LoadingState.LOADED) {
        setInitialized(true);
    }
  }, [ssidsLoadingState, vpnSelfOptionLoadingState]);

  if (initialized && isEmpty(vpnSelfOption)) {
    return (
      <PromptVpnSetting/>
    );
  } else if (initialized && unProvisionedCounts > 0){
    return (
      <PromptAddNode />
    );
  } else {
    return <></>;
  }
}
