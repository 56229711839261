import React, { useEffect, useState } from "react";
import { superstructResolver } from "@hookform/resolvers/superstruct";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectNetworkId, selectCustomerId, hasError, addToast, SfFormSelect } from "sf-ui-components";
import {
  selectCorpSsid,
  selectEmpSsid,
  selectSSidDetail,
  startGetEmpSSid,
  startGetSSidDetail,
  startUpdateSSID,
  startGetTemplateNetwork,
  selectDefaultNetworkId,
  startGetCorpSSid,
} from "./_wirelessSlice";
import { Button, Form, Modal } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import * as s from "superstruct";
import { unwrapResult } from "@reduxjs/toolkit";

const WirelessPage = () => {
  const wirelessSchema = s.object({
    name: s.optional(s.size(s.string(), 1, Infinity)),
    authMethod: s.optional(s.size(s.string(), 1, Infinity)),
    authPassword: s.optional(s.size(s.string(), 8, 64)),
  });

  const form = useForm({
    resolver: superstructResolver(wirelessSchema, {
      coerce: true,
    }),
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
    control,
  } = form;

  const dispatch = useDispatch();
  const customerId = useSelector(selectCustomerId);
  const networkId = useSelector(selectNetworkId);
  const corpWifi = useSelector(selectCorpSsid);
  const empSsid = useSelector(selectEmpSsid);
  const ssidDetail = useSelector(selectSSidDetail);
  const defaultNetwork = useSelector(selectDefaultNetworkId);
  const [onEdit, setOnEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [securityMode, setSecurityMode] = useState();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    dispatch(startGetEmpSSid({ networkId }));
    dispatch(startGetTemplateNetwork({ customerId }));
  }, [dispatch, networkId, customerId]);

  // get corp ssid
  useEffect(() => {
    if (defaultNetwork) {
      dispatch(startGetCorpSSid({ networkId: defaultNetwork }));
    }
  }, [dispatch, defaultNetwork]);

  // get emp ssid
  useEffect(() => {
    if (empSsid) {
      dispatch(startGetSSidDetail({ networkId, ssidId: empSsid?.id }));
    }
  }, [dispatch, empSsid, networkId]);

  //set state
  useEffect(() => {
    if (ssidDetail?.authMethod) {
      setSecurityMode(ssidDetail.authMethod);
    } else {
      setSecurityMode("Unsecured ssid");
    }
  }, [dispatch, ssidDetail]);

  //set state
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "authMethod") {
        setSecurityMode(value.authMethod);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onClickEdit = () => {
    setValue("name", ssidDetail?.name);
    setValue("authMethod", ssidDetail?.authMethod);
    setValue("authPassword", ssidDetail?.authPassword);
    setOnEdit(true);
  };

  const onClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onClickCancelEdit = () => {
    setOnEdit(false);
  };

  const displayMode = () => {
    return (
      <div>
        <div className="row mb-3">
          <div className="col-1 sf-text-secondary sf-font-body1">WiFi Name:</div>
          <div className="col-2 sf-font-body1">{ssidDetail?.name}</div>
          <div className="col-1 sf-text-primary-color text-right">
            <Button variant="link" className="p-0 sf-font-h5" onClick={onClickEdit}>
              Edit
            </Button>
          </div>
        </div>

        {(securityMode === "WPA2" || securityMode === "WPA3_PERSONAL" || securityMode === "WPA3_MIXED") && (
          <div className="row mb-3">
            <div className="col-1 sf-text-secondary">WiFi Password:</div>
            <div className="col-2 sf-font-body1">{showPassword ? ssidDetail?.authPassword : "********"}</div>
            <div className="col-1 sf-text-primary-color text-right">
              <Button variant="link" className="p-0 sf-font-h5" onClick={onClickShowPassword}>
                {showPassword ? "Hide" : "Show"}
              </Button>
            </div>
          </div>
        )}

        <div className="row mb-3">
          <div className="col-1 sf-text-secondary">Security Mode:</div>
          <div className="col-2 sf-font-body1">{securityMode}</div>
        </div>
        <div className="row mb-6">
          <div className="col-1 sf-text-secondary">WiFi Mode:</div>
          <div className="col-2 sf-font-body1">Mixed</div>
        </div>
      </div>
    );
  };

  const onChangeSecurityMode = (e) => {
    setSecurityMode(e.target.value);
  };

  const editMode = () => {
    return (
      <div className="mb-8">
        <Form onSubmit={handleSubmit(doOnSubmit)}>
          <div className="row">
            <div className="col-4">
              <Form.Group>
                <Form.Label>WiFi Name</Form.Label>
                <Form.Control
                  name={`name`}
                  defaultValue={ssidDetail?.name}
                  type="text"
                  className={hasError(errors, `name`) ? "is-invalid" : ""}
                  {...register(`name`)}
                />
              </Form.Group>
            </div>
          </div>
          {(securityMode === "WPA2" || securityMode === "WPA3_PERSONAL" || securityMode === "WPA3_MIXED") && (
            <div className="row">
              <div className="col-4">
                <Form.Group>
                  <Form.Label>WiFi Password</Form.Label>
                  <Form.Control
                    name={`authPassword`}
                    defaultValue={ssidDetail?.authPassword}
                    type="password"
                    className={hasError(errors, `authPassword`) ? "is-invalid" : ""}
                    {...register(`authPassword`)}
                  />
                </Form.Group>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-4">
              <Form.Group>
                <Form.Label>Security Mode</Form.Label>
                <SfFormSelect
                  name={"authMethod"}
                  defaultValue={securityMode}
                  onChange={onChangeSecurityMode}
                  control={control}
                  className={hasError(errors, `authMethod`) ? "is-invalid" : ""}
                  {...register(`authMethod`)}
                >
                  <Dropdown.Item eventKey="Unsecured ssid">Unsecured ssid</Dropdown.Item>
                  <Dropdown.Item eventKey="WPA2">WPA2</Dropdown.Item>
                  <Dropdown.Item eventKey="WPA3_PERSONAL">WPA3_PERSONAL</Dropdown.Item>
                  <Dropdown.Item eventKey="WPA3_MIXED">WAP3_MIXED</Dropdown.Item>
                  <Dropdown.Item eventKey="WPA3_OPEN">WPA3_OPEN</Dropdown.Item>
                </SfFormSelect>
              </Form.Group>
            </div>
          </div>
          <div className="row mb-8">
            <div className="col-4">
              <Form.Group>
                <Form.Label>WiFi Mode</Form.Label>
                <SfFormSelect defaultValue="mixd" control={control}>
                  <Dropdown.Item eventKey="mixd">mixd</Dropdown.Item>
                </SfFormSelect>
              </Form.Group>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-4">We combined your 2.4 GHz and 5 GHz WiFi bands to provide seamless roaming among your nodes.</div>
          </div>
          <div className="row mb-8">
            <div className="col-4 sf-text-h5">
              <Button
                variant="link"
                className="p-0"
                onClick={() => {
                  addToast("Not yet implemented", "success");
                }}
              >
                Separate my WiFi bands
              </Button>
            </div>
          </div>

          <Button
            variant="primary"
            type="submit"
            className="sf-font-h4 sf-btn-w-default sf-btn-h-default mr-4"
            style={{ width: "232px", height: "48px" }}
          >
            Save
          </Button>
          <Button
            variant=""
            type="button"
            className="sf-font-h4 sf-text-secondary sf-bg-gray-2"
            onClick={onClickCancelEdit}
            style={{ width: "232px", height: "48px" }}
          >
            cancel
          </Button>
        </Form>
      </div>
    );
  };

  const doOnSubmit = async (data) => {
    if (!data.authMethod || data.authMethod === "Unsecured ssid") {
      delete data.authMethod;
      data.hasAuth = false;
    } else {
      data.hasAuth = true;
    }
    if (!data.authMethod || data.authMethod === "ssid" || data.authMethod === "WPA3_OPEN") delete data.authPassword;

    dispatch(startUpdateSSID(Object.assign(data, { networkId, ssidId: empSsid?.id })))
      .then(unwrapResult)
      .then(() => {
        addToast("Updated  wifi successfully", "success");
        //reload page
        dispatch(startGetSSidDetail({ networkId, ssidId: empSsid?.id })).then(() => {
          setOnEdit(false);
        });
      })
      .catch((error) => {
        addToast(error.message, "error");
      });
  };

  const ShareWifi = (props) => {
    return (
      <Modal {...props} size="md" backdropClassName="sf-modal-backdrop-light" centered>
        <Modal.Header closeButton style={{ backgroundColor: "white" }}>
          <Modal.Title id="contained-modal-title-vcenter" className="sf-font-h3 ml-4">
            Share WiFi
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "white" }}>
          <div className="row mb-4">
            <div className="col-4 sf-font-body1 sf-text-secondary">WiFi name:</div>
            <div className="col sf-text-body1">{ssidDetail?.name}</div>
          </div>
          <div className="row">
            <div className="col-4 sf-font-body1 sf-text-secondary">WiFi Password:</div>
            <div className="col sf-text-body1">{ssidDetail?.authPassword}</div>
          </div>
        </Modal.Body>
        <div style={{ backgroundColor: "white" }}>
          <hr style={{ width: "80%" }} />
          <div className="mb-4 ml-6">
            <Button
              variant="link"
              className="sf-font-h5 sf-text-primary-text "
              type="button"
              style={{ width: "150px", backgroundColor: "white" }}
              onClick={() => {
                addToast("Not yet implemented", "success");
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-files"
                  viewBox="0 0 16 16"
                >
                  <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
                </svg>
              </span>
              <span className="ml-4">Copy</span>
            </Button>

            <Button
              variant="link"
              className="sf-font-h5 sf-text-primary-text ml-1"
              type="button"
              style={{ width: "150px", backgroundColor: "white" }}
              onClick={() => {
                addToast("Not yet implemented", "success");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-mailbox"
                viewBox="0 0 16 16"
              >
                <path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z" />
                <path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z" />
              </svg>
              <span className="ml-4">Email</span>
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      <div className="app-header overflow-hidden">
        <div className="sf-font-h1">Wireless</div>
        <div className="sf-text-secondary sf-text-f-body1 mt-5">View and change the WiFi settings for your personal home network</div>
      </div>
      <div className="app-body" style={{height:'700px'}}>
        <div className="row">
          <span className="col-3 sf-font-h3 mb-8">Personal Network</span>
          <span className="col-1 custom-control custom-switch text-right">
            <input
              disabled={true}
              type="checkbox"
              className="custom-control-input"
              id="mfa"
              checked={ssidDetail ? ssidDetail.status : false}
            />
            <label className="custom-control-label" htmlFor="mfa"></label>
          </span>
        </div>
        {empSsid && corpWifi && (
          <div>
            {onEdit ? editMode() : displayMode()}
            <Button
              variant=""
              className="sf-font-h5 sf-text-secondary sf-bg-gray-2 mb-10"
              type="button"
              style={{ width: "232px", height: "48px" }}
              onClick={() => {
                setModalShow(true);
              }}
            >
              <span className="mr-2">Share WiFi</span>
              <span>
                <svg width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                </svg>
              </span>
            </Button>
            <div className="row mb-2">
              <div className="col-4 sf-font-body1">
                We've combined Linksys HomeWRK's 3 WiFi bands with one name and password. This provides seamless roaming on your network.
                Your devices will find the band they need.
              </div>
            </div>
            <div className="row mb-6">
              <div className="sf-font-h5 sf-text-primary-color">
                <Button
                  variant="link"
                  className="sf-font-h5"
                  type="button"
                  onClick={() => {
                    addToast("Not yet implemented", "success");
                  }}
                >
                  Learn More
                </Button>
              </div>
            </div>
            <div className="row mb-8">
              <div className="col-4">
                <Button
                  variant=""
                  className="sf-font-h5 sf-bg-gray-1 sf-text-secondary mb-4"
                  type="button"
                  style={{ width: "480px", height: "64px" }}
                  onClick={() => {
                    addToast("Not yet implemented", "success");
                  }}
                >
                  <span>WiFi Protected Setup (WPS)</span>
                  <span className="float-right ">
                    <svg width="16" height="16" fill="currentColor" className="bi bi-chevron-compact-right mr-6" viewBox="0 0 16 16">
                      <path
                        fillRule="evenodd"
                        d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
                      />
                    </svg>
                  </span>
                </Button>
              </div>
            </div>
            <div className="row">
              <div className="col sf-font-h3 mb-6">Corporate Network</div>
            </div>
            <div className="row mb-2">
              <div className="col-2 sf-text-secondary">Corporate WiFi:</div>
              <div className="col-2 sf-font-body1">{corpWifi.name}</div>
            </div>
            <div className="row mb-2">
              <div className="col-2 sf-text-secondary">WiFi Password:</div>
              <div className="col-2 sf-font-body1">********</div>
            </div>
          </div>
        )}
      </div>
      <ShareWifi show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};
export default WirelessPage;
