import React, {useEffect, useMemo} from "react";
import {useDispatch} from "react-redux";
import {IOT_STATUS_EVENTS} from "sf-ui-components";
import _ from "lodash";
import { addToast, createLogger, LoadingOverlay, updateVpnSetupStep, useIotMessage, VPN_SETUP_STEP } from "sf-ui-components";
import { useTopic } from "../../common/hooks";

const logger = createLogger('sfe:vpn:connecting');
export const VpnConnecting = () => {
  const dispatch = useDispatch();
  const {topic} = useTopic();
  logger(`useIotMessage, topic: ${topic}`);
  const iotParam = useMemo(() => {
    return {
      topic: topic,
      filter: (message) => {
        const event = _.get(message, 'payload.event');
        return event === IOT_STATUS_EVENTS.VPN_UP;
      }
    };
  }, [topic]);
  const {waitForPromise, removeMessagePromise} = useIotMessage(iotParam);

  useEffect(() => {
    waitForPromise(600000)
      .then(async messages => {
        logger('IoT message array: ', messages);
        if (messages.length > 0) {
          await removeMessagePromise(messages);
          dispatch(updateVpnSetupStep(VPN_SETUP_STEP.CONNECTED));
        }
      })
      .catch(it => {
        logger('waitForPromise Timeout', it);
        addToast('VPN was not connected within 60 seconds', 'info', true);
        dispatch(updateVpnSetupStep(VPN_SETUP_STEP.CONFIGURE));
      }).finally(() => {
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <section>
        <h2>
          Connecting your VPN...
        </h2>
      </section>
      <LoadingOverlay
        initialized={false}
        className='d-flex align-items-center justify-content-center display-1 h-75'>
      </LoadingOverlay>
    </>
  );
};
