import {createSlice} from '@reduxjs/toolkit';
// import {createLogger} from "sf-ui-components";

// const logger = createLogger("sfe:wiring:slice");

export const WIRING_STEP = Object.freeze({
  DISCONNECT_AND_REMOVE: 0,
  PLUG_IN_NODE: 1,
  CONNECT_NODE_TO_INTERNET: 2,
  GETTING_THINGS_UP: 3,
  DONE: 4,
});

export const slice = createSlice({
  name: 'wiring',
  initialState: {
    step: WIRING_STEP.DISCONNECT_AND_REMOVE
  },
  reducers: {
    updateWiringStep: (state, action) => {
      state.step = action.payload;
      return state;
    },
  },
  extraReducers: {}
})

export const selectWiringStep = state => state?.wiring?.step;

export const {updateWiringStep} = slice.actions;

export default slice.reducer;
