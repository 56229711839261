import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import IspRestart from "../../_images/ISP_restart.png";
import { ISP_STEP, updateIspStep } from "./_ispSlice";

export const QuickStart = () => {

  const dispatch = useDispatch();

  const goToIdentityingModem = () => {
    dispatch(updateIspStep(ISP_STEP.IDENTITYING_MODEM));
  }

  return (
    <>
      <section>
        <h3>
          Let's give your modem a quick restart
        </h3>
      </section>
      <section className='mt-4'>
        <p className='sf-text-f-body2 sf-text-c-gray'>
          This is routine when setting up a new router.
        </p>
        <img alt='' width='100%' src={IspRestart} />
      </section>
      <section className='mt-6'>
        <div className='mb-6'>
          <div className='sf-text-f-body2 mb-4'>
            Step 1:
          </div>
          <p className='sf-text-f-body2 sf-text-c-gray'>
            Unplug your modem
          </p>
        </div>
        <div className='mb-6'>
          <div className='sf-text-f-body2 mb-4'>
            Step 2:
          </div>
          <p className='sf-text-f-body2 sf-text-c-gray'>
            Look at the modem to make sure all lights are off
          </p>
          <p>
            <a href='#/' onClick={() => dispatch(updateIspStep(ISP_STEP.LIGHT_STILL_ON))}>
              Are the lights still on after unplugging the modem?
            </a>
          </p>
        </div>
        <div className='mb-6'>
          <div className='sf-text-f-body2 mb-4'>
            Step 3:
          </div>
          <p className='sf-text-f-body2 sf-text-c-gray'>
            Are all of your wires snapped into place?
          </p>
          <p>
            <a href='#/' onClick={goToIdentityingModem}>
              Not sure which device is the modem?
            </a>
          </p>
        </div>
      </section>
      <section>
        <div>
          <Button variant='primary' className='sf-btn-w-xlg' onClick={() => dispatch(updateIspStep(ISP_STEP.CHECKING_INTERNET))}>
            I've Done This
          </Button>
        </div>
      </section>
    </>
  );
};
