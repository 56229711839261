import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import React from "react";
import { SystemSettingsPage } from "./SystemSettingsPage";

export const SystemSettingsContainer = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <SystemSettingsPage/>
      </Route>
      <Route path={`${match.path}`}>
        <Redirect to={`${match.url}/`}/>
      </Route>
    </Switch>
  );
}
