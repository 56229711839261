import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { composeUrl, createLogger } from "sf-ui-components";
import { envBaseApiUrl } from "../../app/env";
import * as _ from "lodash";
import axios from "axios";

const logger = createLogger("configuration:empWifi:slice");

export const startGetTemplateNetwork = createAsyncThunk(
  "corpWifi/GetTemplateNetwork",
  async ({ customerId }, { rejectWithValue, getState, dispatch }) => {
    logger("[GetTemplateNetwork] begin", customerId);
    const url = composeUrl(`${envBaseApiUrl}/organization-service/rest/organizations/${customerId}/networks/list?defaultNetwork=true`);

    return await axios
      .post(url)
      .then((response) => {
        logger("get template network response: %o", response.data);
        return response.data;
      })
      .catch((error) => {
        logger("get template network failed: %o", error);
        throw error;
      });
  }
);

export const startGetCorpSSid = createAsyncThunk(
  "empWifi/GetCorpSSid",
  async ({ networkId }, { rejectWithValue, getState, dispatch }) => {
    logger("[GetCorpSSid] begin", networkId);
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/ssids`);

    return await axios
      .get(url)
      .then((response) => {
        logger("get corp ssid response: %o", response.data);
        return response.data;
      })
      .catch((error) => {
        logger("get corp ssid failed: %o", error);
        throw error;
      });
  }
);

export const startGetEmpSSid = createAsyncThunk(
  "empWifi/GetEmpSSid",
  async ({ networkId }, { rejectWithValue, getState, dispatch }) => {
    logger("[GetEmpSSid] begin", networkId);
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/ssids`);

    return await axios
      .get(url)
      .then((response) => {
        logger("get emp ssid response: %o", response.data);
        return response.data;
      })
      .catch((error) => {
        logger("get emp ssid failed: %o", error);
        throw error;
      });
  }
);

export const startGetSSidDetail = createAsyncThunk(
  "empWifi/GetSSidDetail",
  async ({ networkId, ssidId }, { rejectWithValue, getState, dispatch }) => {
    logger("[GetSSidDetail] begin", networkId, ssidId);
    const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/ssids/${ssidId}`);

    return await axios
      .get(url)
      .then((response) => {
        logger("get emp ssid detail response: %o", response.data);
        return response.data;
      })
      .catch((error) => {
        logger("get emp ssid detail failed: %o", error);
        throw error;
      });
  }
);

export const startUpdateSSID = createAsyncThunk("empWifi/UpdateSSID", async (input, { rejectWithValue, getState, dispatch }) => {
  const { networkId, ssidId, ...rest } = input;
  logger("[UpdateSSID] begin", input);
  const url = composeUrl(`${envBaseApiUrl}/network-service/rest/networks/${networkId}/ssids/${ssidId}`);

  return await axios
    .put(url, rest)
    .then((response) => {
      logger("update ssid response: %o", response.data);
      return response.data;
    })
    .catch((error) => {
      logger("update ssid failed: %o", error);
      throw error;
    });
});

export const slice = createSlice({
  name: "empWifi",
  initialState: {
    defaultNetwork: undefined,
    corpSsid: undefined,
    empSsid: undefined,
    ssidDetails: undefined,
  },
  reducers: {
    doSomething: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
  },
  extraReducers: {
    [startGetTemplateNetwork.pending]: (state, action) => {
      logger("startGetTemplateNetwork.pending: %o, %o", state, action);
    },
    [startGetTemplateNetwork.fulfilled]: (state, action) => {
      logger("startGetTemplateNetwork.fulfilled: %o, %o", state, action);
      state.defaultNetwork = _.get(action, "payload.data[0]");
    },
    [startGetTemplateNetwork.rejected]: (state, action) => {
      logger("startGetTemplateNetwork.rejected: %o, %o", state, action);
    },

    [startGetCorpSSid.pending]: (state, action) => {
      logger("startGetCorpSSid.pending: %o, %o", state, action);
    },
    [startGetCorpSSid.fulfilled]: (state, action) => {
      logger("startGetCorpSSid.fulfilled: %o, %o", state, action);
      state.corpSsid = _.get(action, "payload.data[0]");
    },
    [startGetCorpSSid.rejected]: (state, action) => {
      logger("startGetCorpSSid.rejected: %o, %o", state, action);
    },

    [startGetEmpSSid.pending]: (state, action) => {
      logger("startGetEmpSSid.pending: %o, %o", state, action);
    },
    [startGetEmpSSid.fulfilled]: (state, action) => {
      logger("startGetEmpSSid.fulfilled: %o, %o", state, action);
      state.empSsid = _.get(action, "payload.data[0]");
    },
    [startGetEmpSSid.rejected]: (state, action) => {
      logger("startGetEmpSSid.rejected: %o, %o", state, action);
    },
    [startGetSSidDetail.pending]: (state, action) => {
      logger("startGetSSidDetail.pending: %o, %o", state, action);
    },
    [startGetSSidDetail.fulfilled]: (state, action) => {
      logger("startGetSSidDetail.fulfilled: %o, %o", state, action);
      state.ssidDetails = _.get(action, "payload");
    },
    [startGetSSidDetail.rejected]: (state, action) => {
      logger("startGetSSidDetail.rejected: %o, %o", state, action);
    },
    [startUpdateSSID.pending]: (state, action) => {
      logger("startUpdateSSID.pending: %o, %o", state, action);
    },
    [startUpdateSSID.fulfilled]: (state, action) => {
      logger("startUpdateSSID.fulfilled: %o, %o", state, action);
    },
    [startUpdateSSID.rejected]: (state, action) => {
      logger("startUpdateSSID.rejected: %o, %o", state, action);
    },
  },
});

export const { doSomething } = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
export const startSomethingAsync = (amount) => (dispatch) => {
  setTimeout(() => {
    dispatch(doSomething());
  }, 1000);
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectSomething = (state) => state.empWifi.value;
export const selectDefaultNetworkId = (state) => state?.empWifi?.defaultNetwork?.id;
export const selectCorpSsid = (state) => state?.empWifi?.corpSsid;
export const selectEmpSsid = (state) => state?.empWifi?.empSsid;
export const selectSSidDetail = (state) => state?.empWifi?.ssidDetails;

export default slice.reducer;
