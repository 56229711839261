import React from "react";
import { useDispatch, useSelector } from "react-redux";
import parentAndChildNode from "../../_images/parent_and_child_node.png";
import { selectPreviousMoreNodeSetupStep, updateMoreNodeSetupStep } from "./_nodeSlice";

export const ParentAndChildNodes = () => {

  const dispatch = useDispatch();
  const previousStep = useSelector(selectPreviousMoreNodeSetupStep);

  return (
    <div className="app-container">
      <div className="app-container-top"/>
      <div className="app-container-trunk px-2">
        <div className="d-flex align-items-center mb-5 pt-5">
          <i className='sf-icon-chevron-left cursor-pointer' onClick={() => dispatch(updateMoreNodeSetupStep(previousStep))}/>
        </div>
        <section className="mb-6">
          <h2 className="mb-4">Parent and child nodes</h2>
          <div className="mb-4">
            <h4 className="mb-2">Parent node </h4>
            <p className="sf-text-f-body2 sf-text-c-gray">The node connected to your modem with an ethernet cable.</p>
          </div>
          <div>
            <h4 className="mb-2">Child nodes</h4>
            <p className="sf-text-f-body2 sf-text-c-gray">Nodes you place throughout your home to expand your WiFi coverage area. Child nodes may be wired or wireless.</p>
          </div>
        </section>
        <section>
          <img alt='' width='100%' src={parentAndChildNode}/>
        </section>
      </div>
      <div className="app-container-bottom"/>
    </div>
  );
}
