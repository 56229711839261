import React from 'react';
import { Sidebar, UserMenu } from 'sf-ui-components';
import { useHistory, useLocation } from 'react-router-dom';

import { useWindowSize } from '../hooks';
import MobileMenu from './MobileMenu';
import menuItems from './MenuItems';
import { MobileUserMenu } from './MobileUserMenu';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowMobileMenu, updateShowMobileMenu } from './_layoutSlice';
import XIcon from '../../_images/XIcon.svg';

export const OneColumnLayoutHeader = ({displayBack = true, displayMenu = true, logo}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const {pathname} = location;
  const windowSize = useWindowSize();
  const showMobileMenu = useSelector(selectShowMobileMenu);
  const MOBILE_BREAK_POINT = 768;

  const toggleMobileMenu = () => {
    dispatch(updateShowMobileMenu(!showMobileMenu));
  }

  return (
    <>
      <div className='content-header'>
        {logo && 
          <div className='content-header-brand'>
            <div className='content-header-brand-image'>
              <img src={logo} alt='brand logo' />
            </div>
          </div>
        }
        <div className='d-flex justify-content-between profile-menu'>
          {displayBack
          && '/auth/login' !== pathname
          && <i className='sf-icon-chevron-left cursor-pointer' onClick={() => history.goBack()}/>}
          {displayMenu && windowSize.width >= MOBILE_BREAK_POINT && <UserMenu menuConfig={menuConfig}/>}
          {displayMenu && windowSize.width < MOBILE_BREAK_POINT && <div onClick={toggleMobileMenu}><MobileMenu showMobileMenu={showMobileMenu}/></div>}
        </div>
      </div>
      {windowSize.width < MOBILE_BREAK_POINT &&
        <div className={`mobile-sidebar-container${showMobileMenu ? '' : ' hidden'}`}>
          <div className='close-menu-container' onClick={toggleMobileMenu}><img src={XIcon} alt='close menu'/></div>
          <Sidebar menuItems={menuItems} />
          <MobileUserMenu />
        </div>
      }
    </>
  );
};

const menuConfig = {
  dropdownList: [
    {
      title: 'Profile',
      handleClick: ({history}) => {
        history.push('/profile');
      },
      className: 'sf-text-f-body2'
    },
    {
      title: 'Settings',
      handleClick: ({history}) => {
        history.push('/profile/setting');
      },
      className: 'sf-text-f-body2'
    },
    {
      title: 'Logout',
      handleClick: ({history}) => {
        // This function will be executed before sf-ui-components does logout.
      },
      className: 'sf-text-f-body2 sf-text-secondary'
    }
  ]
};
