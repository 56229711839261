import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addToast,
  createLogger,
  isEmpty,
  LoadingState,
  selectCustomerId,
  selectVpnSelfOptionLoadingState,
  startFetchAccountVpnOption,
  useProfile
} from "sf-ui-components";
import { AppContainer } from "../../common/AppContainer";
import { selectNetworkSsidsLoadingState, startGetNetworkSsids, updateWifiSetupStep, WIFI_SETUP_STEP } from "../wifi/_wifiSlice";
import { LoadingNetworkPage } from "./LoadingNetworkPage";
import {
  selectNetwork,
  selectNetworkDevicesLoadingState,
  selectNetworkLoadingState,
  startFetchFirstAccountNetwork,
  startFetchNetworkDevices
} from "sf-ui-components";

const logger = createLogger('sfe:network:page');

export const NetworkPage = () => {
  const customerId = useSelector(selectCustomerId);
  const profile = useProfile();
  const accountId = _.get(profile, 'accountId', '');
  const dispatch = useDispatch();
  const networkLoadingState = useSelector(selectNetworkLoadingState);
  useEffect(() => {
    if (!isEmpty(accountId) && !isEmpty(customerId) && networkLoadingState === LoadingState.NONE) {
      dispatch(startFetchFirstAccountNetwork({
        customerId: customerId,
        accountId: accountId
      }))
        .then(unwrapResult)
        .then((resp) => {
          logger('Fetch account network successfully', resp);
        })
        .catch((error) => {
          logger('Fetch network failed:', error);
          addToast(`Fetch network failed: ${error.message}`, 'error');
        });
    }
  }, [dispatch, accountId, customerId, networkLoadingState]);

  const devicesLoadingState = useSelector(selectNetworkDevicesLoadingState);
  const network = useSelector(selectNetwork);

  useEffect(() => {
    if (network != null && devicesLoadingState === LoadingState.NONE) {
      dispatch(startFetchNetworkDevices({
        networkId: network.id
      }))
        .then(unwrapResult)
        .then(resp => {
          logger('startFetchNetworkDevices resp=%o', resp);
        })
        .catch((error) => {
          addToast(`Fetch devices failed: ${error.message}`, 'error');
        });
    }
  }, [dispatch, network, devicesLoadingState]);

  const ssidsLoadingState = useSelector(selectNetworkSsidsLoadingState);

  useEffect(() => {
    if (network?.id && ssidsLoadingState === LoadingState.NONE) {
      dispatch(startGetNetworkSsids({
        networkId: network.id
      }));
    }
  }, [dispatch, network?.id, ssidsLoadingState]);

  const vpnSelfOptionLoadingState = useSelector(selectVpnSelfOptionLoadingState);

  useEffect(() => {
    if (vpnSelfOptionLoadingState === LoadingState.NONE) {
      dispatch(startFetchAccountVpnOption({orgId: customerId, accountId: 'self'}))
        .then(unwrapResult)
        .then(resp => {
          logger('startFetchAccountVpnOption resp=%o', resp);
        })
        .catch((error) => {
          addToast(`Fetch vpn self option failed: ${error.message}`, 'error');
        });
      ;
    }
  }, [dispatch, vpnSelfOptionLoadingState, customerId])

  return (
    <AppContainer>
      {networkLoadingState !== LoadingState.LOADED || devicesLoadingState !== LoadingState.LOADED
      || vpnSelfOptionLoadingState !== LoadingState.LOADED || ssidsLoadingState !== LoadingState.LOADED ?
        <LoadingNetworkPage/> : <RenderNetworkPage/>}
    </AppContainer>
  );
}

function RenderNetworkPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateWifiSetupStep(WIFI_SETUP_STEP.NAME_WIFI_INFO));
    history.push('/wifi');
  }, [dispatch, history])
  return <></>
}
