import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createLogger, isEmpty, LoadingOverlay, LoadingState, selectCustomerId, useProfile } from "sf-ui-components";
import { selectNetworkPreferences, selectPreferencesLoadingState, startFetchNetworkPreferences } from '../confirm/_confirmSlice';
import { selectNetwork, startFetchFirstAccountNetwork } from "sf-ui-components";
import { MORE_NODE_SETUP_STEP, updateMoreNodeSetupStep } from "../node/_nodeSlice";
import { selectNetworkSsidsLoadingState, selectSsids, startGetNetworkSsids, updateWifiSetupStep, WIFI_SETUP_STEP } from "../wifi/_wifiSlice";

const logger = createLogger('sfe:wiring:done');

export const WiringDone = () => {
  const dispatch = useDispatch();
  const customerId = useSelector(selectCustomerId);
  const profile = useProfile();
  const accountId = _.get(profile, 'accountId', '');
  const network = useSelector(selectNetwork);
  const history = useHistory();
  const ssids = useSelector(selectSsids);
  const ssidsLoadingState = useSelector(selectNetworkSsidsLoadingState);
  const [reloadedNetwork, setReloadedNetwork] = useState(false);
  const preferencesLoadingState = useSelector(selectPreferencesLoadingState);
  const networkPreferences = useSelector(selectNetworkPreferences);

  useEffect(() => {
    if (network && preferencesLoadingState === LoadingState.NONE) {
      dispatch(startFetchNetworkPreferences({networkId: network.id}))
      .then(unwrapResult)
      .then((resp) => {
        logger('startFetchNetworkPreferences resp: %o', resp);
      })
      .catch((error) => {
        logger('Fetch Network Preferences failed:', error?.message);
      });
    }
  }, [dispatch, network, preferencesLoadingState]);


  useEffect(() => {
    dispatch(startFetchFirstAccountNetwork({
      customerId: customerId,
      accountId: accountId
    }))
      .then(unwrapResult)
      .then((resp) => {
        setReloadedNetwork(true);
      })
      .catch((error) => {
        setReloadedNetwork(true);
      });
  }, [accountId, customerId, dispatch]);

  useEffect(() => {
    if (network?.id && ssidsLoadingState === LoadingState.NONE) {
      dispatch(startGetNetworkSsids({
        networkId: network.id
      }));
    }
  }, [dispatch, network.id, ssidsLoadingState]);

  useEffect(() => {

    if (reloadedNetwork && ssidsLoadingState === LoadingState.LOADED && preferencesLoadingState === LoadingState.LOADED ) {
      const vpnStatus = network?.vpn?.connectivity;
      const unProvisionedCounts = _.get(network, 'stat.unProvisionedCounts', 0);
      if (vpnStatus === true) {
        if ( ssids == null || ssids.length === 0) {
          dispatch(updateWifiSetupStep(WIFI_SETUP_STEP.NAME_WIFI_INFO));
          history.push('/wifi');
        } else if ( unProvisionedCounts > 0 ) {
          dispatch(updateMoreNodeSetupStep(MORE_NODE_SETUP_STEP.NODE_DETECTED));
          history.push('/node/setup/more');
        } else if ( isEmpty(networkPreferences)) {
          history.push('/confirm');
        } else {
          history.push('/network/ready');
        }
      } else {
        history.push('/vpn');
      }
    }
  }, [dispatch, history, network, networkPreferences, preferencesLoadingState, reloadedNetwork, ssids, ssidsLoadingState]);

  return (
    <>
      <div className="h-100">
        <LoadingOverlay
          className="d-flex align-items-center justify-content-center display-1 h-75"
          initialized={reloadedNetwork}>
          <div></div>
        </LoadingOverlay>
      </div>
    </>);

  // useEffect(() => {
  //   dispatch(startFetchAccountNetwork({
  //     customerId: customerId,
  //     accountId: accountId
  //   }))
  //     .then(() => {
  //       return dispatch(startFetchNetworkDevices({
  //         networkId: network.id
  //       }))
  //     })
  //     .then(unwrapResult)
  //     .then(() => {
  //       logger('Reload network and network devices successfully');
  //       if (offlineDevices > 0) {
  //         dispatch(updateMoreNodeSetupStep(MORE_NODE_SETUP_STEP.NODE_DETECTED));
  //         history.push('/node/setup/more');
  //       }
  //     })
  //     .catch((error) => {
  //       logger('Fetch network and devices failed:', error);
  //     });
  //   // eslint-disable-next-line
  // }, []);

  // return (
  //   <>
  //     <section>
  //       <h2 className='d-flex align-items-center justify-content-center'>
  //         Done!
  //       </h2>
  //     </section>
  //     <section className='mt-9'>
  //       <img alt='' width='100%' src='https://via.placeholder.com/400x256'/>
  //     </section>
  //   </>
  // );
}
