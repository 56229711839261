import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { EmpProgressBar } from "../../components/EmpProgressBar";
import { selectNetworkUnProvisionedCounts } from "sf-ui-components";

export function ParentNodeSetup() {
  const unProvisionedCounts = useSelector(selectNetworkUnProvisionedCounts);
  const history = useHistory();

  useEffect(
    () => {
      let timer = setTimeout(() => {
        if (unProvisionedCounts > 0) {
          history.push('/node/setup/more');
        } else {
          history.push('/confirm');
        }
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
      // eslint-disable-next-line
    }, []
  );

  return (
    <div className="h-100">
      <section>
        <h2>
          Your parent node is setup.
        </h2>
      </section>

      <section className="d-flex justify-content-center align-items-end h-50 mt-n7">
        <div className="text-primary">
          <FontAwesomeIcon icon={faCheckCircle} size="7x" />
        </div>
      </section>
      <section className='mt-8'>
        <EmpProgressBar start={0} step={25}/>
      </section>
    </div>
  );

}
