import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import wiringConnectNodes from "../../_images/wiring_connectNodes.png";
import { updateWiringStep, WIRING_STEP } from "./_wiringSlice";

export const ConnectNodeToInternet = () => {
  const dispatch = useDispatch();
  return (
    <>
      <section>
        <h3>
          Connect the node to your modem or source of internet.
        </h3>
      </section>
      <section className='mt-4'>
        <div className='sf-text-f-body1 sf-text-secondary'>
          Using the supplied ethernet cord, connect your modem or source of internet to the node. Insert the cord in the port labeled
          Internet.
        </div>
      </section>
      <section className='mt-10'>
        <img alt='' width='100%' src={wiringConnectNodes}/>
      </section>
      <section className='mt-10'>
        <div>
          <Button variant='primary' className='sf-btn-w-xlg' type='submit'
                  onClick={() => dispatch(updateWiringStep(WIRING_STEP.GETTING_THINGS_UP))}>
            I've Done This
          </Button>
        </div>
      </section>
    </>
  );
}
