import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store from './app/store';
import {Provider} from 'react-redux';
import './styles/common.scss';
import debug from 'debug';
import {BrowserRouter as Router} from 'react-router-dom'

const logger = debug('sfe:index');

store.subscribe(() => {
  let {auth} = store.getState();
  logger('Store updated', store.getState(), auth);
  if (auth == null) {
    auth = {};
  }
  localStorage.setItem('auth', JSON.stringify(auth));
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App/>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
