import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon className="toolIcon" icon={faEllipsisV} />
  </div>
));

const defaultStyles = {
  fontSize: '15px',
  fontWeight: 500,
  color: '#666666',
};

function KebabMenu(props) {
  return (
    <Dropdown className={props.className}>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu className="pl-2 pr-2" alignRight>
        {props.kebabMenuConfig.dropdownList.map((menuItem, index) => {
          return (
            <Dropdown.Item
              key={index}
              as={Button}
              onClick={menuItem.onClick}
              style={{ ...defaultStyles, ...menuItem.style }}
              className={`sf-text-f-body2 pl-4 pr-4 ${menuItem.className || ''}`}
            >
              {menuItem.title}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default KebabMenu;
