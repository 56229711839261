import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {NodeSetupPage} from "./NodeSetupPage";
import {MoreNodeSetupPage} from "./MoreNodeSetupPage";

export const NodeContainer = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/setup`}>
        <NodeSetupPage/>
      </Route>
      <Route exact path={`${match.path}/setup/more`}>
        <MoreNodeSetupPage/>
      </Route>
      <Route exact path={`${match.path}/`}>
        <Redirect to={`${match.url}/setup`}/>
      </Route>
      <Route path={`${match.path}`}>
        <Redirect to={`${match.url}/setup`}/>
      </Route>
    </Switch>
  );
}
