import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
  addToast,
  createLogger,
  doClearSystemSettingsData,
  selectCustomerId,
  selectNetworkId,
  selectTimezones,
  SfTimezone,
  startFetchAccountNetwork,
  useAccountVpn,
  useNetworkPreference,
  useProfile,
  UserVpnView
} from "sf-ui-components";
import {FirmwareUpdatePage} from "./firmware/FirmwareUpdatePage";

const logger = createLogger('systemSettings:SystemSettingsPage');

export const SystemSettingsPage = () => {
  const dispatch = useDispatch();
  const customerId = useSelector(selectCustomerId);
  const profile = useProfile();
  const accountId = profile?.accountId ?? '';
  const networkId = useSelector(selectNetworkId);
  const timezones = useSelector(selectTimezones);
  const [timezoneEditing, setTimezoneEditing] = useState(undefined);

  useEffect(() => {
    return () => {
      dispatch(doClearSystemSettingsData());
    }
  }, [dispatch]);

  useEffect(() => {
    if (networkId == null) {
      dispatch(startFetchAccountNetwork({ customerId, accountId }));
    }
  }, [dispatch, customerId, accountId, networkId]);

  const {
    reload, prepareTimezone, submitTimezone, preferences
  } = useNetworkPreference({ networkId });

  const submitTimezone2 = async (data) => {
    setTimezoneEditing(undefined);
    const resp = await submitTimezone(data);
    logger("Update response: %o", resp);
    if (resp?.error != null) {
      addToast("Error updating timezone: " + resp.error?.message, "error");
    } else {
      reload();
      addToast("Network timezone updated");
      setTimezoneEditing(false);
    }
  }

  useEffect(() => {
    prepareTimezone();
    reload();
  }, [networkId, reload, prepareTimezone]);

  const vpnData = useAccountVpn({ customerId, accountId: 'self' })

  return (
    <>
      <div className='app-header'>
        <h1>System Settings</h1>
        <div className='sf-text-c-secondary sf-text-f-body1 mt-5'>
          Keep your Linksys HomeWRK system up to date and running smoothly.
        </div>
      </div>
      <div className="app-body row">
        <div className="col-md-12 col-lg-8">
          <section className='mt-4'>
            <SfTimezone selected={preferences} data={timezones} editing={timezoneEditing}
                        submit={submitTimezone2}/>
          </section>
          <section className='mt-7'>
            <UserVpnView data={vpnData?.accountVpnOption} vpnServers={vpnData?.vpnServers}/>
          </section>
          <section className='mt-7'>
            <FirmwareUpdatePage/>
          </section>
          <section className='mt-7'>
            <h3>Router Diagnostic Information</h3>
            <div className='sf-text-c-secondary sf-text-f-body1 mt-2'>
              Sharing router diagnostic information with Linksys and your company’s IT team can help if you have any issues with your
              network.
            </div>
            <div className='mt-4'>
              <Button variant='primary' className='' type='button'>
                Share Router Information
              </Button>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
