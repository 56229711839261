import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppContainer } from "../../common/AppContainer";
import { ConnectNodeToInternet } from "./ConnectNodeToInternet";
import { DisconnectAndRemove } from "./DisconnectAndRemove";
import { GettingThingsUp } from "./GettingThingsUp";
import { PlugInNode } from "./PlugInNode";
import { WiringDone } from "./WiringDone";
import { selectWiringStep, updateWiringStep, WIRING_STEP } from "./_wiringSlice";

export const WiringPage = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(updateWiringStep(WIRING_STEP.DISCONNECT_AND_REMOVE));
    }
  }, [dispatch]);

  return (
    <AppContainer>
      <RenderWiringFlow/>
    </AppContainer>
  );
};

const RenderWiringFlow = () => {
  const step = useSelector(selectWiringStep);
  switch (step) {
    case WIRING_STEP.DISCONNECT_AND_REMOVE:
      return (
        <>
          <DisconnectAndRemove/>
        </>
      );
    case WIRING_STEP.PLUG_IN_NODE:
      return (
        <>
          <PlugInNode/>
        </>
      );
    case WIRING_STEP.CONNECT_NODE_TO_INTERNET:
      return (
        <>
          <ConnectNodeToInternet/>
        </>
      );
    case WIRING_STEP.GETTING_THINGS_UP:
      return (
        <>
          <GettingThingsUp/>
        </>
      );
    case WIRING_STEP.DONE:
      return (
        <>
          <WiringDone/>
        </>
      );
    default:
      return (
        <>
        </>
      );
  }
}
