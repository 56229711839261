import React from "react";
import moment from 'moment';
import _ from 'lodash';

import {OneColumnLayoutHeader} from "./OneColumnLayoutHeader";
import { VERSION } from '../../version';
const showVersion = true;

export function OneColumnLayout({children, logo}) {
  return (
    <div className="application-container">
      <OneColumnLayoutHeader displayBack={false} displayMenu={true} logo={logo}/>
      <div className="content-body-container">
        {children}
      </div>
      {showVersion && <Version/>}
    </div>
  )
}

const Version = () => {
  const v = _.get(VERSION, 'version', '');
  const hash = _.get(VERSION, 'hash', '');
  let createAtLocalTime;
  try {
    const createdAt = _.get(VERSION, 'createdAt', '');
    const utc = new Date(createdAt);
    createAtLocalTime = moment(utc).local().format('MMM D, YYYY, HH:mm:ss z');
  } catch (e) {
  }
  return (
    <div className='position-absolute' style={{color: '#F0F0F0', right: 0, bottom: 0}}>
        {v} ({hash}) {createAtLocalTime}
    </div>
  );
}
