import React from "react";
import { useHistory } from "react-router-dom";
import {SETUP_STEP, updateSetupStep} from "../features/setup/_setupSlice"
import { useDispatch } from 'react-redux';

export function AppContainer({className, children}) {

  const history = useHistory();
  const dispatch = useDispatch();

  const onClickClose = () => {
    dispatch(updateSetupStep(SETUP_STEP.DETECTED));
    history.push('/setup');
  }

  return (
    <div className={`app-container ${className || ''}`}>
      <div className="app-container-top"/>
      <div className="app-container-trunk px-2">
        <div className="d-flex align-items-center justify-content-end mb-5 pt-5">
          <i className='sf-icon-close ml-3 cursor-pointer' onClick={onClickClose}/>
        </div>
        {children}
      </div>
      <div className="app-container-bottom"/>
    </div>
  );
}
