import React, { useEffect } from "react";
import { AppContainer } from "../../common/AppContainer";
import { useDispatch, useSelector } from "react-redux";
import { selectVpnSetupStep, updateVpnSetupStep, VPN_SETUP_STEP } from "sf-ui-components";
import { VpnIntroduction } from "./VpnIntroduction";
import { VpnConfiguration } from "./VpnConfiguration";
import { VpnConnecting } from "./VpnConnecting";
import { VpnConnected } from "./VpnConnected";

export const VpnSetupPage = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(updateVpnSetupStep(VPN_SETUP_STEP.INTRODUCTION));
    }
  }, [dispatch]);

  return (
    <AppContainer>
      <RenderVpnSetupFlow/>
    </AppContainer>
  );
};

function RenderVpnSetupFlow() {
  const step = useSelector(selectVpnSetupStep);
  switch (step) {
    case VPN_SETUP_STEP.CONFIGURE:
      return <VpnConfiguration/>;
    case VPN_SETUP_STEP.CONNECTING:
      return <VpnConnecting/>
    case VPN_SETUP_STEP.CONNECTED:
      return <VpnConnected/>
    case VPN_SETUP_STEP.INTRODUCTION:
    default:
      return <VpnIntroduction/>;
  }
}
