import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {MORE_NODE_SETUP_STEP, updateMoreNodeSetupStep} from "./_nodeSlice";
import parentNode from "../../_images/node-parent.svg";
import childNode from "../../_images/node-child.svg";
import {selectNetworkDeviceCounts, selectNetworkUnProvisionedCounts} from "sf-ui-components";
import {useHistory} from "react-router-dom";

export const MoreNodeDetected = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const unProvisionedCounts = useSelector(selectNetworkUnProvisionedCounts);
  const deviceCounts = useSelector(selectNetworkDeviceCounts);
  const [unProvisionedChildNodes, setUnProvisionedChildNodes] = useState([]);

  useEffect(() => {
    setUnProvisionedChildNodes((arr) => {
      arr = [];
      for (let i = 0; i < unProvisionedCounts; i++) {
        arr.push(
          <section key={i} className='mt-6'>
            <img alt='' width='100%' src={childNode}/>
          </section>
        )
      }
      return arr;
    });
  }, [unProvisionedCounts]);

  return (
    <>
      <section>
        <h2 className="mb-4">
          It looks like you have more nodes to set up. Do you want to add them now?
        </h2>
        <div>
          Adding nodes to your network will expand the WiFi coverage in your home.
        </div>
      </section>
      {
        deviceCounts > 0 &&
        <section className='mt-6'>
          <img alt='' width='100%' src={parentNode}/>
        </section>
      }
      {unProvisionedChildNodes}
      <section className='mt-6'>
        <div className="mb-6">
          <a href='#/' className="" onClick={() => dispatch(updateMoreNodeSetupStep(MORE_NODE_SETUP_STEP.PARENT_AND_CHILD_NODES))}>
            What are parent and child nodes?
          </a>
        </div>
        <div>
          <Button variant='primary' className='sf-btn-w-xlg' type='submit'
                  onClick={() => dispatch(updateMoreNodeSetupStep(MORE_NODE_SETUP_STEP.PLACE_CHILD_NODES))}>
            Yes, Show Me How
          </Button>
        </div>
        <div className='d-flex align-items-center justify-content-center mt-6'>
          <a href='#/' onClick={(e) => {
            e.preventDefault()
            history.push('/confirm');
          }}>
            Skip for now
          </a>
        </div>
      </section>
    </>
  );
}

