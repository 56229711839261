import React from "react";
import {useSelector} from "react-redux";
import {selectCustomerConfig} from "sf-ui-components";
import _ from "lodash";
import {VpnConfigurationForm} from "./VpnConfigurationForm";

export const VpnConfiguration = () => {
  const customerConfig = useSelector(selectCustomerConfig);
  const customerName = _.get(customerConfig, 'customerName');
  const vpnName = 'VPN';
  return (
    <>
      <section>
        <h2>
          Enter {customerName} VPN settings
        </h2>
      </section>
      <section className='mt-5 sf-text-f-body1 sf-text-c-secondary'>
        <div>
          Choose a server and enter the username and password that you use to connect to {vpnName}. This will create your corporate network
          for work-related devices.
        </div>
      </section>
      <section className='mt-5'>
        <VpnConfigurationForm/>
      </section>
      <section className='mt-5'>
        <Notice/>
      </section>
    </>
  );
};

const Notice = () => {
  return (
    <div className='bg-secondary px-5 py-5 rounded'>
      <h3>Have questions or don't know how to log in to VPN?</h3>
      <div className='mt-4 sf-text-c-secondary'>
        Contact your IT or network administrator for help.
      </div>
    </div>
  );
};
