import React, { useState, useEffect } from "react";
import { PeopleAvatar } from "lsui-components";

const UserIcon = ({ profile }) => {
    const avatarUrl = profile?.preferences?.avatarUrl;
    const [imgSrc, setImgSrc] = useState(avatarUrl);
    const { firstname = '', lastname = '' } = profile || {};
    
    useEffect(() => {
        let url = profile?.avatarUrl;
        if (url) {
            setImgSrc(url);
        } else {
        // Will continue in Corporate portal.
        // dispatch(startFetchUserIcon());
        }
    }, [profile]);

    return <PeopleAvatar imageUrl={imgSrc} text={firstname + ' ' + lastname} letters={2} />;
};

export default UserIcon;