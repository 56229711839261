import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import wiringPlugin from "../../_images/wiring_plugin.png";
import { updateWiringStep, WIRING_STEP } from "./_wiringSlice";

export const PlugInNode = () => {
  const dispatch = useDispatch();
  return (
    <>
      <section>
        <h3>
          Plug in a node
        </h3>
      </section>
      <section className='mt-4'>
        <div className='sf-text-f-body1 sf-text-secondary'>
          If you have multiple nodes, choose any one of them to plug in near your modem or source of internet.
        </div>
      </section>
      <section className='mt-11 pt-2'>
        <img alt='' width='100%' src={wiringPlugin}/>
      </section>
      <section className='mt-6'>
        <div>
          <Button variant='primary' className='sf-btn-w-xlg' type='submit'
                  onClick={() => dispatch(updateWiringStep(WIRING_STEP.CONNECT_NODE_TO_INTERNET))}>
            I've Done This
          </Button>
        </div>
      </section>
    </>
  );
};
