import React from "react";
import img1 from "../../_images/system_settings.png";

export function NetworkAdministrationPage() {
  return (
    <>
      <img alt='Network Administration' src={img1} />
    </>
  );
}
