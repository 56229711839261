import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { SimpleCard } from "./SimpleCard";
import { ISP_STEP, updateIspStep } from "./_ispSlice";

export const UnableConnect = () => {

  const dispatch = useDispatch();

  return (
    <>
      <section className="mb-6">
        <div className="mb-4">
          <h3>We weren't able to connect to your modem</h3>
        </div>
        <div className="sf-text-f-body1 sf-text-c-gray">
          This are some common fixes:
        </div>
      </section>
      <section className="mb-7">
        <SimpleCard title="Check your wiring" className="mb-6"
          message="It might seem silly, but wiring issues can be hard to spot. These are some quick tips."
          onClick={() => dispatch(updateIspStep(ISP_STEP.CHECK_WIRING))}
        />

        <SimpleCard title="Restart your modem again" className="mb-6"
          message="Some modems can be stubborn starting a new connection."
          onClick={() => dispatch(updateIspStep(ISP_STEP.QUICK_START))}
        />
      </section>
      <section className="mb-7">
        <div>
          <Button variant='primary' className='sf-btn-w-xlg' onClick={() => dispatch(updateIspStep(ISP_STEP.CHECKING_INTERNET))}>
            Try Again
          </Button>
        </div>
      </section>
      <section>
        <div className="text-center mb-2">
          <div className="sf-text-f-body1 sf-text-c-gray">We're always happy to help!</div>
        </div>
        <div className="text-center">
          <a href='https://www.linksys.com/homewrk/help' target="_blank" rel="noreferrer noopener">
            Visit our support site
          </a>
        </div>
      </section>
    </>
  );

}
