import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";

export const SimpleCard = ({title, message, onClick, className}) => {
  return (
    <div className={`rounded p-4 row clickable sf-bg-gray-2 mx-0 ${className}`} onClick={onClick}>
      <div className='col-10 pr-0'>
        <div className='d-flex align-items-center'>
          <h4>{title}</h4>
        </div>
        <div className="sf-text-f-body2 sf-text-c-secondary mt-4">
          {message}
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-center col-2 pr-0'>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  )
};
