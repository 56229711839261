import { Button } from "react-bootstrap";
import React from "react";
import {useDispatch} from 'react-redux';
import {MORE_NODE_SETUP_STEP, updateMoreNodeSetupStep} from "./_nodeSlice";
import {useHistory} from "react-router-dom";
import nodeLight from "../../_images/node_light.png";
import nodePlacementRoom from "../../_images/node_placement_room.png";

export const StepNodeNotFound = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <>
      <section className="mb-6">
        <h2 className="mb-4">
          Hmm. We didn't find any child nodes
        </h2>
        <div className='sf-text-f-body2 sf-text-c-gray'>
          Follow the steps below to confirm that your child nodes are ready for setup.
        </div>
      </section>
      <section className="mb-6">
        <h3 className="mb-4">Step 1:</h3>
        <div>
          Is the light on your child node solid purple?
          If the light is blinking purple, the node needs a bit more time to finish starting up.
        </div>
      </section>
      <section className='mb-6 d-flex align-items-center justify-content-center'>
        <img alt='' src={nodeLight} style={{height: 132}}/>
      </section>
      <section className="mb-6">
        <h3 className="mb-4">Step 2:</h3>
        <div>
          Is the child node plugged in within 1-2 rooms of another node that has been set up and has a solid blue light?
          Try to limit the number of walls and obstacles between nodes.
        </div>
      </section>
      <section className='mt-6'>
        <img alt='' width='100%' src={nodePlacementRoom}/>
      </section>
      <section className='mt-6'>
        <Button variant='primary' className='sf-btn-w-xlg' type='submit'
          onClick={() => dispatch(updateMoreNodeSetupStep(MORE_NODE_SETUP_STEP.SEARCHING_FOR_CHILD_NODES))}>
          Check again
        </Button>
      </section>
      <section className='mb-6'>
        <div className='d-flex align-items-center justify-content-center mt-2'>
          <a href='#/' onClick={(e) => {
            e.preventDefault()
            history.push('/confirm');
          }}>
            Skip adding child nodes
          </a>
        </div>
      </section>
      <section>
        <div className='d-flex align-items-center justify-content-center'>
          <span className='sf-text-f-body2 sf-text-c-gray'>
            We're always happy to help!
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-center mt-2'>
          <a href='https://www.linksys.com/homewrk/help' target="_blank" rel="noreferrer noopener">
            Visit our support site
          </a>
        </div>
      </section>
    </>
  );
};
