import React, {useEffect} from "react";
import {
  COMMAND_STATUS,
  ISP_ACTIONS,
  RenderSpeedsPage,
  selectViewNetworkId,
  SpeedTestIoT,
  startFetchAllSpeedTestResults,
  startFetchIspInfo,
  startFetchIspList,
  startFetchLatestSpeedTest,
  startFetchSpeedTestStats,
  startFetchTopology,
  updateIspAction,
  updateSpeedTestCommandStatus
} from "sf-ui-components";
import {useDispatch, useSelector} from "react-redux";

export const SpeedsPage = () => {
  const networkId = useSelector(selectViewNetworkId);
  const dispatch = useDispatch();
  useEffect(() => {
    if (networkId != null) {
      dispatch(startFetchLatestSpeedTest({networkId}));
      dispatch(startFetchAllSpeedTestResults({networkId}));
      dispatch(startFetchSpeedTestStats({networkId}));
      dispatch(startFetchIspInfo({networkId}));
      dispatch(startFetchIspList());
      // fetch topology to check if node is online
      dispatch(startFetchTopology({networkId}));
    }
    return () => {
      dispatch(updateSpeedTestCommandStatus(COMMAND_STATUS.NONE));
      dispatch(updateIspAction(ISP_ACTIONS.VIEW));
    };
  }, [dispatch, networkId]);
  return (
    <div>
      <div className='app-header'>
        <h1>Speed Check</h1>
      </div>
      <div className="app-body">
        <div className='sf-text-secondary sf-font-body1'>
          Measures download and upload speeds between the internet and your parent node. It’s a good way
          to find out whether you are getting the level of service promised by your ISP.
        </div>
        <RenderSpeedsPage isIspEditable={true}/>
        <SpeedTestIoT/>
      </div>
    </div>
  );
}
