import React from "react";
import {Button} from "react-bootstrap";
import {useDispatch} from 'react-redux';
import {updateWifiSetupStep, WIFI_SETUP_STEP} from "./_wifiSlice";

export function NameWifiInfoPage() {

  const dispatch = useDispatch();

  return (
    <>
      <section className="mb-6">
        <h2 className="mb-4">
          Next, you'll create a name and password for your personal WiFi
        </h2>
        <div className="sf-text-f-body2 sf-text-c-secondary">
          We recommend using your existing home WiFi name and password,
          which will allow your devices to automatically connect to your new network.
        </div>
      </section>
      <section className='mb-6'>
        <Notice/>
      </section>
      <section>
        <div className="d-flex justify-content-center">
          <Button variant='primary' className='sf-btn-w-xlg'
            onClick={() => dispatch(updateWifiSetupStep(WIFI_SETUP_STEP.NAME_WIFI))}>
            Next
          </Button>
        </div>
      </section>
    </>
  );
}

const Notice = () => {
  return (
    <div className='bg-secondary px-5 py-5 rounded'>
      <h3 className="mb-4">You'll have two separate networks: personal and corporate</h3>
      <div className='mt-4 sf-text-c-secondary'>
        Most of the corporate network setup will be handled by your company automatically.
        You just have to sign in to your company's VPN later in setup.
      </div>
    </div>
  );
};
