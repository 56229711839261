import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { selectNetwork } from "sf-ui-components";

export function NetworkRoute({component: Component, ...rest}) {
  const network = useSelector(selectNetwork);
  // const devices = useSelector(selectNetworkDevices);
  return (
    <Route
      {...rest}
      render={(props) => ((network == null) ?
        <Redirect to='/'/> : <Component {...props} />)}
    />
  );
}
