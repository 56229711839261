import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import redayToSetup from "../../_images/reday_to_setup.png";

export function StartWiringFlow() {

  const history = useHistory();

  const onClickContinue = () => {
    history.push('/wiring');
  }

  return (

    <div className="mt-9">

      <div className="d-flex justify-content-center mt-7 mb-7">
        <img alt='' src={redayToSetup}/>
      </div>

      <div className="text-center mt-7 mb-9">
        <h3>Let's get your system set up.</h3>
      </div>

      <div className="d-flex justify-content-center align-items-center mb-6">
        <Button variant="primary" className="mr-2 sf-btn-w-lg sf-btn-h-default"
          onClick={onClickContinue}>Continue</Button>
      </div>
    </div>
  );
}
