import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import WirelessPage from "./WirelessPage";

export const WirelessContainer = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/`}>
        <WirelessPage />
      </Route>
      <Route path={`${match.path}`}>
        <Redirect to={`${match.url}/`} />
      </Route>
    </Switch>
  );
};
