import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from 'axios';
import {composeUrl, createLogger, unwrapErrorResponse} from 'sf-ui-components';
import {envBaseApiUrl} from "../../app/env";

const logger = createLogger("sfe:node:slice");

export const NODE_SETUP_STEP = Object.freeze({
  NODE_NOT_FOUND: 0,
  GO_AHEAD: 1,
  ADDING_NODE: 2,
  NODE_ADDED: 3
});

export const MORE_NODE_SETUP_STEP = Object.freeze({
  NONE: 0,
  NODE_DETECTED: 1,
  PLACE_CHILD_NODES: 2,
  SEARCHING_FOR_CHILD_NODES: 3,
  ADDING_NODES: 4,
  DONE: 5,
  NOT_FOUND: 6,
  PARENT_AND_CHILD_NODES: 7,
});

export const startWSetup = createAsyncThunk(
  'node/WSetup',
  async (input, thunkAPI) => {
    logger('[WSetup] begin: %o', input);
    const { networkId } = input;
    const url = composeUrl(`${envBaseApiUrl}/network-delegation-service/rest/networks/${networkId}/devices/primary/command`);

    return axios.post(url, {
      cmd: 'wSetup',
      data: {},
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorResp = unwrapErrorResponse(error);
        throw errorResp;
      })
  }
);

export const slice = createSlice({
  name: 'node',
  initialState: {
    step: NODE_SETUP_STEP.GO_AHEAD,
    moreNodeSetupStep: MORE_NODE_SETUP_STEP.NONE
  },
  reducers: {
    updateNodeSetupStep: (state, action) => {
      state.step = action.payload;
    },
    updateMoreNodeSetupStep: (state, action) => {
      state.previousMoreNodeSetupStep = state.step;
      state.moreNodeSetupStep = action.payload;
    },
  },
  extraReducers: {
    [startWSetup.pending]: (state, action) => {
      logger("startWSetup.pending: %o, %o", state, action);
    },
    [startWSetup.fulfilled]: (state, action) => {
      logger("startWSetup.fulfilled: %o, %o", state, action);
    },
    [startWSetup.rejected]: (state, action) => {
      logger("startWSetup.rejected: %o, %o", state, action);
    },
  }
})

export const selectNodeSetupStep = state => state?.node?.step;
export const selectMoreNodeSetupStep = state => state?.node?.moreNodeSetupStep;
export const selectPreviousMoreNodeSetupStep = state => state?.node?.previousMoreNodeSetupStep;

export const {updateNodeSetupStep, updateMoreNodeSetupStep} = slice.actions;

export default slice.reducer;
