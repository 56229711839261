import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useTopic } from "../../common/hooks";
import _ from "lodash";
import { EmpProgressBar } from "../../components/EmpProgressBar";
import { useHistory } from "react-router-dom";
import {
    IOT_STATUS_EVENTS,
    startFetchAccountNetwork,
    addToast,
    createLogger,
    selectCustomerId,
    useIotMessage,
    useProfile
} from "sf-ui-components";
import wiringAnimation from "../../_images/wiring_animation.png";
import { updateWiringStep, WIRING_STEP } from "./_wiringSlice";

const logger = createLogger('sfe:wiring:gettingThingsUp');

export const GettingThingsUp = () => {

const history = useHistory();
const customerId = useSelector(selectCustomerId);
const profile = useProfile();
const accountId = _.get(profile, 'accountId', '');

  // IoT
  const dispatch = useDispatch();
  const {topic} = useTopic();
  logger(`useIotMessage, topic: ${topic}`);
  const iotParam = useMemo(() => {
    return {
      topic: topic,
      filter: (message) => {
        const event = _.get(message, 'payload.event');
        return event === IOT_STATUS_EVENTS.DEVICE_ONLINE;
      }
    };
  }, [topic]);
  const {waitForPromise, removeMessagePromise} = useIotMessage(iotParam);

  useEffect(() => {
    waitForPromise(180000)
      .then(async messages => {
        logger('IoT message array: ', messages);
        await removeMessagePromise(messages);
        dispatch(updateWiringStep(WIRING_STEP.DONE));
      })
      .catch(it => {
        logger('waitForPromise Timeout', it);

        //call API to check provisioned again
        dispatch(startFetchAccountNetwork({
          customerId: customerId,
          accountId: accountId
        }))
          .then(unwrapResult)
          .then((resp) => {
            if (resp?.data != null && resp.data[0] != null && resp.data[0].provisionStatus === "PROVISIONED") {
              dispatch(updateWiringStep(WIRING_STEP.DONE));
            } else {
              history.push('/isp');
              addToast('Node was not added within 180 seconds', 'info', true);
            }
          })
          .catch((error) => {
            history.push('/isp');
            addToast('Node was not added within 180 seconds', 'info', true);
          });
      }).finally(() => {
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <section>
        <h3>
          Getting things up and running
        </h3>
      </section>
      <section className='mt-4'>
        <div className='f-text-f-body1 sf-text-secondary'>
          Nodes take a couple of minutes to finish starting up. Once yours is ready for setup, we'll get your network ready to go.
        </div>
      </section>
      <section className='mt-10'>
        <div className="d-flex justify-content-center pt-10 pb-10">
          <img alt='' width='50%' src={wiringAnimation}/>
        </div>
      </section>
      <section className='mt-10'>
        <EmpProgressBar/>
      </section>
    </>
  );
};
