import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NodeDetailsPage from "./NodeDetailsPage";

export function NodeDetailContainer() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/:deviceId`}>
        <NodeDetailsPage />
      </Route>
    </Switch>
  );

}
