import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {NetworkPage} from "./NetworkPage";
import {NetworkReadyPage} from "./NetworkReadyPage";

export const NetworkContainer = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/ready`}>
        <NetworkReadyPage/>
      </Route>
      <Route exact path={`${match.path}/`}>
        <NetworkPage/>
      </Route>
      <Route path={`${match.path}`}>
        <Redirect to={`${match.url}/`}/>
      </Route>
    </Switch>
  );
}
