import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import TopologyPage from "./TopologyPage";

export function TopologyContainer() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.path}`}>
        <TopologyPage/>
      </Route>
    </Switch>
  );

}
