import setupDone from "../../_images/setup_done.png";

export function AllSet() {
  return (
    <div className="mt-9">

      <div className="d-flex justify-content-center mt-11 mb-7">
        <img alt='' src={setupDone}/>
      </div>

      <div className="d-flex justify-content-center">
        <div className="text-center" style={{maxWidth: 480}}>
          <h3>
          You're all set for now! When you receive your
          node, follow the instruction in the quick
          start guide to finish setup.
          </h3>
        </div>
      </div>
    </div>
  );
}
