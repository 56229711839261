import React, {useEffect, useState} from 'react';
import {
  HomeBandwidthIoT,
  HomeBandwidthUsage,
  HomeSpeedTest,
  HomeSpeedTestIoT,
  HomeSsidBoard,
  HomeTopology,
  selectCustomerId,
  selectNetworkId,
  selectViewTopology,
  startFetchFirstAccountNetwork,
  TopologyDataProvider,
  useProfile,
} from 'sf-ui-components';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Container, Row} from 'react-bootstrap';
import {SetupPrompt} from './SetupPrompt';
import './topology.scss';
import Page from '../../common/Page';
import _ from "lodash";

const TopologyPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useProfile();
  const customerId = useSelector(selectCustomerId);
  const accountId = _.get(profile, 'accountId', '');
  const topology = useSelector(selectViewTopology);
  const networkId = useSelector(selectNetworkId);

  const [title, setTitle] = useState();

  useEffect(() => {
    if (topology == null) {
      setTitle('');
    } else if (searchOfflineDevice(topology.root) != null) {
      setTitle('Something is wrong');
    } else {
      setTitle('Everything looks good!');
    }
  }, [topology]);

  useEffect(() => {
    dispatch(startFetchFirstAccountNetwork({ customerId, accountId }));
  }, [customerId, accountId, dispatch]);

  return (
    <Page column>
      <Container>
        {networkId && (
          <TopologyDataProvider networkId={networkId}>
            <Row>
              <SetupPrompt/>
              <Col xs={12}>
                {/* <h3>{title}</h3> */}
                <div className="mt-3">{topology != null && <HomeSsidBoard hasCorpNetIssue={title === 'Something is wrong'}
                                                                          hasHomeWiFiIssue={title === 'Something is wrong'}/>}</div>
                <Page className="topology-container">
                  <HomeTopology
                    onNodeClickCb={(id) => {
                      history.push('/nodeDetails/' + id);
                    }}
                  />
                </Page>
              </Col>
              <Col xs={12} md={8}>
                <Page className="visible-animated" column>
                  <HomeBandwidthUsage />
                  <HomeBandwidthIoT />
                </Page>
              </Col>
              <Col xs={12} md={4}>
                <Page className="visible-animated internet-speed" column>
                  <HomeSpeedTest />
                  <HomeSpeedTestIoT />
                </Page>
              </Col>
            </Row>
          </TopologyDataProvider>
        )}
      </Container>
    </Page>
  );
};

const searchOfflineDevice = (element) => {
  if (element == null) {
    return null;
  }
  if (element.status === 'OFFLINE') {
    return element;
  } else if (element.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchOfflineDevice(element.children[i]);
    }
    return result;
  }
  return null;
};

export default TopologyPage;
