import React from "react";
import {Button} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {NODE_SETUP_STEP, updateNodeSetupStep} from "./_nodeSlice";

export const StepGoAhead = () => {
  const dispatch = useDispatch();
  return (
    <>
      <section>
        <h2>
          Go ahead, put your child nodes where you want them
        </h2>
      </section>
      <section className='mt-5'>
        <img alt='' width='100%' src='https://via.placeholder.com/400x256'/>
      </section>
      <section className='mt-6'>
        <div className='mb-6'>
          <div className='sf-text-f-body2 mb-4'>
            Place your nodes within range of your new network
          </div>
          <p className='sf-text-f-body2 sf-text-c-gray'>
            You can use your phone to connect to your new network and check where you have a WiFi signal. A general guideline is to place
            nodes one to two rooms apart from each other.
          </p>
        </div>
        <div className='mb-6'>
          <div className='sf-text-f-body2 mb-4'>
            Look for a solid blue light
          </div>
          <p className='sf-text-f-body2 sf-text-c-gray'>
            When you plug in a node after moving it, the light will blink blue. It will be solid blue when it has a strong connection to
            another node. If the light is yellow or red, move it closer to another node with a solid blue light.
          </p>
        </div>
        <div className='mb-6'>
          <div className='sf-text-f-body2 mb-4'>
            Once you’re finished with setup, name your nodes in settings
          </div>
          <p className='sf-text-f-body2 sf-text-c-gray'>
            Don’t forget to name your nodes, so you can tell them apart when managing your node’s settings.
          </p>
        </div>
      </section>
      <section className='mt-6'>
        <div>
          <Button variant='primary' className='sf-btn-w-xlg' type='submit'
                  onClick={() => dispatch(updateNodeSetupStep(NODE_SETUP_STEP.ADDING_NODE))}>
            I've Done This
          </Button>
        </div>
      </section>
      <section className='mt-6'>
        <div className='d-flex flex-column align-items-center'>
          <p className='sf-text-f-body2 sf-text-c-gray'>
            We’re always happy to help!
          </p>
          <p>
            <a href='#/'>
              Give us a call
            </a>
          </p>
        </div>
      </section>
    </>
  );
};
