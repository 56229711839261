import React, { useLayoutEffect, useState } from 'react';
import './App.css';
import { Route, Switch, useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {
  AuthContainer,
  axiosInterceptorSupplier,
  GuardedRoute,
  IotContext,
  selectCustomerId,
  selectNetwork,
  setStore,
  setToastConfig,
  useCustomerConfig,
  useIoT
} from "sf-ui-components";
import store from "./app/store";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { MainContainer } from "./common/MainContainer";
import { OneColumnLayout } from "./common/_layout/OneColumnLayout";
import _ from "lodash";
import { TwoColumnsLayout } from './common/_layout/TwoColumnsLayout';
import linksys from './_images/LinksysWRK-Logo.svg';
import { Col, Row } from 'react-bootstrap';

function App() {
  useCustomerConfig();
  const history = useHistory();
  const dispatch = useDispatch();
  const [device, setDevice] = useState();
  setToastConfig({
    instance: toast
  });
  // Assign store to sf-ui-components
  setStore(store);
  useLayoutEffect(() => {
    axiosInterceptorSupplier.create({axiosInstance: axios, store, history, dispatch});
  }, [history, dispatch])

  return (
    <>
      <Switch>
        <Route path="/auth">
          <Row className="mobile-fill-container fill-container">
            <Col xs={12} xl={{ span: 4, offset: 4 }} className="no-padding">
              <AuthContainer className="absolute-center fill-container" displayCompanyLogo={true}/>
            </Col>
          </Row>
        </Route>
        <Route path="/" name="App Container">
          <OneColumnLayout logo={linksys}>
            <TwoColumnsLayout>
                <IotContext.Provider value={{device, setDevice}}>
                  <IotContainer>
                      <GuardedRoute component={MainContainer}/>
                  </IotContainer>
                </IotContext.Provider>
            </TwoColumnsLayout>
          </OneColumnLayout>
        </Route>
      </Switch>
      <ToastContainer/>
    </>
  );
}

export function IotContainer({children}) {
  const network = useSelector(selectNetwork);
  const networkId = _.get(network, 'id');
  const customerId = useSelector(selectCustomerId);
  // IoT
  const topic = `sf/org/${customerId}/net/${networkId}/status`;
  useIoT({topic, networkId, orgId: customerId});
  return (
    <>{children}</>
  );
}

export default App;
