import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormCheck, Row } from 'react-bootstrap';
import { hasError, LoadingOverlay, LoadingState } from 'sf-ui-components';
import { useForm } from 'react-hook-form';
import { superstructResolver } from '@hookform/resolvers/superstruct';
import * as s from 'superstruct';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../common/Page';
import UserIcon from '../../common/UserIcon';
import './Profile.scss';
import { startFetchProfile, startFetchPreferences, startUpdateProfile, startUpdatePreferences, updateProfileEditingState, selectProfileEditActive, selectProfileData, selectProfilePreferences, selectLoadingState } from './_profileSlice';

const ProfilePage = (props) => {
    const dispatch = useDispatch()
    const profile = useSelector(selectProfileData);
    const preferences = useSelector(selectProfilePreferences);
    const loadingState = useSelector(selectLoadingState);

    useEffect(() => {
        dispatch(startFetchProfile());
        dispatch(startFetchPreferences())
    }, [dispatch])

    return (
        <Page column className='profile-page'>
            {(loadingState === LoadingState.LOADING) &&
                <LoadingOverlay />
            }
            {loadingState === LoadingState.LOADED && profile && preferences &&
                <Container>
                    <Col>
                        <Row>
                            <div className='profile-page-header'>
                                <h4>Profile</h4>
                                <UserIcon profile={profile} />
                            </div>
                        </Row>
                        <ProfileDataTable profile={profile} preferences={preferences}/>
                    </Col>
                </Container>
            }
        </Page>
    )
}


const ProfileDataTable = (props) => {
    const { profile, preferences } = props;
    const { firstname, lastname, username, hasMFA } = profile;
    const dispatch = useDispatch();

    //probable move this to a shared location
    const mapLanguage = {
        en: 'English',
        fr: 'French',
        es: 'Spanish'
    }

    const updateProfile = (data) => {
        dispatch(startUpdateProfile(data));
    }

    const updatePreferences = (data) => {
        dispatch(startUpdatePreferences(data));
    }

    const displayObject = {
        Name: {
            value: `${firstname} ${lastname}`,
            editable: true,
            formSchema: s.object({
                firstname: s.size(s.string(), 1, 128),
                lastname: s.size(s.string(), 1, 128)
            }),
            action: updateProfile,
            label: 'Name',
            keyLabels: {
                firstname: 'First Name',
                lastname: 'Last Name'
            },
            placeholders: {
                firstname: firstname,
                lastname: lastname
            },
            types: {
                firstname: 'text',
                lastname: 'text'
            }
        },
        Role: {
            value: 'Employee',
            label: 'Role'
        },
        language: {
            value: mapLanguage[preferences?.locale?.language],
            editable: false,
            action: updatePreferences,
            label: 'Language',
            keyLabels: {
                language: 'Language'
            },
            types: {
                language: 'select'
            },
            options: [
                'English',
                'Spanish',
                'French'
            ]
        },
        avatarUrl: {
            value: preferences?.avatarUrl,
            editable: false,
            formSchema: s.object({
                avatarUrl: s.string(),
            }),
            action: updatePreferences,
            placeholders: {
                avatarUrl: preferences?.avatarUrl
            },
            label: 'Profile Image URL',
            keyLabels: {
                avatarUrl: 'Profile Image URL'
            },
            types: {
                avatarUrl: 'text'
            }
        },
        Email: {
            value: username,
            label: 'Email'
        },
        // 'Backup Email': {
        //     value: '',
        //     editable: true,
        //     formSchema: s.object({
        //         'Backup Email': s.string(),
        //     }),
        //     placeholders: {
        //         'Backup Email': ''
        //     },
        //     label: 'Backup Email',
        //     keyLabels: {
        //         'Backup Email': 'Backup Email'
        //     },
        //     types: {
        //         'Backup Email': 'text'
        //     }
        // },
        hasMFA: {
            value: '2-Step Verification gives your account added protection to keep it safe and secure.',
            label: '2-Step Verification',
            toggleValue: hasMFA,
            toggleable: true,
            action: updateProfile
        },
        // 'Shipping Address': {
        //     value: '',
        //     editable: true,
        //     label: 'Shipping Address',
        //     formSchema: s.object({
        //         'Backup Email': s.string(),
        //     }),
        //     placeholders: {
        //         'Shipping Address': ''
        //     },
        //     keyLabels: {
        //         'Shipping Address': 'Shipping Address'
        //     },
        //     types: {
        //         'Shipping Address': 'text'
        //     }
        // }
    }

    return (
        <div className='profile-data-table'>
            {Object.keys(displayObject).map((item) => <ProfileDataItem title={displayObject[item].label} data={displayObject[item]} key={item} action={item.action}/>)}
        </div>
    )
}

const ProfileDataItem = (props) => {
    const { title, data } = props;
    const { value, editable, toggleable, toggleValue, formSchema, keyLabels, placeholders, types, options, action } = data;
    const [isEditing, setIsEditing] = useState();
    const editActive = useSelector(selectProfileEditActive);
    const dispatch = useDispatch();

    const toggleEditing = () => {
        const newIsEditing = !isEditing;
        setIsEditing(newIsEditing);
        dispatch(updateProfileEditingState(!editActive));
    }

    const twoFactorUpdate = () => {
        dispatch(startUpdateProfile({hasMFA: !toggleValue}));
    }

    return (
        <Row>
            <Col xs={1} className='title'>{title}</Col>
            <Col className='value'>{isEditing ? <BuildForm formSchema={formSchema} keyLabels={keyLabels} placeholders={placeholders} types={types} options={options} action={action}/> : value}</Col>
            <Col xs={1} className={`action${!isEditing && editActive ? ' disabled' : ''}`} >
                {editable && <div onClick={toggleEditing}>{isEditing ? 'Cancel' : 'Edit'}</div>}
                {toggleable && 
                    <FormCheck type='switch' id='two-factor-switch' checked={toggleValue} onChange={twoFactorUpdate}/>
                }
            </Col>
        </Row>
    )
}

const BuildForm = (props) => {
    const { formSchema, keyLabels, placeholders = [], types = [], options = [], action} = props;
    // const dispatch = useDispatch();

    const form = useForm({
        resolver: superstructResolver(formSchema)
    });

    const {register, handleSubmit, formState: { errors }} = form;
    
    return (
        <Form onSubmit={handleSubmit(action)} className='user-profile-form'>
            {Object.keys(keyLabels).map((k) => {
                return <FormLine errors={errors} register={register} label={keyLabels[k]} value={placeholders[k]} key={k} k={k} type={types[k]} options={options}/>
            })}
            <Button className='button button-enabled' value='Save' type='submit'>Save</Button>
        </Form>
    )
}

const FormLine = (props) => {
    const { register, errors, label, value, k, type, options } = props;

    return (
        <>
            {type === 'select' ?
                <>
                    <Form.Label>
                        {label}
                    </Form.Label>
                    <Form.Control name={k} as='select' className={hasError(errors, k) ? 'is-invalid' : ''} {...register(k)}>
                        {options.map((lang) => <option value={lang} key={lang}>{lang}</option>)}
                    </Form.Control>
                </>
                :
                <>
                    <Form.Label>
                        {label}
                    </Form.Label>
                    <Form.Control name={k} type={type} className={hasError(errors, k) ? 'is-invalid' : ''} {...register(k)} defaultValue={value}/>
                    {
                        hasError(errors, k) &&
                        <div className='row mt-2'>
                            <div className='col-12 sf-text-f-body2 sf-text-c-red'>Can not be empty</div>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default ProfilePage;
