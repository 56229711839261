import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SETUP_STEP, updateSetupStep } from "../setup/_setupSlice";
import { ISP_STEP, updateIspStep } from "./_ispSlice";

export const LightsStillOn = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const onClickClose = () => {
    dispatch(updateSetupStep(SETUP_STEP.DETECTED));
    history.push('/setup');
  }

  const goToIdentityingModem = () => {
    dispatch(updateIspStep(ISP_STEP.IDENTITYING_MODEM));
  }

  return (
    <>
      <div className="app-container">
        <div className="app-container-top"/>
        <div className="app-container-trunk px-2">
          <div className="d-flex align-items-center justify-content-between mb-5 pt-5">
            <i className='sf-icon-chevron-left cursor-pointer' onClick={() => dispatch(updateIspStep(ISP_STEP.QUICK_START))}/>
            <i className='sf-icon-close ml-3 cursor-pointer' onClick={onClickClose}/>
          </div>

          <section className="mb-8">
            <h3 className="sf-text-f-h3">
              Your modem may still be plugged in, or you may have a battery-powered modem that requires extra help.
            </h3>
          </section>
          <section className="mb-11">
            <h5 className="mb-5">
              <a href='https://www.linksys.com/us/support-article?articleNum=302834' target="_blank" rel="noreferrer noopener">
                Learn more about restarting a battery powered modem
              </a>
            </h5>
            <h5>
              <a href='#/' onClick={goToIdentityingModem}>
                Not sure which device is the modem?
              </a>
            </h5>
          </section>
          <section>
            <div className="text-center pt-11 mb-5">
              <div className="sf-text-f-body1 sf-text-c-secondary">We're always happy to help!</div>
            </div>
            <h5 className="text-center">
              <a href='https://www.linksys.com/homewrk/help' target="_blank" rel="noreferrer noopener">
                Visit our support site
              </a>
            </h5>
          </section>
        </div>
        <div className="app-container-bottom"/>
      </div>
    </>
  );

}
