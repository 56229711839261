import React from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router-dom";
import {WiringPage} from "./WiringPage";

export const WiringContainer = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/`}>
        <WiringPage/>
      </Route>
      <Route path={`${match.path}`}>
        <Redirect to={`${match.url}/`}/>
      </Route>
    </Switch>
  );
}
