import { ReactComponent as Home } from '../../_images/menuIcons/Home.svg';
import { ReactComponent as Devices } from '../../_images/menuIcons/Devices.svg';
import { ReactComponent as Speeds } from '../../_images/menuIcons/Speeds.svg';
import { ReactComponent as Wireless } from '../../_images/menuIcons/Wireless.svg';
import { ReactComponent as GuestNetwork } from '../../_images/menuIcons/GuestNetwork.svg';
import { ReactComponent as Security } from '../../_images/menuIcons/Security.svg';
import { ReactComponent as Priority } from '../../_images/menuIcons/Priority.svg';
import { ReactComponent as NetworkAdministration } from '../../_images/menuIcons/NetworkAdministration.svg';
import { ReactComponent as AdvancedSettings } from '../../_images/menuIcons/AdvancedSettings.svg';

const menuItems = [
  // {
  //   title: 'Customer accounts',
  //   link: '/customers',
  //   icon: {
  //     // type: 'font-awesome' | 'js' | 'url',
  //     type: 'js',
  //     // src: <FontAwesomeIcon icon={faUsers} className='sidebar-icon-svg'/>
  //     // src: `${process.env.PUBLIC_URL}/images/people-active.svg`
  //     src: <People className='sidebar-icon-svg'/>
  //   },
  //   hidden: () => false,
  //   // pathMatch: 'full' | 'prefix' | 'regex'
  //   pathMatch: 'prefix',
  //   inactivePathMatch: 'regex',
  //   inactivePaths: ['/customers/.*/edit']
  // },
  {
    title: 'Home',
    link: '/setup',
    icon: {
      type: 'js',
      src: <Home className='sidebar-icon-svg sf-text-primary-text'/>
    },
    hidden: () => false,
    pathMatch: 'prefix',
  },
  {
    title: 'Devices',
    link: '/devices',
    icon: {
      type: 'js',
      src: <Devices className='sidebar-icon-svg sf-text-primary-text'/>
    },
    hidden: () => false,
    pathMatch: 'prefix',
  },
  {
    title: 'Speeds',
    link: '/speeds',
    icon: {
      type: 'js',
      src: <Speeds className='sidebar-icon-svg sf-text-primary-text'/>
    },
    hidden: () => false,
    pathMatch: 'prefix',
  },
  {
    title: 'Wireless',
    link: '/wireless',
    icon: {
      type: 'js',
      src: <Wireless className='sidebar-icon-svg sf-text-primary-text'/>
    },
    hidden: () => false,
    pathMatch: 'prefix',
  },
  {
    title: 'Guest Network',
    link: '/guestnetwork',
    icon: {
      type: 'js',
      src: <GuestNetwork className='sidebar-icon-svg sf-text-primary-text'/>
    },
    hidden: () => false,
    pathMatch: 'prefix',
  },
  {
    title: 'Security',
    link: '/security',
    icon: {
      type: 'js',
      src: <Security className='sidebar-icon-svg sf-text-primary-text'/>
    },
    hidden: () => false,
    pathMatch: 'prefix',
  },
  {
    title: 'Priority',
    link: '/priority',
    icon: {
      type: 'js',
      src: <Priority className='sidebar-icon-svg sf-text-primary-text'/>
    },
    hidden: () => false,
    pathMatch: 'prefix',
  },
  {
    title: 'Network Administration',
    link: '/networkadministration',
    icon: {
      type: 'js',
      src: <NetworkAdministration className='sidebar-icon-svg sf-text-primary-text'/>
    },
    hidden: () => false,
    pathMatch: 'prefix',
  },
  {
    title: 'System Settings',
    link: '/systemSettings',
    icon: {
      type: 'js',
      src: <AdvancedSettings className='sidebar-icon-svg sf-text-primary-text'/>
    },
    hidden: () => false,
    pathMatch: 'prefix',
  }
];

export default menuItems;
