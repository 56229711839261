import { createSlice } from '@reduxjs/toolkit';
import _ from "lodash";
// import { createLogger } from "sf-ui-components";


// const logger = createLogger("sfe:topology:slice");


export const SETUP_STEP = {
  DETECTED: 0,
  HAVE_HOME_WRKSYSTEM: 1,
  START_CUSTOMIZATION_FLOW: 2,
  START_WIRING_FLOW: 3,
  ALL_SET: 4,
};


export const slice = createSlice({
  name: 'setup',
  initialState: {
    step: SETUP_STEP.DETECTED,
  },
  reducers: {
    updateSetupStep: (state, action) => {
      state.step = action.payload;
    },
  },
  extraReducers: {

  }
});

export const { updateSetupStep } = slice.actions;

export const selectSetupStep = state => _.get(state, 'setup.step', SETUP_STEP.DETECT);

export default slice.reducer;
