import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import customizeSetting from "../../_images/customize_setting.png";


export function StartCustomizationFlow() {

  const history = useHistory();

  const onClickContinue = () => {
    history.push('/vpn');
  }

  return (
    <div className="mt-9">

      <div className="d-flex justify-content-center mt-7 mb-7">
        <img alt='' src={customizeSetting}/>
      </div>

      <div className="d-flex justify-content-center mb-7">
        <div className="text-center" style={{maxWidth: 480}}>
          <h3>
            No Problem. We Can still customize your
            setting now to save your time later.
          </h3>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mb-6">
        <Button variant="primary" className="mr-2 sf-btn-w-lg sf-btn-h-default"
          onClick={onClickContinue}>Continue</Button>
      </div>

    </div>
  );

}
