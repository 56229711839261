import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { AppContainer } from "../../common/AppContainer";
import { BackupEmail } from "./BackupEmail";
import { ConfirmDetails } from "./ConfirmDetails";

export const ConfirmContainer = () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${match.path}/details`}>
        <AppContainer>
          <ConfirmDetails/>
        </AppContainer>
      </Route>
      <Route exact path={`${match.path}/backupEmail`}>
        <AppContainer>
          <BackupEmail/>
        </AppContainer>
      </Route>
      {/* <Route exact path={`${match.path}/finish`}>
        <FinisheSetup/>
      </Route> */}
      <Route path={`${match.path}`}>
        <Redirect to={`${match.url}/details`}/>
      </Route>
    </Switch>
  );
}
