import React, { useEffect, useMemo } from "react";
import { EmpProgressBar } from "../../components/EmpProgressBar";
import { useDispatch, useSelector } from "react-redux";
import {
    IOT_STATUS_EVENTS,
    selectNetworkUnProvisionedCounts,
    addToast,
    createLogger,
    useIoT,
    useIotMessage
} from "sf-ui-components";
import { useTopic } from "../../common/hooks";
import _ from "lodash";
import addingNode from "../../_images/addingNode.png";
import { MORE_NODE_SETUP_STEP, updateMoreNodeSetupStep } from "./_nodeSlice";

const logger = createLogger('sfe:node:moreNodeAddingNode');
export const MoreNodeAddingNode = () => {
  const unProvisionedCounts = useSelector(selectNetworkUnProvisionedCounts);
  const dispatch = useDispatch();

  // IoT
  const {topic} = useTopic();
  logger(`useIotMessage, topic: ${topic}`);
  const iotParam = useMemo(() => {
    return {
      topic: topic,
      filter: (message) => {
        const event = _.get(message, 'payload.event');
        return event === IOT_STATUS_EVENTS.DEVICE_ONLINE;
      }
    };
  }, [topic]);
  useIoT(iotParam);
  const {waitForPromise, removeMessagePromise} = useIotMessage(iotParam);

  useEffect(() => {
    waitForPromise(180000)
      .then(async messages => {
        logger('IoT message array: ', messages);
        await removeMessagePromise(messages);
        dispatch(updateMoreNodeSetupStep(MORE_NODE_SETUP_STEP.DONE));
      })
      .catch(it => {
        logger('waitForPromise Timeout', it);
        dispatch(updateMoreNodeSetupStep(MORE_NODE_SETUP_STEP.NOT_FOUND));
        addToast('Node was not added within 180 seconds', 'info', true);
      }).finally(() => {
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <section>
        <h2>
          Adding your {unProvisionedCounts > 1 ? 'nodes' : 'node'}...
        </h2>
      </section>
      <section className='mt-4'>
        <div className='sf-text-f-body2 sf-text-c-gray'>
          This can take up to a few minutes.
        </div>
      </section>
      <section className='mt-9'>
        <img alt='' width='100%' src={addingNode}/>
      </section>
      <section className='mt-9'>
        <EmpProgressBar/>
      </section>
    </>
  )
}
