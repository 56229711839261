import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import wiringRemove from "../../_images/wiring_remove.png";
import { updateWiringStep, WIRING_STEP } from "./_wiringSlice";

export const DisconnectAndRemove = () => {
  const dispatch = useDispatch();
  return (
    <>
      <section>
        <h3>
          Disconnect and remove the router that you're replacing
        </h3>
      </section>
      <section className='mt-4'>
        <div className='sf-text-f-body1 sf-text-secondary'>
          Disconnect the ethernet cord from your modem and unplug the router from the wall. Set the router aside. If you have a combined
          modem-router, you can skip this step.
        </div>
      </section>
      <section className='mt-9 pt-3'>
        <img alt='' width='100%' src={wiringRemove}/>
      </section>
      <section className='mt-9'>
        <div>
          <Button variant='primary' className='sf-btn-w-xlg' type='submit'
                  onClick={() => dispatch(updateWiringStep(WIRING_STEP.PLUG_IN_NODE))}>
            I've Done This
          </Button>
        </div>
      </section>
    </>
  );
};
