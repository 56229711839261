import {configureStore} from '@reduxjs/toolkit';
import {
  authSlice,
  cloudUtilitySlice,
  devicesSlice,
  iotSlice,
  networkPreferenceSlice,
  networkReducer,
  nodeClientDetailsSlice,
  nodeDetailsReducer,
  speedsReducer,
  topologyReducer,
  vpnReducer
} from 'sf-ui-components';
import confirmSlice from "../features/confirm/_confirmSlice";
import ispSlice from "../features/isp/_ispSlice";
import nodeSlice from "../features/node/_nodeSlice";
import setupSlice from "../features/setup/_setupSlice";
import profileSlice from "../features/profile/_profileSlice";
import wifiSlice from "../features/wifi/_wifiSlice";
import wiringSlice from "../features/wiring/_wiringSlice";
import layoutSlice from "../common/_layout/_layoutSlice";
import wirelessSlice from "../features/wireless/_wirelessSlice";

export default configureStore({
  reducer: {
    auth: authSlice.reducer,
    cloudUtility: cloudUtilitySlice.reducer,
    iot: iotSlice.reducer,
    isp: ispSlice,
    node: nodeSlice,
    wifi: wifiSlice,
    network: networkReducer,
    nodeDetails: nodeDetailsReducer,
    vpn: vpnReducer,
    networkPreference: networkPreferenceSlice.reducer,
    wiring: wiringSlice,
    topology: topologyReducer,
    confirm: confirmSlice,
    layout: layoutSlice,
    setup: setupSlice,
    profile: profileSlice,
    devices: devicesSlice.reducer,
    nodeClientDetails: nodeClientDetailsSlice.reducer,
    empWifi: wirelessSlice,
    speeds: speedsReducer
  },
});
