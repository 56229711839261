import { superstructResolver } from '@hookform/resolvers/superstruct';
import { unwrapResult } from "@reduxjs/toolkit";
import debug from 'debug';
import _, { parseInt } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addToast, coerceOptionalNumber, hasError, isEmpty, LoadingState, selectCustomerId, useProfile } from "sf-ui-components";
import * as s from 'superstruct';
import { selectNetwork, selectNetworkLoadingState, startFetchFirstAccountNetwork } from "sf-ui-components";
import {
  selectIsps, selectIspsLoadingState, selectLocations, selectNetworkPreferences, selectPreferencesLoadingState,
  startFetchNetworkPreferences, startGetGeoLocationByZipcode, startGetIsps, startSetNetworkPreferences
} from './_confirmSlice';

const logger = debug('sf:confirm:details');

export function ConfirmDetails() {

  const dispatch = useDispatch();
  const history = useHistory();
  const [cityAndState, setCityAndState] = useState();
  const [invalidZipCode, setInvalidZipCode] = useState(false);
  const isps = useSelector(selectIsps);
  const ispsLoadingState = useSelector(selectIspsLoadingState);
  const locations = useSelector(selectLocations);
  const networkPreferences = useSelector(selectNetworkPreferences);
  const preferencesLoadingState = useSelector(selectPreferencesLoadingState);
  const [isOther, setIsOther] = useState(false);
  const customerId = useSelector(selectCustomerId);
  const profile = useProfile();
  const accountId = _.get(profile, 'accountId', '');
  const network = useSelector(selectNetwork);
  const [allBeSet, setAllBeSet] = useState(false);

  const networkLoadingState = useSelector(selectNetworkLoadingState);
  useEffect(() => {
    if (!isEmpty(accountId) && !isEmpty(customerId) && !network && networkLoadingState === LoadingState.NONE) {
      dispatch(startFetchFirstAccountNetwork({
        customerId: customerId,
        accountId: accountId
      }))
        .then(unwrapResult)
        .then((resp) => {
          logger('Fetch account network successfully', resp);
        })
        .catch((error) => {
          logger('Fetch network failed:', error);
          addToast(`Fetch network failed: ${error.message}`, 'error');
        });
    }
  }, [dispatch, accountId, customerId, networkLoadingState, network]);


  useEffect(() => {
    if (network != null && !isEmpty(networkPreferences) ) {
      const provisionStatus = _.get(network, 'provisionStatus');
      if (provisionStatus === 'PROVISIONED') {
        if (networkPreferences.city != null && networkPreferences.ispMaxSpeed != null && networkPreferences.ispId != null) {
          if (profile != null && profile.backupEmail != null) {
            history.push('/network/ready');
          } else {
            history.push('/confirm/backupEmail');
          }
        }
      }
    }
  }, [history, network, networkPreferences, profile]);

  useEffect(() => {
    if (ispsLoadingState === LoadingState.NONE) {
      dispatch(startGetIsps({limit: 10}))
        .then((resp) => {
          logger('startGetIsps resp: %o', resp);
        });
    }
  }, [dispatch, ispsLoadingState]);

  const schema = s.object({
    ispId: s.size(s.string(), 1, Infinity),
    ispName: s.optional(s.string()),
    ispMaxSpeed: coerceOptionalNumber(s.number()),
    cityId: s.size(s.string(), 1, Infinity),
  });

  const form = useForm({
    resolver: superstructResolver(schema, {
      coerce: true
    }),
    defaultValues: {
      ispId: _.get(networkPreferences, 'ispId', ''),
      ispName: _.get(networkPreferences, 'ispName', ''),
      ispMaxSpeed: _.get(networkPreferences, 'ispMaxSpeed', ''),
      cityId: _.get(networkPreferences, 'city.cityId', ''),
    }
  });

  const { handleSubmit, register, setValue, watch, formState: { errors } } = form;

  useEffect(() => {
    if (preferencesLoadingState === LoadingState.NONE && network) {
      dispatch(startFetchNetworkPreferences({networkId: network.id}))
        .then(unwrapResult)
        .then((resp) => {
          setValue('ispId', _.get(resp, 'ispId', ''));
          setValue('ispName', _.get(resp, 'ispName', ''));
          setValue('ispMaxSpeed', _.get(resp, 'ispMaxSpeed', ''));
          setValue('cityId', _.get(resp, 'city.cityId', ''));
        })
        .catch((error) => {
          logger('Fetch Network Preferences failed:', error);
          addToast(`Fetch Network Preferences: ${error.message}`, 'error');
        });
    }
  }, [dispatch, network, preferencesLoadingState, setValue]);


  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      logger('value=%o, name=%o, type=%o', value, name, type);
      const ispId = _.get(value, 'ispId');
      const ispMaxSpeed = _.get(value, 'ispMaxSpeed');
      const cityId = _.get(value, 'cityId');

      if (name === 'ispId') {
        if (ispId != null && isps != null & isps.length > 0) {
          const element = isps.find(x => x.externalId === ispId);
          if (element != null && element.name === 'Other') {
            setValue('ispName', '');
            setIsOther(true);
          } else {
            setIsOther(false);
          }
        } else {
          setIsOther(false);
        }
      }

      if (ispId != null && ispId.length > 0 &&
          ispMaxSpeed != null && parseInt(ispMaxSpeed) > 0 &&
          cityId != null && cityId.length > 0
      ) {
        setAllBeSet(true);
      } else {
        setAllBeSet(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [isps, setValue, watch]);


  useEffect(() => {
    if (!isEmpty(networkPreferences) && networkPreferences.city && cityAndState == null) {
      setCityAndState(networkPreferences.city.name + ', ' + networkPreferences.city.state);
      setValue('cityId', networkPreferences.city.cityId);
      setInvalidZipCode(false);
    }
  }, [cityAndState, dispatch, networkPreferences, setValue]);

  let timeout = 0;
  const onChange = (value) => {
    if (value && value.length >= 3) {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        setCityAndState("");
        dispatch(startGetGeoLocationByZipcode({
          zipcode: value
        }))
          .then(unwrapResult)
          .then(resp => {
            if (resp && resp.length > 0){
              setCityAndState(resp[0].name + ', ' + resp[0].state);
              setValue('cityId', resp[0].cityId);
              setInvalidZipCode(false);
            }
          })
          .catch((error) => {
            logger(`Get GeoLocation By Zipcode failed: ${error.message}`, 'error');
            setCityAndState("");
            setValue('cityId', "");
            setInvalidZipCode(true);
          });
      }, 1000);
    } else {
      setCityAndState("");
      setValue('cityId', "");
      setInvalidZipCode(true);
    }
  }

  const onClickSkip = () => {
    const provisionStatus = _.get(network, 'provisionStatus');

    if (provisionStatus !== 'PROVISIONED') {
      history.push('/confirm/backupEmail');
    } else {
      if (profile != null && profile.backupEmail != null) {
        history.push('/network/ready');
      } else {
        history.push('/confirm/backupEmail');
      }
    }
  }

  const doOnSubmit = async data => {
    logger("submit: data =%o", data);

    if (locations && locations.length > 0) {
      logger('locations[0].cityId=%o', locations[0].cityId);
    }

    if (isps && isps.length > 0) {
      let ispName;
      if (isOther === true) {
        ispName = data.ispName;
      }

      const body = Object.assign(data, {
        ispName: ispName,
        networkId: network.id
      });
      logger("body=%o", body);
      dispatch(startSetNetworkPreferences(body))
        .then(unwrapResult)
        .then((resp) => {
          logger('Set Network Preferences successfully', resp);

          const provisionStatus = _.get(network, 'provisionStatus');

          if (provisionStatus !== 'PROVISIONED') {
            history.push('/confirm/backupEmail');
          } else {
            if (profile != null && profile.backupEmail != null) {
              history.push('/network/ready');
            } else {
              history.push('/confirm/backupEmail');
            }
          }
        })
        .catch((error) => {
          logger('startSetNetworkPreferences failed:', error);
          addToast(`Set Network Preferences failed: ${error.message}`, 'error');
        });
    } else {
      addToast('No isp Name found', 'error');
    }
  }

  return (
    <>
      <section className="mb-6">
        <h2 className="mb-4">
          Confirm your details
        </h2>
        <div className="sf-text-f-body1 sf-text-c-secondary">
          We'll only use this information for troubleshooting should you have any WiFi issues later.
        </div>
      </section>
      <Form onSubmit={handleSubmit(doOnSubmit)}>

        <section className="mb-6">
          <h3 className="mb-2">Where are you?</h3>
          <div className="mb-5">
            This will help us determine if there are internet outages in your area.
          </div>
          <Form.Label className={invalidZipCode ? "sf-text-c-red" : ""}>Zip Code</Form.Label>
          <InputGroup>
            <Form.Control name={"zipCode"} type="text" defaultValue={networkPreferences?.city?.zipcode}
              onChange={e => onChange(e?.target?.value)} className={invalidZipCode ? "is-invalid" : ""}/>
            {
              cityAndState && cityAndState.length > 0 &&
              <InputGroup.Append>
                <InputGroup.Text >
                  {cityAndState}
                </InputGroup.Text>
              </InputGroup.Append>
            }
          </InputGroup>
          {
            invalidZipCode &&
            <div className="row mt-2">
              <div className="col-12 sf-text-f-body2 sf-text-c-red">Please enter a valid zip code</div>
            </div>
          }
          <input type="hidden" name={"cityId"} {...register("cityId")} />
        </section>
        <section className="mb-6">
          <h3 className="mb-5">Who is your internet service provider (ISP)?</h3>
          <Form.Group>
            <Form.Label>ISP</Form.Label>
            <Form.Control as="select" name={"ispId"} defaultValue="" {...register("ispId")}
              className={hasError(errors, `ispId`) ? "is-invalid" : ""}>
              <option disabled value="">Select your ISP</option>
              { isps && isps.length > 0 &&
                isps.map((isp, i) =>
                  <option key={i} value={isp.externalId}>{isp.name}</option>
                )
              }
            </Form.Control>
          </Form.Group>
          {
            isOther &&
            <Form.Group className="mt-5">
              <Form.Label>Other ISP name</Form.Label>
              <Form.Control type="text" name={"ispName"} defaultValue="" {...register("ispName")} >
              </Form.Control>
            </Form.Group>
          }
        </section>
        <section className="mb-6">
          <h3 className="mb-4">
            What is the maximum speed you chose when you signed up for your internet plan?
          </h3>
          <div className="mb-5">
            If we know what you're paying for, we can help you get the most from your WiFi.
          </div>
          <div className="row">
            <div className="col-11">
              <label>ISP download speed</label>
              <div className="row d-flex align-items-center ">
                <div className="col-4">
                  <Form.Control name={"ispMaxSpeed"} type="number" {...register("ispMaxSpeed")}
                    className={hasError(errors, `ispMaxSpeed`) ? "is-invalid" : ""} />
                </div>
                <div> Mbps</div>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-6">
          {
            invalidZipCode === false && allBeSet ?
            <Button variant='primary' className='sf-btn-w-xlg' type="submit">
              Next
            </Button>
            :
            <Button variant='primary-reversed' className='sf-btn-w-xlg' disabled type="submit">
              Next
            </Button>
          }

        </section>
      </Form>

      <section className="mb-6">
        <div className='d-flex align-items-center justify-content-center'>
          <Button variant="link" className="p-0 sf-link-primary" onClick={onClickSkip}>
            Skip this step
          </Button>
        </div>
      </section>
    </>
  );
}
