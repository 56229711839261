import { unwrapResult } from "@reduxjs/toolkit";
import _ from "lodash";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    IOT_STATUS_EVENTS,
    startFetchAccountNetwork,
    addToast,
    createLogger,
    selectCustomerId,
    useIotMessage,
    useProfile
} from "sf-ui-components";
import { useTopic } from "../../common/hooks";
import { EmpProgressBar } from "../../components/EmpProgressBar";
import IspCheckingForInternet from "../../_images/ISP_Checking_for_internet.png";
import { increaseDetectionCount, ISP_STEP, selectDetectionCount, updateIspStep } from "./_ispSlice";

const logger = createLogger('sfe:isp:CheckingForInternet');

export const CheckingForInternet = () => {

  const customerId = useSelector(selectCustomerId);
  const profile = useProfile();
  const accountId = _.get(profile, 'accountId', '');
  const detectionCount = useSelector(selectDetectionCount);

  // IoT
  const dispatch = useDispatch();
  const {topic} = useTopic();
  logger(`useIotMessage, topic: ${topic}`);
  const iotParam = useMemo(() => {
    return {
      topic: topic,
      filter: (message) => {
        const event = _.get(message, 'payload.event');
        return event === IOT_STATUS_EVENTS.DEVICE_ONLINE;
      }
    };
  }, [topic]);
  const {waitForPromise, removeMessagePromise} = useIotMessage(iotParam);

  useEffect(() => {
    logger('detectionCount=', detectionCount);
    dispatch(increaseDetectionCount());
    waitForPromise(120000)
      .then(async messages => {
        logger('IoT message array: ', messages);
        await removeMessagePromise(messages);
        dispatch(updateIspStep(ISP_STEP.CONNECTED));
      })
      .catch(it => {
        logger('waitForPromise Timeout', it);
        addToast('Node was not added within 120 seconds', 'info', true);

        //call API to check provisioned again
        dispatch(startFetchAccountNetwork({
          customerId: customerId,
          accountId: accountId
        }))
          .then(unwrapResult)
          .then((resp) => {
            logger('Fetch account network successfully', resp);
            if (resp?.data != null && resp.data[0] != null && resp.data[0].provisionStatus === "PROVISIONED") {
              dispatch(updateIspStep(ISP_STEP.CONNECTED));
            } else {
              logger('detectionCount=', detectionCount);
              if (detectionCount === 0) {
                dispatch(updateIspStep(ISP_STEP.UNABLE_CONNECT));
              } else {
                dispatch(updateIspStep(ISP_STEP.TROUBLE_COMMUNICATING));
              }

            }
          })
          .catch((error) => {
            logger('detectionCount=', detectionCount);
            if (detectionCount === 0) {
                dispatch(updateIspStep(ISP_STEP.UNABLE_CONNECT));
              } else {
                dispatch(updateIspStep(ISP_STEP.TROUBLE_COMMUNICATING));
              }
          });
      }).finally(() => {
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className="mb-11">
        <h3>
          Checking for internet...
        </h3>
      </section>
      <section className='mb-9'>
        <img alt='' width='100%' src={IspCheckingForInternet}/>
      </section>
      <section className='mb-2'>
        <EmpProgressBar/>
        <div>This cloud tale up to 2 minutes</div>
      </section>

    </>
  );
}
