import React from "react";
import {useSelector} from "react-redux";
import {FirmwareUpdateIoT, FirmwareUpdateView, selectNetworkId} from "sf-ui-components";

export const FirmwareUpdatePage = () => {
    const networkId = useSelector(selectNetworkId);
    return (
      <>
        <FirmwareUpdateView networkId={networkId}/>
        <FirmwareUpdateIoT networkId={networkId}/>
      </>
    );
  }
;
