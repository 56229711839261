import React from "react";
import {AppContainer} from "../../common/AppContainer";
import {useSelector} from "react-redux";
import {MORE_NODE_SETUP_STEP, selectMoreNodeSetupStep} from "./_nodeSlice";
import {MoreNodeDetected} from "./MoreNodeDetected";
import {MoreNodePlaceChildNode} from "./MoreNodePlaceChildNode";
import {MoreNodeSearchingNode} from "./MoreNodeSearchingNode";
import {MoreNodeAddingNode} from "./MoreNodeAddingNode";
import {MoreNodeDone} from "./MoreNodeDone";
import {StepNodeNotFound} from "./StepNodeNotFound";
import { ParentAndChildNodes } from "./ParentAndChildNodes";

export const MoreNodeSetupPage = () => {
  return (
    <>
      <RenderMoreNodeSetupFlow/>
    </>
  );
};

const RenderMoreNodeSetupFlow = () => {
  const step = useSelector(selectMoreNodeSetupStep);
  switch (step) {
    case MORE_NODE_SETUP_STEP.NODE_DETECTED:
      return (
        <AppContainer>
          <MoreNodeDetected/>
        </AppContainer>
      );
    case MORE_NODE_SETUP_STEP.PLACE_CHILD_NODES:
      return (
        <AppContainer>
          <MoreNodePlaceChildNode/>
        </AppContainer>
      );
    case MORE_NODE_SETUP_STEP.SEARCHING_FOR_CHILD_NODES:
      return (
        <AppContainer>
          <MoreNodeSearchingNode/>
        </AppContainer>
      );
    case MORE_NODE_SETUP_STEP.ADDING_NODES:
      return (
        <AppContainer>
          <MoreNodeAddingNode/>
        </AppContainer>
      );
    case MORE_NODE_SETUP_STEP.DONE:
      return (
        <AppContainer>
          <MoreNodeDone/>
        </AppContainer>
      );
    case MORE_NODE_SETUP_STEP.NOT_FOUND:
      return (
        <AppContainer>
          <StepNodeNotFound/>
        </AppContainer>
      );
    case MORE_NODE_SETUP_STEP.PARENT_AND_CHILD_NODES:
      return (
        <>
          <ParentAndChildNodes />
        </>
      );
    case MORE_NODE_SETUP_STEP:
    default:
      return (
        <AppContainer>
          <MoreNodeDetected/>
        </AppContainer>
      );
  }
}
