import React from "react";
import {Sidebar} from "sf-ui-components";
import menuItems from "./MenuItems";

export function TwoColumnsLayout({children}) {
  return (
    <div className="container-fluid">
      <div className="position-relative">
        <Sidebar displayHeader={false} menuItems={menuItems} />
        <div className="content-container main-body">
          {children}
        </div>
      </div>
    </div>
  )
}
